import { Card } from 'primereact/card';
import { Accordion, AccordionTab } from 'primereact/accordion';
import * as react from 'react';
import { SystemAdmin } from './SystemAdmin';
import { useDispatch, useSelector } from 'react-redux';
import { AppMode } from '../../data/AppMode';
import { Themes } from '../../data/Themes';
import { AppModeState } from '../../dtos/common/AppModeState';
import { ThemeState } from '../../dtos/common/ThemeState';
import { CronSettings } from './CronSettings';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { createSettings, findSettingsByKey, updateSettings } from '../../service/common/commonService';
import { Toast } from 'primereact/toast';
import { useRef, useState } from 'react';
import { TabMenu } from 'primereact/tabmenu';
import '../../css/components/systemAdmin.css'
import { System_Time_Zone } from '../../shared/constant/StarValue';

export const ApplicationSettings = () => {
    const themeName = useSelector((state: ThemeState) => state.theme.themeName);
    const ThemeColors = Themes.find(th => th.name === themeName);
    const modeName = useSelector((state: AppModeState) => state.theme.appMode);
    const mode = AppMode.find(md => md.name === modeName);
    const [subordinateLabel, setSubordinateLabel] = react.useState<number>(0);
    const dispatch = useDispatch();
    const toast = useRef<any>(null);
    const [offsetTimeInHour, setOffsetTimeInHour] = react.useState(0);
    const [offsetTimeInMin, setOffsetTimeInMin] = react.useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const localization = useSelector((state: any) => state.localization.localization);
    const [systemTimeZone, setSystemTimeZone] = useState<string>("")
    const items = [
        { label: localization?.SystemAdmin || 'System Admin', icon: 'pi pi-user' },
        { label: localization?.CronSettings || 'Cron Settings', icon: 'pi pi-fw pi-cog' },
        { label: localization?.SubbordinateLevel || 'Subbordinate Level', icon: 'pi pi-chart-line' },
        { label: localization?.SystemTimeZone || 'System Time Zone', icon: 'pi pi-clock' }
    ];
    const [cronTime, setCronTime] = useState<any>(0)

    const handleSubordinateLabel = async () => {
        const isPresent = await findSettingsByKey(dispatch, { key: 'teamLevel' });
        if (isPresent !== null) {
            await updateSettings(dispatch, {
                Key: 'teamLevel',
                Value: subordinateLabel.toString()
            })
        }
        else {
            await createSettings(dispatch, {
                Key: 'teamLevel',
                Value: subordinateLabel.toString()
            })
        }
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'You have successfully change the subordinate level!', life: 3000 });
    }

    const getSubordinateLevel = async () => {
        const subLevel = await findSettingsByKey(dispatch, { key: 'teamLevel' });
        if (subLevel !== null) {
            setSubordinateLabel(subLevel.Value)
        }
    }

    const getOffsetTime = async () => {
        const offsetTime = await findSettingsByKey(dispatch, { key: 'OffsetTime' });
        const timeInMin = offsetTime?.Value?.slice(1)
        const hours = (timeInMin / 60);
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        setOffsetTimeInHour(rhours);
        setOffsetTimeInMin(rminutes);
    }

    const getCornTime = async () => {
        const time = await findSettingsByKey(dispatch, { key: 'CronTime' });
        if (time.Value) {
            setCronTime(time.Value)
        }
    }
    const getSystemTimeZone = async () => {
        const time = await findSettingsByKey(dispatch, { key: System_Time_Zone });
        if (time.Value) {
            setSystemTimeZone(time.Value)
        }
    }

    react.useEffect(() => {
        getSubordinateLevel();
        getOffsetTime();
        getCornTime();
        getSystemTimeZone()
    }, [])

    return (
        <Card style={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} className='admin_card'>
            <Toast ref={toast} />
            <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className='tab-menu' style={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} />

            <div className='main-settings'>
                {activeIndex === 0 ? (<SystemAdmin />) :
                    activeIndex === 1 ? (<CronSettings data={cronTime} />) :
                        activeIndex === 2 ? (<Card style={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }}>
                            <div className='subbordinate-label'>
                                <h3>Level: </h3>
                                <InputNumber value={subordinateLabel} onChange={(e: any) => setSubordinateLabel(e.value)} />
                                <Button label='Change Subordinate Label' onClick={handleSubordinateLabel} style={{ backgroundColor: ThemeColors?.primaryColor }} className='credential_btn' />
                            </div>
                        </Card>) :
                            (
                                <Card style={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }}>
                                    <div className='offset-time'>
                                        <h3>System Time Zone : </h3>
                                        <div>{offsetTimeInHour} Hr {offsetTimeInMin} Min</div>
                                    </div>
                                    <div className='offset-time'>
                                        <h3>System Time Zone Name: </h3>
                                        <div>{systemTimeZone}</div>
                                    </div>
                                </Card>
                            )
                }
            </div>


        </Card>
    )
}
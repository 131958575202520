import { Toolbar } from "primereact/toolbar"
import { useDispatch, useSelector } from "react-redux";
import { AppMode } from "../../data/AppMode";
import { AppModeState } from "../../dtos/common/AppModeState";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { forwardRef, useEffect, useRef, useState } from "react";
import { Calendar } from "primereact/calendar";
import { isNotUpcomingDate } from "../../shared/functions/IsNotUpcomingDateFunc";
import DatePicker from "react-datepicker";
import { Card } from "primereact/card";
import { getSubordinatesNameIDCode } from "../../service/employee/employeeService";
import { MultiSelect } from "primereact/multiselect";
import { getMonthlyRatingsWithKPI, getPerformanceReport, getPerformanceReportByKPI } from "../../service/performance/PerformanceService";
import { PerformanceReportInputDto } from "../../dtos/performance/PerformanceReportInputDto";
import { ISTToDate } from "../../shared/functions/ISTToDate";
import { Column } from 'primereact/column';
import { DashboardInputDto } from "../../dtos/dashboard/DashboardInputDto";
import '../../css/components/performanceReport.css';
import { DataTable } from "primereact/datatable";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PerformanceMonthlyReportDto } from "../../dtos/performance/PerformanceMonthlyReportDto";
import { PerformanceYearlyReportDto } from "../../dtos/performance/PerformanceYearlyReportDto";
import { ProgressSpinner } from "primereact/progressspinner";
import { SiMicrosoftexcel } from "react-icons/si";
import { EmpPerformanceReportMonthlyChart } from "../charts/EmpPerformanceReportMonthlyChart";
import { EmpPerformanceReportYearlyChart } from "../charts/EmpPerformanceReportYearlyChart";
import instance from "../../utills/BaseService";
import { MonthlyRatingsWithKPI } from "../../dtos/performance/MonthlyRatingsWithKPI";
import { Themes } from "../../data/Themes";
import { ThemeState } from "../../dtos/common/ThemeState";
import { Toast } from "primereact/toast";
import { GetPreviousMonth } from "../../shared/functions/GetPreviousMonth";


export const PerformanceReport = () => {
    const modeName = useSelector((state: AppModeState) => state.theme.appMode);
    const mode = AppMode.find((md) => md.name === modeName);
    const dispatch = useDispatch();
    //get previous month
    const [date, setDate] = useState<any>(GetPreviousMonth(new Date));
    const [dateType, setDateType] = useState<string>("month");
    const [calender, setCalender] = useState(<Calendar value={date} onChange={(e) => setDate(e.value)} style={{ backgroundColor: 'red' }} />);
    const [disableSelectBtn, setDisableSelectBtn] = useState<boolean>(false);
    const [monthDate, setMonthDate] = useState<any>(new Date());
    const [yearDate, setYearDate] = useState<any>(new Date(new Date().setFullYear(new Date().getFullYear() - 1)));
    const toast = useRef<any>(null);
    const [employee, setEmployee] = useState<any[]>([]);
    const [selectedEmployee, setSelectedEmployee] = useState<string[] | null>(null);
    const localization = useSelector((state: any) => state.localization.localization);
    const [reportData, setReportData] = useState<PerformanceMonthlyReportDto[] | PerformanceYearlyReportDto[]>([]);
    const [expandedRow, setExpandedRow] = useState<string>('');
    const [historyOfReportData, setHistoryOfReportData] = useState<PerformanceMonthlyReportDto[]>([]);
    const [excelBtn, setExcelBtn] = useState(true);
    const [monthlyChartData, setMonthlyChartData] = useState<MonthlyRatingsWithKPI[]>([])
    const [excelLoader, setExcelLoader] = useState(false);
    const themeName = useSelector((state: ThemeState) => state.theme.themeName);
    const ThemeColors = Themes.find(th => th.name === themeName);
    const buttons = [
        { name: "Month", value: "month" },
        { name: "Year", value: "year" }
    ];

    //#region all Functions

    const getAllEmployees = async () => {
        const employees = await getSubordinatesNameIDCode(dispatch);
        setEmployee(employees);
    };

    //This function will give first and last day of month from a selected single date
    function getFirstAndLastDatesOfMonth(inputDate: Date) {
        const year = inputDate.getFullYear();
        const month = inputDate.getMonth();
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);
        return {
            firstDay,
            lastDay,
        };
    }

    const createEmpAndProjArr = (arr: any[]) => {
        const data: string[] = [];
        arr?.forEach((e: any) => {
            data.push(e?.employeeCode?.toString());
        });
        return data;
    };

    const createStartAndEndDate = (input: Date[]) => {
        let dates: DashboardInputDto = {
            startDate: "",
            endDate: "",
        };
        if (date.length > 0) {
            const allStrDates = ISTToDate(input);
            dates = {
                startDate: allStrDates[0],
                endDate: allStrDates[1],
            };
        }
        //If there is only a single date and Month button is selected
        else if (date.length === undefined && dateType === "month") {
            const firstAndLastDate = getFirstAndLastDatesOfMonth(date);
            const dt = [firstAndLastDate.firstDay, firstAndLastDate.lastDay];
            const allStrDates = ISTToDate(dt);
            dates = {
                startDate: allStrDates[0],
                endDate: allStrDates[1],
            };
        } else if (dateType === "year") {
            const currentYear = date.getFullYear();
            const allStrDates = ISTToDate([
                new Date(currentYear, 0, 1),
                new Date(currentYear, 11, 31),
            ]);
            dates = {
                startDate: allStrDates[0],
                endDate: allStrDates[1],
            };
        }
        return dates;
    };

    const getAllReport = async () => {
        setDisableSelectBtn(true);
        setExcelBtn(true
        )
        const input: PerformanceReportInputDto = {
            dateType: dateType === 'month' ? '0' : '1',
            startDate: createStartAndEndDate(date).startDate,
            endDate: createStartAndEndDate(date).endDate,
            employeeCodes: createEmpAndProjArr(selectedEmployee as string[])
        }

        if (input.employeeCodes.length === 0) {
            setReportData([]);
            setDisableSelectBtn(false)
            return;
        }

        const res: PerformanceMonthlyReportDto[] = await getPerformanceReport(dispatch, input);

        res.forEach(data => data.isKPIPresent = false);

        //for chart
        input.dateType = '0'
        const monthlyKPIRating: MonthlyRatingsWithKPI[] = await getMonthlyRatingsWithKPI(dispatch, input);



        if (monthlyKPIRating.length > 0) {
            setMonthlyChartData(monthlyKPIRating)
        }

        setReportData(res)
        setHistoryOfReportData(res);
        if (res.length > 0) {
            setExcelBtn(false)
        }
        setDisableSelectBtn(false)
    }

    const employeeOptions = employee.map((emp) => ({
        label: `${emp.fullName} (${emp.employeeCode})`,
        value: emp, // You can set the entire employee object as the value if needed
    }));

    //after clicking the year button dates will set to year
    const handleYear = () => {
        setDate(yearDate);
    };

    //after clicking the month button dates will set to month
    const handleMonth = () => {
        setDate(monthDate);
    };

    //For handling date select component buttons
    const handleDate = (e: any) => {
        let dateTypeForPicker;
        if (e.value === null) {
            dateTypeForPicker = dateType;
            setDateType((prev: any) => {
                return prev;
            });
        } else {
            setDateType(e.value);
            dateTypeForPicker = e.value;
        }

        if (e.value === "month") {
            handleMonth();
        } else if (e.value === "year") {
            handleYear();
        }
    };

    //For previous date button
    const handlePrevDate = (type: string, date: any) => {
        if (type === "month") {
            const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            firstDay.setMonth(firstDay.getMonth() - 1);
            setDate(firstDay);
            setMonthDate(firstDay)
        } else if (type === "year") {
            const prevY = new Date(date.setFullYear(date.getFullYear() - 1));
            setDate(prevY);
            setYearDate(prevY)
        }
    };

    //For next date button
    const handleNextDate = (type: string, date: any) => {
        if (type === "month") {
            const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            firstDay.setMonth(firstDay?.getMonth() + 1);

            //if the date is upcoming date then set it current date
            if (!isNotUpcomingDate(firstDay.toString())) {
                setDate((prev: any) => {
                    const newDate = prev;
                    return newDate
                })
                toast.current.show({ severity: 'warn', summary: 'Sorry!', detail: "You can't go to the current and upcoming months!", life: 3000 });
                return;
            }
            setDate(firstDay);
            setMonthDate(firstDay)
        } else if (type === "year") {
            const nextY = new Date(date.setFullYear(date.getFullYear() + 1));
            setDate(nextY);
            setYearDate(nextY)
        }
    };

    //This function will decide which calender will show according to the dateType
    const handleCalender = () => {
        if (dateType === "month") {
            setCalender(
                <DatePicker
                    selected={date}
                    customInput={<CustomInput />}
                    value={date}
                    onChange={(e: any) => setDate(e)}
                    dateFormat="MMMM yyyy"
                    showMonthYearPicker
                />
            );
            setMonthDate(date)
        } else if (dateType === "year") {
            setCalender(
                <DatePicker
                    selected={date}
                    value={date}
                    customInput={<CustomInput />}
                    onChange={(e) => setDate(e)}
                    dateFormat="yyyy"
                    showYearPicker
                />
            );
            setYearDate(date)
        }
    };

    const handleExpandIcon = async (rowData: PerformanceMonthlyReportDto) => {
        if (expandedRow === rowData.EmployeeCode) {
            setExpandedRow('');
            setReportData((prev: any) => {
                const newReport = [...prev].filter(kp => kp.isKPIPresent === false);
                return newReport;
            })
            return;
        }
        else {
            setExpandedRow(rowData?.EmployeeCode)
        }

        const isHistoryPresent: PerformanceMonthlyReportDto | undefined = historyOfReportData.find(hReport => hReport.EmployeeCode === rowData?.EmployeeCode);

        if (isHistoryPresent?.kpiReport) {
            setReportData((prev: any) => {

                const newReport = [...prev].filter(kp => kp.isKPIPresent === false)
                let indexOfEmpCode: number = newReport.findIndex(x => x.EmployeeCode === rowData?.EmployeeCode)

                isHistoryPresent?.kpiReport?.forEach(kpi => {
                    kpi.isKPIPresent = true;
                    newReport.splice(indexOfEmpCode + 1, 0, kpi);
                    indexOfEmpCode++
                })
                return newReport
            });
            return;
        }

        const input: PerformanceReportInputDto = {
            dateType: dateType === 'month' ? '0' : '1',
            startDate: createStartAndEndDate(date).startDate,
            endDate: createStartAndEndDate(date).endDate,
            employeeCodes: [rowData?.EmployeeCode]
        }

        const kpiRes: PerformanceMonthlyReportDto[] = await getPerformanceReportByKPI(dispatch, input);

        setReportData((prev: any) => {

            const newReport = [...prev].filter(kp => kp.isKPIPresent === false)
            let indexOfEmpCode: number = newReport.findIndex(x => x.EmployeeCode === rowData?.EmployeeCode)

            kpiRes.forEach(kpi => {
                kpi.isKPIPresent = true;
                newReport.splice(indexOfEmpCode + 1, 0, kpi);
                indexOfEmpCode++
            })
            return newReport
        });
        setHistoryOfReportData((prev) => {

            const newReport = [...prev]
            const empReport: PerformanceMonthlyReportDto | undefined = newReport.find(x => x.EmployeeCode === rowData?.EmployeeCode)

            if (empReport) {
                empReport.kpiReport = kpiRes;
            }

            return newReport
        });

    }

    const handleExcelData = async () => {
        setExcelLoader(true);
        setExcelBtn(true);
        try {
            const input: PerformanceReportInputDto = {
                dateType: dateType === 'month' ? '0' : '1',
                startDate: createStartAndEndDate(date).startDate,
                endDate: createStartAndEndDate(date).endDate,
                employeeCodes: createEmpAndProjArr(selectedEmployee as string[])
            }
            const headers = {
                Accept:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                access_token: "Bearer " + localStorage.getItem("token"),
            };

            const response = await instance.post("/performance/getPerformanceExcelReport",
                input, {
                headers,
                responseType: "blob"
            })

            if (response) {
                setExcelLoader(false);
                setExcelBtn(false);
            }
            const blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "PerformanceReport");
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error(error);
        }
    };

    //#endregion

    //#region all Templates
    const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
        <div className="example-custom-input" onClick={onClick} ref={ref}>
            {value}
        </div>
    ));

    const endToolbar = () => {
        return (
            <div className="report-start-tool p-report-header flex-wrap w-100">
                <Button
                    icon={<SiMicrosoftexcel size={20} />}
                    onClick={handleExcelData}
                    className="excel-btn"
                    disabled={excelBtn}
                />
                {excelLoader && (
                    <ProgressSpinner
                        style={{ width: "20px", height: "20px" }}
                        strokeWidth="7"
                        fill="var(--surface-ground)"
                        animationDuration=".5s"
                    />
                )}
                <MultiSelect
                    value={selectedEmployee}
                    filter
                    resetFilterOnHide={true}
                    disabled={disableSelectBtn}
                    onChange={(e) => setSelectedEmployee(e.value)}
                    onHide={getAllReport}
                    options={employeeOptions}
                    placeholder={localization?.SelectEmployees || "Select Employees"}
                    className="w-full md:w-10rem select_width_issue"
                    style={{ width: "11rem" }}
                    display="chip"
                    panelStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }}
                />
                <SelectButton
                    value={dateType}
                    onChange={(e) => handleDate(e)}
                    optionLabel="name"
                    options={buttons}
                    disabled={disableSelectBtn}
                />

                {/* visible input calender */}

                <div className="date-input">
                    <Button
                        icon={<AiOutlineLeft />}
                        onClick={() => handlePrevDate(dateType, date)}
                        className="date-comp-prev-btn"
                        disabled={disableSelectBtn}
                    />
                    {calender}

                    <Button
                        icon={<AiOutlineRight />}
                        onClick={() => handleNextDate(dateType, date)}
                        className="date-comp-next-btn"
                        disabled={disableSelectBtn}
                    />
                </div>
            </div>)
    }

    const employeeNameTemp = (rowData: PerformanceMonthlyReportDto) => {
        return <div className="emp-name-col" style={{ paddingLeft: rowData?.isKPIPresent ? '3.6rem' : '0' }} >
            {!rowData.isKPIPresent && <span onClick={() => handleExpandIcon(rowData)}>{expandedRow === rowData?.EmployeeCode ?
                <FontAwesomeIcon className="col-icon" icon={faChevronDown} /> :
                <FontAwesomeIcon className="col-icon" icon={faChevronRight} />}</span>}
            <span>{rowData?.Name}{!rowData.isKPIPresent && `(${rowData?.EmployeeCode})`}</span>
        </div>
    }

    const scoreTemp = (rowData: any, field: any) => {
        return <span>{rowData[field] === null ? '-' : rowData[field]}</span>
    }

    //#endregion


    useEffect(() => {
        handleCalender();
    }, [dateType, date, mode?.color, window.location.pathname]);

    useEffect(() => {
        getAllReport();
    }, [dateType, date])

    useEffect(() => {
        getAllEmployees();
    }, [])

    return (
        <div className="p-report-toolbar">
            <Toast ref={toast} />
            <Toolbar end={endToolbar}
                style={{
                    backgroundColor: mode?.backgroundSecondaryColor,
                    color: mode?.color,
                }} className="mb-3" />

            <Card  style={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }}>
                {dateType === 'month' ?
                    <DataTable className="performance-table report-chart-table" value={reportData} tableStyle={{ }}>
                        <Column bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} body={employeeNameTemp} header="Employee Name"></Column>
                        <Column bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="AvgRating" header="Score (%)"></Column>
                    </DataTable>
                    :
                    <DataTable className="performance-table audit-log-table" value={reportData} style={{}} >
                        <Column bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} body={employeeNameTemp} header="Employee Name"></Column>
                        <Column bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} body={(e) => scoreTemp(e, 'January')} header='January Score(%)'></Column>
                        <Column bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} body={(e) => scoreTemp(e, 'February')} header='February Score(%)'></Column>
                        <Column bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} body={(e) => scoreTemp(e, 'March')} header='March Score(%)'>-ddddddddddd</Column>
                        <Column bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} body={(e) => scoreTemp(e, 'April')} header='April Score(%)'></Column>
                        <Column bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} body={(e) => scoreTemp(e, 'May')} header='May Score(%)'></Column>
                        <Column bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} body={(e) => scoreTemp(e, 'June')} header='June Score(%)'></Column>
                        <Column bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} body={(e) => scoreTemp(e, 'July')} header='July Score(%)'></Column>
                        <Column bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} body={(e) => scoreTemp(e, 'August')} header='August Score(%)'></Column>
                        <Column bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} body={(e) => scoreTemp(e, 'September')} header='September Score(%)'></Column>
                        <Column bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} body={(e) => scoreTemp(e, 'October')} header='October Score(%)'></Column>
                        <Column bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} body={(e) => scoreTemp(e, 'November')} header='November Score(%)'></Column>
                        <Column bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} body={(e) => scoreTemp(e, 'December')} header='December Score(%)'></Column>
                        <Column bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="AvgRating" header="Score (%)"></Column>
                    </DataTable>
                }
            </Card>

            {/* charts *******************************/}
            <Card  style={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} className="mt-3 performance-report-card-cont">
                {reportData.length > 0 &&
                    (dateType === 'month' ?
                        <EmpPerformanceReportMonthlyChart data={monthlyChartData} date={date} /> :
                        <EmpPerformanceReportYearlyChart data={reportData as PerformanceYearlyReportDto[]} date={date} />
                    )}
            </Card>
        </div>
    )
}
import * as React from "react";
import '../../css/components/kpi.css';
import { DataTable } from "primereact/datatable";
import { Toolbar } from 'primereact/toolbar';
import { createKpi, delKpi, getAllKpi, getAllPaginatedKpi, updateKpi } from "../../service/kpi/kpiService";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Tag } from 'primereact/tag';
import { Dialog } from 'primereact/dialog';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { SelectButton } from 'primereact/selectbutton';
import { useDispatch, useSelector } from "react-redux";
import { CreateKpiDetailsDto } from "../../dtos/kpi/CreateKpiDetailsDto";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { ThemeState } from "../../dtos/common/ThemeState";
import { Themes } from "../../data/Themes";
import { InputTextarea } from "primereact/inputtextarea";
import { AppModeState } from "../../dtos/common/AppModeState";
import { AppMode } from "../../data/AppMode";
import { useEffect } from "react";
import { isMappingPresent } from "../../service/kpiMappings/kpiMappingsService";
import { useNavigate } from "react-router-dom";

export interface LazyState {
  page: number,
  first: number,
  rows: number,
  sortField: string,
  sortOrder: number,
  filters?: string;
}

export const KPITable = () => {

  type Kpi = {
    Name: string;
    Information: string;
    IsActive: boolean | null;
    [key: string]: string | boolean | null;
  };



 

  //#region all Variables
  const emptyKpi: Kpi = {
    Name: '',
    Information: '',
    IsActive: true ? true : false
  }
  const [data, setData] = React.useState<CreateKpiDetailsDto[]>([]);
  const [selectedKpi, setSelectedKpi] = React.useState(undefined);
  const [kpiDialog, setKpiDialog] = React.useState(false);
  const statusOptions = ['Active', 'InActive'];
  const [statusValue, setStatusValue] = React.useState(statusOptions[1]);
  const [kpi, setKpi] = React.useState(emptyKpi);
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const [searchText, setSearchText] = React.useState<string>('');
  const [edit, setEdit] = React.useState<boolean>(false);
  const dt = React.useRef<any>(null);
  const toast = React.useRef(null) as any;
  const [kpiLength, setKpiLength] = React.useState<number>(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find(th => th.name === themeName);
  const localization = useSelector((state: any) => state.localization.localization);
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find(md => md.name === modeName);
  
  const [lazyState, setlazyState] = React.useState({
    page: 0,
    first: 0,
    rows: 5,
    sortField: 'id',
    sortOrder: 0 as any
  });
  const [prevKpi, setPrevKpi] = React.useState(false);
  const [prevKpiDetails, setPrevKpiDetails] = React.useState<any>(null);
  const [showDialog, setShowDialog] = React.useState(false);
  const [allKpis, setAllKpis] = React.useState<CreateKpiDetailsDto[]>([]);


  //#endregion

  //#region all Functions

  const allDataFunc = async () => {
    let param;

    if (searchText.length > 0) {
      param = {
        Limit: lazyState.rows,
        Page: lazyState.page,
        SortBy: lazyState.sortOrder === 1 ? 'ASC' : 'DESC',
        SortProperty: lazyState.sortField,
        Filter: searchText,
        PropertyNames: ['Name']
      }
    }
    else {

      param = {
        Limit: lazyState.rows,
        Page: lazyState.page,
        SortBy: lazyState.sortOrder === 1 ? 'ASC' : 'DESC',
        SortProperty: lazyState.sortField
      }
    }

    const res: any = await getAllPaginatedKpi(dispatch, param);
    setData(res?.List);
    setKpiLength(res?.TotalCount);
  }

  const allKpi = async () => {
    const kpis = await getAllKpi(dispatch);
    setAllKpis(kpis)
  }

  const handleSearchFeature = async () => {
    const res: any = await getAllKpi(dispatch);
    const filteredData: CreateKpiDetailsDto[] = res?.filter((val: CreateKpiDetailsDto) => {
      return val.Name.toLowerCase().includes(searchText.toLowerCase())
    })
    setData(filteredData);
    setKpiLength(filteredData.length)
  }

  const onPageChange = (event: any) => {
    setlazyState(event)
  };

  const onSort = (event: any) => {
    setlazyState((prev: LazyState) => {
      const newState: LazyState = {
        ...prev, // Copy all properties from the previous state
        sortOrder: event.sortOrder, // Update sortOrder
        sortField: event.sortField, // Update sortField
      };
      return newState;
    });
  };

  const openNew = () => {
    setKpi(emptyKpi);
    setSubmitted(false);
    setKpiDialog(true);
    setStatusValue(statusOptions[1])
  };
  const createNewKPI =async ()=>{
    const data = {
      ...kpi,IsActive:statusValue==="Active"?true:false
    }
    if(statusValue==="Active"){
      toast.current.show({severity:'error', summary: 'Error', detail:'After creating the Sub-KPI you can change the  status of the KPI from inactive to active ,', life: 3000});
    }else{
      const createdKpi = await createKpi(dispatch, data);
      toast.current.show({ severity: 'success', summary: 'Created', detail: localization?.DataCreatedSuccessfully || 'You have successfully created the KPI!', life: 3000 });
      setKpiDialog(false);
      return createdKpi
    }

  }

  const editKpi = (rowkpi: any) => {
    setKpiDialog(true);
    setEdit(true);
    setSubmitted(false);
    const kp: Kpi = {
      id: rowkpi.id,
      Name: rowkpi.Name,
      Information: rowkpi.Information,
      IsActive: rowkpi.IsActive
      // IsActive: true ? true : false
    }
    setStatusValue(rowkpi.IsActive === true ? 'Active' : 'InActive')
    setKpi(kp);
    setPrevKpi(rowkpi.IsActive === true ? true : false);
    setPrevKpiDetails(kp)
  }

  const onInputChange = (e: any, name: string) => {
    const val = e.target.value || '';
    let kpis: Kpi = { ...kpi };

    kpis[name] = val;

    setKpi(kpis);
  };

  const handleSave = async () => {
    setSubmitted(true)
    const isPresentSameKPI = allKpis.filter(kpis => {
      return kpis.Name.toLowerCase() === kpi.Name.trim().toLowerCase()
    })

    let error: string = ''
    if (kpi.Name.length === 0) {
      error = 'Please provide some KPI names for saving the KPI'
    }

    if (kpi.Name.length > 0 && kpi.Name.replace(/\s/g, '').length == 0) {
      error = "You can't create any KPI using only blank spaces!"
    }

    if (kpi.IsActive === null) {
      error = 'Please select a KPI activation status for saving the KPI';
    }
    
    if (kpi.Name.length === 0 && kpi.IsActive === null) {
      error = 'Please provide some KPI names for saving, and select a KPI activation status for saving the KPI.';
    }

    if (isPresentSameKPI.length > 0 && edit === false) {
      error = 'This KPI name already exists';
    }

    if (edit === true) {
      if (prevKpiDetails.Name !== kpi.Name) {
        if (isPresentSameKPI.length >= 1 && edit === true) {
          error = 'This KPI name already exists';
        }
      }
    }

    if (error.length === 0) {
      setSubmitted(true)
      if (edit) {
        const isPresentKpi = await isMappingPresent(dispatch, { KPIID: kpi.id });
        if (isPresentKpi) {
          if (prevKpi === true && kpi.IsActive === false) {
            // kpi.IsActive = prevKpi
            setShowDialog(true);
            return
          }
          else {
            const result = await updateKpi(dispatch, kpi.id as unknown as number, kpi);
            if(typeof result === 'string'){
              return toast.current.show({ severity: 'error', summary: 'Error', detail: localization?.DataUpdatedSuccessfully || "Sub KPI is not present for this KPI,So you can't change the status from inactive to active", life: 3000 });
            }
            toast.current.show({ severity: 'success', summary: 'Updated', detail: localization?.DataUpdatedSuccessfully || 'You have successfully updated the KPI!', life: 3000 });
            setKpiDialog(false);
            await allDataFunc();
            await allKpi()
          }
        }
        else {
          const result = await updateKpi(dispatch, kpi.id as unknown as number, kpi);
          if(typeof result === 'string'){
            return toast.current.show({ severity: 'error', summary: 'Error', detail: localization?.DataUpdatedSuccessfully || "Sub KPI is not present for this KPI,So you can't change the status from inactive to active", life: 3000 });
          }
          toast.current.show({ severity: 'success', summary: 'Updated', detail: localization?.DataUpdatedSuccessfully || 'You have successfully updated the KPI!', life: 3000 });
          setKpiDialog(false);
          await allDataFunc();
          await allKpi()
        }
        setEdit(false)
      } else {
       
        const createdKpi = await createNewKPI();
      
        await allDataFunc();
      }

    } else {
      toast.current.show({ severity: 'error', summary: 'Please Note!', detail: error, life: 5000 });
    }
  };


  const deleteKpi = async (kpi: any) => {
    setKpi(kpi)
    confirm2(kpi.id);
  }
  const subKpi=(kpi:any)=>{
   
    setKpi(kpi)
    navigate(`/kpi/${kpi.id}`)
  }

  const accept = async (id: number) => {
    const res = await delKpi(dispatch, id as unknown as number);
    await allDataFunc()
    await allKpi()
    toast.current.show({ severity: res.length > 38 ? 'warn' : 'success', summary: 'Delete Info', detail: res, life: 5000 });
  }

  const reject = () => {
    toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected the delete operation', life: 3000 });
  }

  const handleStatusValue = (e: any) => {
    if (e.value === null) {
      setStatusValue((prev) => prev)
    } else {
      setStatusValue(e.value)
    }
  }

  const handleKpiDialog = () => {
    setKpiDialog(false);
    setEdit(false)
  }

  //#endregion

  //#region all Templates

  const startToolbar = () => {
    return (
      <div>
        <h4>{localization?.KPI || 'KPI'}</h4>
      </div>
    )
  };

  const endToolbar = () => {
    return (
      <div className="endToolbar">
        <Button className="justify-content-center" label={localization?.Create || 'Create'} onClick={openNew} style={{ width: '30%', backgroundColor: ThemeColors?.primaryColor, border: 'none' }} />
        <InputText type="search" placeholder={` ${localization?.Search || 'Search'}...`} onChange={(e) => {
          setTimeout(() => {
            setSearchText(e.target.value.trim())
          }, 500)
        }} />
      </div>
    )
  };

  const editDeleteButtonTemplate = (rowData: any) => {
    return (
      <div className='editDeleteButtonTemplate bengali_btn_style'>
           <Button label={localization?.SubKPI || 'SubKPI'} className="editDelButton" onClick={() => subKpi(rowData)} style={{ backgroundColor: ThemeColors?.primaryColor }} />
        <Button label={localization?.Edit || 'Edit'} className="mr-2 editDelButton" style={{ backgroundColor: ThemeColors?.primaryColor }} onClick={() => editKpi(rowData)} />
        <Button label={localization?.Delete || 'Delete'} className="editDelButton" onClick={() => deleteKpi(rowData)} style={{ backgroundColor: '#d80300' }} />
      </div>
    );
  };

  const activeBodyTemplate = (rowData: any) => {
    return <Tag className={rowData.IsActive === true ? 'activeIcon' : 'inactiveicon'} value={rowData.IsActive === true ? (<FontAwesomeIcon icon={faCheck} />) : (<FontAwesomeIcon icon={faXmark} />)} rounded></Tag>
  };

  const confirm2 = (id: number) => {
    confirmDialog({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: '',
      className: 'del_modal',
      headerStyle: { backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color },
      style: { width: '350px', backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color },
      contentStyle: { backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color },
      acceptClassName: 'p-button-danger',
      accept: () => accept(id),
      reject
    });
  };

  const dialogHeader = () => {
    return (
      <>
        <div className="dialogHeader">
          <h4>{edit ? 'Edit' : 'Create'} Kpi</h4>
        </div>
        {/* <hr /> */}
      </>
    )
  }

  const dialogFooter = () => {
    return (
      <div style={{ padding: '1rem 0', backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }}>
        <Button label="Save" className="kpi-save-button" onClick={handleSave} style={{ backgroundColor: ThemeColors?.primaryColor }} />
      </div>
    )
  }

  //#endregion


  useEffect(() => {
    (statusValue === 'Active') ? kpi.IsActive = true : (statusValue === null) ? kpi.IsActive = null : kpi.IsActive = false
  }, [statusValue])

  useEffect(() => {
    allDataFunc()
  }, [searchText, lazyState]);

  useEffect(() => {
    allKpi()
  }, [])

  return (
    <div>
      <Dialog header="Error:" visible={showDialog} style={{ width: '50vw', backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} contentStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} onHide={() => setShowDialog(false)}>
        <p style={{ padding: "1rem 0" }}>
          Unable to directly modify active status. Navigate to the Kpi Mappings page, remove mapping, and then update.
        </p>
      </Dialog>
      <Toast ref={toast} />
      <ConfirmDialog />
      <Toolbar className="mb-3" start={startToolbar} end={endToolbar} style={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} />
      <Card style={{ backgroundColor: mode?.backgroundSecondaryColor }} className="kpi_tabile_res">
        <DataTable
          className="audit-log-table"
          ref={dt}
          value={data}
          selection={selectedKpi}
          onSelectionChange={(e: any) => setSelectedKpi(e.value)}
          dataKey="id"
          lazy
          paginator
          first={lazyState.first}
          rows={lazyState.rows}
          totalRecords={kpiLength}
          onSort={onSort}
          onPage={onPageChange}
          sortOrder={lazyState.sortOrder}
          sortField={lazyState.sortField}
          showGridlines
          rowsPerPageOptions={[5, 8, 10, 20]}
          currentPageReportTemplate="Total Records {totalRecords} "
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        >
          <Column className="kpiColumn" bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="Name" header={localization?.KPIName || 'KPI Name'} sortable ></Column>
          <Column className="kpiColumn" bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="Information" header={localization?.KPIInformation || 'KPI Information'} sortable ></Column>
          <Column className="kpiColumn col_center col_150" bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, textAlign: 'center' }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="IsActive" header={localization?.IsActive || 'IsActive'} body={activeBodyTemplate}  ></Column>
          <Column className="kpiColumn col_center actionColumn" bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} body={editDeleteButtonTemplate} header={localization?.Action || 'Action'}  ></Column>
        </DataTable>
      </Card>

      {/* Product dialog **********************/}

      <Dialog visible={kpiDialog} header={dialogHeader} footer={dialogFooter} style={{ width: "50vw", backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} contentStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} baseZIndex={999} onHide={handleKpiDialog} className="dialog">
        <div className="field" >
          <label htmlFor="name" className="font-bold">
            {localization?.Name || 'Name'}
          </label>
          <InputText type="text" id="name" value={kpi?.Name} onChange={(e) => onInputChange(e, 'Name')} className="kpiInput" required autoFocus />
          {submitted && kpi.Name.length === 0 && <small className="p-error">KPI Name is required.</small>}
        </div>
        <div className="field">
          <label htmlFor="information" className="font-bold">
            {localization?.Information || 'Information'}
          </label>
          <InputTextarea className="custom-textarea" id="information" value={kpi?.Information} onChange={(e) => onInputChange(e, 'Information')} rows={2} cols={60} autoResize={true} />
        </div>
        <div className="field">
          <label htmlFor="status" className="font-bold">
            {localization?.IsActive || 'IsActive'}
          </label>
          <SelectButton value={statusValue} onChange={(e) => handleStatusValue(e)} options={statusOptions} />
        </div>

      </Dialog>
    </div>
  )

}

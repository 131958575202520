import { Card } from "primereact/card";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppModeState } from "../../dtos/common/AppModeState";
import { faCheck, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAllUserGoals } from "../../service/userGoals/userGoalService";
import "../../css/components/userGoal.css";
import UserGoalInfo from "./UserGoalInfo";
import { LoggedInUserData } from "../../shared/functions/LoggedInUserData";
import { GetOneUserDto } from "../../dtos/user/GetOneUserDto";
import { AllUserGoalsDto } from "../../dtos/userGoals/AllUserGoalsDto";
import { getAssignedGoalsByEmp } from "../../service/goals/goalsService";
import { AssignedGoalDto } from "../../dtos/goals/AssignedGoalDto";
import { GoalStepsDto } from "../../dtos/goals/GoalsStepsDto";

import { AppMode } from "../../data/AppMode";
import { Toolbar } from "primereact/toolbar";
import { Tooltip } from "primereact/tooltip";
import { getAllUserSkillsRelatedToGoal } from "../../service/userSkill/userSkillServices";
import { skillLogos } from "../../shared/constant/SkillImages";

const UserGoals = () => {
  //#region all Variables
  const localization = useSelector(
    (state: any) => state.localization.localization
  );
  const [showUserGoalInfo, setShowUserGoalInfo] = useState<boolean>(false);
  const [allUserGoals, setAllUserGoals] = useState<AllUserGoalsDto[] | []>([]);
  const [allAssignedGoal, setAllAssignedGoal] = useState<
    AssignedGoalDto[] | []
  >([]);
  const dispatch = useDispatch();
  const [selectedGoalSteps, setSelectedGoalSteps] = useState<
    GoalStepsDto[] | []
  >([]);
  const [scorePercentage, setScorePercentage] = useState<number>(0);
  const [selectedGoalName, setSelectedGoalName] = useState<string>("");
  const [selectedGoalDesc, setSelectedGoalDesc] = useState<string>("");
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const [empCode, SetEmpCode] = useState<string | null>(null);
  const [empProgress, setEmpProgress] = useState({
    totalValue: 0,
    totalScore: 0,
  });
  const [allUserSkills, setAllUserSkills] = useState<{ SkillName: string }[] | []>([])

  //#endregion

  //#region all Functions

  const getAllUserGoal = async () => {
    const data: GetOneUserDto = await LoggedInUserData(dispatch);
    SetEmpCode(data.EmployeeCode);
    const response = await getAllUserGoals(dispatch, {
      employeeCode: data.EmployeeCode,
    });

    setAllUserGoals(response);
  };

  const getStepsByGoalNameAndEmpCode = async () => {
    const allGoals = await getAssignedGoalsByEmp(dispatch, {
      goalName: selectedGoalName,
      empCode: empCode,
    });

    setAllAssignedGoal(allGoals);
    setEmpProgress({
      totalValue: allGoals.totalValue,
      totalScore: allGoals.totalScore,
    });
    setSelectedGoalSteps(allGoals.GoalSteps);
    setScorePercentage(allGoals.UserGoalStepScorePercentage!);
    setSelectedGoalDesc(allGoals.GoalDescription);
  };

  //#endregion

  //#region all Templates

  const descriptionTemplate = (rowData: any) => {
    return (
      <>
        {rowData.Description.length > 70 ? (
          <>
            {rowData.Description.slice(0, 70)}
            <span
              className={`description-expanded-dots${rowData.id} expanded-dots`}
              data-pr-position="bottom"
            >
              ...
              <Tooltip
                target={`.description-expanded-dots${rowData.id}`}
                content={rowData.Description}
                className="w-75 w-md-200px"
              />
            </span>
          </>
        ) : (
          rowData.Description
        )}
      </>
    );
  };

  const goalNameTemplate = (rowData: any) => {
      const skillImg = Object.keys(skillLogos).find((skillName: string) => {
          const skillWords = rowData.GoalName.toLowerCase().split(/[\s\-\.]+/); // Split by spaces, dashes, or dots
          return skillWords.includes(skillName.toLowerCase());
        });
    return (
      <div>
        <img
          src={skillImg && skillLogos[skillImg] || skillLogos.default}
          alt={`logo`}
          className="p-avatar-img rounded-4 me-2"
          style={{ width: '30px', height: '30px', borderRadius: '50%' }}
        />
        {rowData.GoalName.length > 15 ? (
          <>
            {rowData.GoalName.slice(0, 15)}
            <span
              className={`name-expanded-dots${rowData.id} expanded-dots`}
              data-pr-position="bottom"
            >
              ...
              <Tooltip
                target={`.name-expanded-dots${rowData.id}`}
                content={rowData.GoalName}
                className="w-75 w-md-200px"
              />
            </span>
          </>
        ) : (
          rowData.GoalName
        )}
      </div>
    );
  };

  const startToolbar = () => {
    return <h4>{localization?.YourGoals || "Your Goals"}</h4>;
  };

  const getAllUserSkills = async (selectedGoalName: string, empCode: string) => {
    const userSkills = await getAllUserSkillsRelatedToGoal(dispatch, { GoalName: selectedGoalName, EmployeeCode: empCode })
    setAllUserSkills(userSkills)
  }

  //#endregion

  useEffect(() => {
    if (selectedGoalName && empCode) {
      getStepsByGoalNameAndEmpCode();
      getAllUserSkills(selectedGoalName, empCode)
    }
  }, [selectedGoalName, empCode]);


  useEffect(() => {
    getAllUserGoal();
  }, []);
  return (
    <div>
      <Toolbar
        start={startToolbar}
        style={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        className="bg-transparent border-0 p-0 mb-3"
      />

      <div
        //  style={{
        //   backgroundColor: mode?.backgroundSecondaryColor,
        //   color: mode?.color,
        // }}
        className="myGoalCont"
      >
        <div
          className="row row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-1 g-0"

        >
          {allUserGoals.map((goal: any) => {
            return (
              <div className="col shadow-none p-0 me-3">
                <Card
                  title={
                    <span style={{ minHeight: "2.5rem" }}>
                      {goalNameTemplate(goal)}
                    </span>
                  }
                  className={`card ${goal.IsGoalCompleted ? "done" : ""
                    } w-100 goal_card p-0 cs_card_shadow`}

                  style={{
                    cursor: "pointer",
                    // backgroundColor: mode?.backgroundPrimaryColor,
                    //   color: mode?.color, 
                  }}
                  onClick={() => {
                    setShowUserGoalInfo(true);
                    setSelectedGoalName(goal?.GoalName);
                  }}
                >
                  {
                    goal.Description !== null &&
                    goal.Description.length > 0 && <p className="m-0" style={{ minHeight: "2.8rem" }}>

                      <span className="light-gray goal-card-description">
                        {
                          descriptionTemplate(goal)
                        }
                      </span>
                    </p>

                  }
                  <div className="align-in-between">
                    <div className="d-inline-flex duration-block rounded-2 py-1 px-2 my-2">
                      <FontAwesomeIcon
                        icon={faClock}
                        className="duration-icon"
                      />
                      <span>
                        {localization?.Duration || "Duration"} {goal?.Duration || 0}{" "}
                        {localization?.Days || "Days"}
                      </span>
                    </div>
                    {goal.IsGoalCompleted && (
                      <div className="align-at-end">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="done-check-mark"
                        />
                        <span className="done-mark">
                          {" "}
                          {localization?.Done || "Done"}
                        </span>
                      </div>
                    )}
                  </div>
                </Card>
              </div>
            );
          })}
        </div>
      </div>
      <UserGoalInfo
        showUserGoalInfo={showUserGoalInfo}
        setShowUserGoalInfo={setShowUserGoalInfo}
        selectedGoalSteps={selectedGoalSteps}
        scorePercentage={scorePercentage}
        selectedGoalName={selectedGoalName}
        selectedGoalDesc={selectedGoalDesc}
        empProgress={empProgress}
        allUserSkills={allUserSkills}
      />
    </div>
  );
};

export default UserGoals;

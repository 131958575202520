import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Toolbar } from "primereact/toolbar"
import { useEffect, useState } from "react";
import { CronJoStatusDto } from "../../dtos/cronJob/CronJobStatusDto";
import { CronJobDataInputDto } from "../../dtos/cronJob/CronJobDataInputDto";
import { getAllCronJobStatus } from "../../service/cronJob/CronJobServices";
import { useDispatch, useSelector } from "react-redux";
import { AppMode } from "../../data/AppMode";
import { Themes } from "../../data/Themes";
import { AppModeState } from "../../dtos/common/AppModeState";
import { ThemeState } from "../../dtos/common/ThemeState";
import moment from "moment";
import { GetPaginationRowsCount } from "../../shared/functions/GetPaginationRowsCount";
import { GetPaginationRowsInterface } from "../../dtos/common/GetPaginationRows";

export const CronJobStatus = () => {
    const [cronJobStatus, setCronJobStatus] = useState<CronJoStatusDto[]>([]);
    const [totalCronJobStatusCount, setTotalCronJobStatusCount] = useState<number>(0);
    const dispatch = useDispatch();
    const themeName = useSelector((state: ThemeState) => state.theme.themeName);
    const ThemeColors = Themes.find(th => th.name === themeName);
    const modeName = useSelector((state: AppModeState) => state.theme.appMode);
    const mode = AppMode.find(md => md.name === modeName);
    const row: GetPaginationRowsInterface = GetPaginationRowsCount();
    const [lazyState, setlazyState] = useState<any>({
        page: 0,
        first: 0,
        rows: row.rowCount
    });

    const getAllCronJobStatusData = async () => {
        const input: CronJobDataInputDto = {
            limit: lazyState.rows,
            page: lazyState.page
        }

        const allCronJob = await getAllCronJobStatus(dispatch, input);
        setCronJobStatus(allCronJob.data);
        setTotalCronJobStatusCount(allCronJob.totalLength)
    }

    const onPageChange = (event: any) => {
        setlazyState(event)
    };


    const startToolBar = () => {
        return <div>
            <h4>CronJob Status</h4>
        </div>
    }
    const createdAtBodyTempForStatus = (rowData: any) => {
        return `${moment(rowData.CreatedAt).format('lll')}`
    }

    useEffect(() => {
        getAllCronJobStatusData()
    }, [lazyState])

    return (
        <div className="cronjob_container cronJobStatus">
            <Toolbar className="bg-transparent border-0 p-0" start={startToolBar} />

            {/* cron job status */}
            <div className="cronJobStatusTable border_primary cs_card_shadow cs_card">
                <DataTable
                    className="performance-table audit-log-table"
                    value={cronJobStatus}
                    dataKey="id"
                    lazy
                    paginator
                    totalRecords={totalCronJobStatusCount}
                    first={lazyState.first}
                    rows={lazyState.rows}
                    onPage={onPageChange}
                    showGridlines
                    rowsPerPageOptions={row.rowList}
                    currentPageReportTemplate="Total Records {totalRecords} "
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown">
                    <Column style={{ minWidth: '12rem' }} bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="Name" header="Job Name" >
                    {/* <span className="badge badge-light-success fw-semibold fs-8 px-2 ms-2">New</span> */}
                    </Column>
                    <Column style={{ minWidth: '12rem' }} bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="Reason" header="Job Reason" ></Column>
                    <Column style={{ minWidth: '12rem' }} bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="Status" header="Job Status" ></Column>
                    <Column style={{ minWidth: '12rem' }} bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} body={createdAtBodyTempForStatus} field="CreatedAt" header="Created Date" ></Column>
                </DataTable>
            </div>
        </div>
    )
}
import * as React from "react"
import { Main } from "../components/main/Main";
import "../css/pages/home.css";
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import ProSidebar from "../components/navbars/ProSidebar";
import { Toast } from "primereact/toast";
import { setError } from "../store/slices/toastSlice";



export const Home = () => {
    const err = useSelector((state: any) => state?.error);
    const toastError = useSelector((state: any) => state.toastError);
    const toast = useRef<Toast>(null);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [visible, setVisible] = React.useState(false);

    // ✅ State to track online/offline status
    const [isOnline, setIsOnline] = React.useState(navigator.onLine);
    const [offlineDialog, setOfflineDialog] = React.useState(!navigator.onLine);
    useEffect(() => {
        const handleOnline = () => {
            setIsOnline(true);
            setOfflineDialog(false); // Hide the offline dialog
        };

        const handleOffline = () => {
            setIsOnline(false);
            setOfflineDialog(true); // Show the offline dialog
        };

        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);

        return () => {
            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);
        };
    }, []);


    React.useEffect(() => {
        setVisible(err?.isError);
    }, [err]);




    React.useEffect(() => {
        if (toastError.isError) {
            showToast("error", toastError.errorMessage, "Error");
            // Optionally reset the error state after a delay
            setTimeout(() => {
                dispatch(setError({ isError: false, errorMessage: "" }));
            }, 3000); // Adjust timing as needed
        }
    }, [toastError.isError, toastError.errorMessage, dispatch]);


    const isLogin = useSelector((state: any) => state.login.islogin);

    const showToast = (severity: any, detail: string, summary: string) => {
        toast.current?.show({ severity, summary, detail, life: 3000 });
    };

    useEffect(() => {
        if (!isLogin) {
            navigate("/login")
        }
    }, [isLogin, navigate])


    return (
        <div className='home-page'>
            <Dialog header="Error!" visible={visible} style={{ width: '50vw', backgroundColor: 'white' }} onHide={() => setVisible(false)}>
                <p style={{ padding: '1rem 0' }}>
                    {err?.errorMessage}
                </p>
            </Dialog>
            <Toast ref={toast} />

            <ProSidebar />
            <Main />
        </div>
    )
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skill-chip{
    display:flex;
    font-size:14px;
    flex-wrap:wrap;
    width: max-content;
    margin:5px;
}

.skill-icon{
    cursor:pointer;
    font-size:18px;
}

.skill-avatar{
    background-color:transparent;
    padding:5.2px;
}`, "",{"version":3,"sources":["webpack://./src/css/components/goalTable.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,cAAc;IACd,cAAc;IACd,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,4BAA4B;IAC5B,aAAa;AACjB","sourcesContent":[".skill-chip{\n    display:flex;\n    font-size:14px;\n    flex-wrap:wrap;\n    width: max-content;\n    margin:5px;\n}\n\n.skill-icon{\n    cursor:pointer;\n    font-size:18px;\n}\n\n.skill-avatar{\n    background-color:transparent;\n    padding:5.2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

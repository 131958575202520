import * as React from "react";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { getAllPerformanceWithKPI } from "../../service/performance/PerformanceService";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, AccordionTab } from "primereact/accordion";
import "../../css/components/performance.css";
import { MainPerformanceDto } from "../../dtos/performance/MainPerformanceDataDto";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AppModeState } from "../../dtos/common/AppModeState";
import { AppMode } from "../../data/AppMode";
import { ThemeState } from "../../dtos/common/ThemeState";
import { Themes } from "../../data/Themes";
import {
  AiFillDownCircle,
  AiFillRightCircle,
  AiOutlineLeft,
  AiOutlineRight,
} from "react-icons/ai";
import { SelectButton } from "primereact/selectbutton";
import { Paginator } from "primereact/paginator";
import { forwardRef, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { GetOneUserDto } from "../../dtos/user/GetOneUserDto";
import { LoggedInUserData } from "../../shared/functions/LoggedInUserData";
import { setPath } from "../../store/slices/routePathSlice";
import DatePicker from "react-datepicker";
import ReactStars from "react-stars";
import { Dropdown } from "primereact/dropdown";
import { getAllRoleFromKpiMappings } from "../../service/kpiMappings/kpiMappingsService";
import { KpiMappingsRoleDto } from "../../dtos/role/KpiMappingsRoleDto";
import { ISTToDate } from "../../shared/functions/ISTToDate";
import { isNotUpcomingDate } from "../../shared/functions/IsNotUpcomingDateFunc";
import { Toast } from "primereact/toast";
import { setInputs } from "../../store/slices/performanceViewInput";
import { HighStarColor, LowStarColor, LowStarVal, MidStarColor, MidStarVal } from "../../shared/constant/StarValue";
import { GetPreviousMonth } from "../../shared/functions/GetPreviousMonth";
import { getUserRole } from "../../service/user/userService";
import { performanceDate, performanceEmpCode } from "../../shared/constant/Common";

export const PerformanceTable = () => {
  // #region all Variables

  const [data, setData] = React.useState<any>([]);
  const [searchText, setSearchText] = useState<string>("");
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const localization = useSelector(
    (state: any) => state.localization.localization
  );
  const mode = AppMode.find((md) => md.name === modeName);
  const columnOptions: any = [
    { name: localization?.Name || "Name", colName: "EmployeeName" },
    { name: localization?.Rating || "Rating", colName: "AverageRating" },
  ];
  const [sortColumn, setSortColumn] = useState<any>(columnOptions[1]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [itemsPerPage, setItemsPerPage] = useState<number>(8); // Number of items to display per page
  const totalItems = data.length;
  const [currentPage, setCurrentPage] = useState(0);
  
  //get previous month
  // const presentDate = new Date();
  // presentDate.setMonth(presentDate.getMonth() - 1);
  const [date, setDate] = useState<any>(GetPreviousMonth(new Date()));
  const [empCode,setEmpCode] = useState<string>()
  const [performanceViewData, setPerformanceViewData] =
    useState<MainPerformanceDto>();
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [allRoles, setAllRoles] = useState<KpiMappingsRoleDto[]>([]);
  const [selectedRole, setSelectedRole] = useState<KpiMappingsRoleDto | null>(
    null
  );
  const [disableSelectBtn, setDisableSelectBtn] = useState<boolean>(false);
  const toast = useRef<any>(null);
  const [role, setRole] = useState('');


  //#endregion

  // #region all Functions
  const fetchRole = async () => {
    const roleData = await getUserRole(dispatch);
    setRole(roleData);
  }

  const handlePageChange = (event: any) => {
    setCurrentPage(event.page);
    setItemsPerPage(event.rows)
    setActiveIndex(0);
  };

  const getAllData = async () => {
    const data: GetOneUserDto = await LoggedInUserData(dispatch);
    const inputDate = {
      submittedDate: ISTToDate(date),
      loggedInEmpCode: data.EmployeeCode,
      roleName: selectedRole === null ? "" : typeof selectedRole?.RoleName === 'undefined' ? "" : selectedRole?.RoleName,
    };
    setDisableSelectBtn(true);
    const performanceWithKPI: MainPerformanceDto[] =
      await getAllPerformanceWithKPI(dispatch, inputDate);
    const filterData = performanceWithKPI?.filter((perf) => {
      return perf?.EmployeeName?.toLowerCase().includes(
        searchText.toLowerCase()
      );
    });

    setData(filterData);
    setDisableSelectBtn(false);
  };

  const getAllRoles = async () => {
    const roles = await getAllRoleFromKpiMappings(dispatch);
    const transformedRoles = roles.map((role: { RoleName: string }) => {
      return {
        ...role,
        RoleName: role.RoleName.slice(0, 1).toUpperCase() + role.RoleName.slice(1)
      }
    })
    setAllRoles(transformedRoles);
  };

  const handleSort = () => {
    if (arrow === 'up') {
      const newData = [...data];
      if (sortColumn.colName === "AverageRating") {
        newData.sort((a: MainPerformanceDto, b: MainPerformanceDto) => {
          return a.AverageRating - b.AverageRating;
        });
        setData(newData);
      } else {
        newData.sort((a: MainPerformanceDto, b: MainPerformanceDto) =>
          b.EmployeeName.localeCompare(a.EmployeeName)
        );
        setData(newData);
      }
      setArrow("down");
    }
    else {
      const newData = [...data];
      if (sortColumn.colName === "AverageRating") {
        newData.sort((a: MainPerformanceDto, b: MainPerformanceDto) => {
          return b.AverageRating - a.AverageRating;
        });
        setData(newData);
      } else {
        newData.sort((a: MainPerformanceDto, b: MainPerformanceDto) =>
          a.EmployeeName.localeCompare(b.EmployeeName)
        );
        setData(newData);
      }
      setArrow("up");
    }
  };
  const [arrow, setArrow] = React.useState("up");

  const routePath = localStorage.getItem("lastRoute");

  const handleRoute = (
    path: string,
    mode: string,
    btn: string,
    data: MainPerformanceDto
  ) => {
    dispatch(setInputs({
      mode: mode,
      date: date,
      data: data,
      employeeCode:data.EmployeeCode,
      roleName: selectedRole === null ? "" : typeof selectedRole?.RoleName === 'undefined' ? "" : selectedRole?.RoleName,
    }))
    localStorage.setItem(performanceDate, date)
    localStorage.setItem(performanceEmpCode,data.EmployeeCode)
    localStorage.setItem("lastRoute", path);
    navigate(path);
    dispatch(setPath({ path: path }));
  };

  //handle date input
  const handleDate = (e: any) => {
    //if the date is upcoming date then set it current date
    if (!isNotUpcomingDate(e)) {
      setDate((prev: any) => {
        const newDate = prev;
        return newDate
      })
      toast.current.show({ severity: 'warn', summary: 'Sorry!', detail: "You can't go to the current and upcoming months!", life: 3000 });
      return;
    }
    setDate(e);
  }

  const handlePrevMonth = () => {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    firstDay.setMonth(firstDay.getMonth() - 1);
    setDate(firstDay);
  };

  const handleNextMonth = () => {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    firstDay.setMonth(firstDay?.getMonth() + 1);

    //if the date is upcoming date then set it current date
    if (!isNotUpcomingDate(firstDay.toString())) {
      setDate((prev: any) => {
        const newDate = prev;
        return newDate
      })
      toast.current.show({ severity: 'warn', summary: 'Sorry!', detail: "You can't go to the current and upcoming months!", life: 3000 });
      return;
    }
    setDate(firstDay);
  };

  const handleSortColumn = (e: any) => {
    if (e.value.name === 'Name') {
      const newData = [...data];
      setSortColumn(e.value)
      newData.sort((a: MainPerformanceDto, b: MainPerformanceDto) =>
        a.EmployeeName.localeCompare(b.EmployeeName)
      );
      setData(newData);
      setArrow("up");
    }
    else {
      const newData = [...data];
      setSortColumn(e.value)
      newData.sort((a: MainPerformanceDto, b: MainPerformanceDto) => {
        return b.AverageRating - a.AverageRating;
      });
      setData(newData);
      setArrow("up");
    }
  }

  const getStarColor = (val: number) => {
    const rating = Number((val / 20).toFixed(2));
    if (rating < LowStarVal) {
      return LowStarColor;
    }

    if (rating > LowStarVal && rating < MidStarVal) {
      return MidStarColor
    }

    if (rating > MidStarVal) {
      return HighStarColor;
    }
  }

  // #endregion

  // #region all Templates

  const startToolbar = () => {
    return (
      <div>
        <h4>{localization?.RatingsReviews || "Ratings & Reviews"}</h4>
        <p>{localization?.Viewratingsreviewsinformation || "View ratings & reviews information"}</p>
      </div>
    );
  };
  const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <div className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
    </div>
  ));

  const sortTemplate = (option: any) => {
    return option.name;
  };

  const endToolbar = () => {
    return (
      // role dropdown only shows for admin
      < div className="endToolbarPerformance" >
        {/* {(roleOfLoggedInUser !== null && roleOfLoggedInUser === 'admin') && ( */}
        {role === 'admin' && <Dropdown
          value={selectedRole}
          onChange={(e) => setSelectedRole(e.value)}
          resetFilterOnHide={true}
          options={allRoles}
          optionLabel="RoleName"
          filter
          placeholder={localization?.Role || "Role"}
          className="w-full md:w-12rem Etool_dropdown"
          disabled={disableSelectBtn}
          showClear
          panelStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }}
        />}
        {/* )} */}
        <div className="date-input">
          <Button
            icon={<AiOutlineLeft />}
            onClick={handlePrevMonth}
            className="date-comp-prev-btn"
            disabled={disableSelectBtn}
          />
          <DatePicker
            selected={date}
            onChange={handleDate}
            customInput={<CustomInput />}
            dateFormat="MMMM yyyy"
            showMonthYearPicker
          />
          <Button
            icon={<AiOutlineRight />}
            onClick={handleNextMonth}
            className="date-comp-next-btn"
            disabled={disableSelectBtn}
          />
        </div>

        <div className="sortingContainer">
          <SelectButton
            value={sortColumn}
            onChange={handleSortColumn}
            options={columnOptions}
            itemTemplate={sortTemplate}
            optionLabel="colName"
            disabled={disableSelectBtn}
          />
          {arrow === "down" ? (
            <i
              className="pi pi-sort-amount-down-alt sortIcon"
              onClick={handleSort}
            ></i>
          ) : (
            <i
              className="pi pi-sort-amount-up-alt sortIcon"
              onClick={handleSort}
            ></i>
          )}
        </div>
        <InputText
          type="search"
          placeholder={localization?.Search || "Search"}
          onChange={(e) => {
            setTimeout(() => {
              setSearchText(e.target.value);
            }, 1000);
          }}
        />
      </div >
    );
  };
  const template = {
    layout:"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
    RowsPerPageDropdown: (options:any) => {
        const dropdownOptions = [
            { label: 8, value: 8 },
            { label: 10, value: 10 },
            { label: 20, value: 20 }
        ];

        return (
            <React.Fragment>
                <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
                    Total Records {options.totalRecords}
                </span>
                <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
            </React.Fragment>
        );
    },
  
};

  // #endregion

  // #region useEffects
  const handleBackButton = () => {
    // This function will be called when the user clicks the back button
    if (location.pathname === "/performance") {
      navigate("/performance");
    }
  };

  useEffect(() => {
    getAllRoles();
    window.addEventListener("popstate", handleBackButton);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  useEffect(() => {
    setSearchText("");
  }, [routePath]);

  useEffect(() => {
    getAllData();
  }, [searchText, date, routePath, selectedRole]);

  useEffect(() => {
    fetchRole()
  }, [])

  // #endregion

  return (
    <div>
      <Toast ref={toast} />
      <div>
        <Toolbar
          start={startToolbar}
          end={endToolbar}
          style={{
            backgroundColor: mode?.backgroundSecondaryColor,
            color: mode?.color,
          }}
          className="mb-3 performance_toolbar"
        />

        <Card
          style={{
            backgroundColor: mode?.backgroundSecondaryColor,
            color: mode?.color,
          }}
        >
          {data.length > 0 ? (
            <Accordion
              multiple
              onTabChange={(e: any) => setActiveIndex(e.index)}
              activeIndex={activeIndex}
              className="main-performance grid"
              expandIcon={
                <AiFillRightCircle
                  style={{ marginRight: "10px" }}
                  size={22}
                />
              }
              collapseIcon={
                <AiFillDownCircle style={{ marginRight: "10px" }} size={22} />
              }
            >
              {data
                ?.slice(
                  currentPage * itemsPerPage,
                  (currentPage + 1) * itemsPerPage
                )
                ?.map((emp: any) => (
                  <AccordionTab
                    key={emp?.EmployeeCode}
                    className="per-head"
                    headerStyle={{
                      backgroundColor: ThemeColors?.primaryColor,
                      color: ThemeColors?.fontColor,
                    }}
                    header={
                      <div className="header-performance col">
                        <div className="employee-name">
                          {" "}
                          {emp?.EmployeeName}({emp.EmployeeCode})
                        </div>
                        <div className="main-ratings">
                          <span>
                            {Number((emp.AverageRating / 20).toFixed(2))}
                          </span>
                          <ReactStars
                            count={5}
                            edit={false}
                            value={Number(
                              (emp.AverageRating / 20).toFixed(2)
                            )}
                            size={23}
                            color2={getStarColor(emp.AverageRating)}
                            color1='#ccc'
                            className="rating-comp"
                          />
                        </div>
                        {emp.IsSubmitted ? (
                          <Button
                            label="View"
                            className="per-view-btn"
                            onClick={() =>
                              handleRoute(
                                `/performance/${emp.EmployeeCode}`,
                                "View",
                                "Review",
                                emp
                              )
                            }
                            outlined
                            style={{ color: "white" }}
                          />
                        ) : (
                          <Button
                            label="Review"
                            className="per-view-btn"
                            onClick={() =>
                              handleRoute(
                                `/performance/${emp.EmployeeCode}`,
                                "Review",
                                "View",
                                emp
                              )
                            }
                            outlined
                            style={{ color: "white" }}
                          />
                        )}
                      </div>
                    }
                  >
                    {emp?.KPIs?.map((per: any) => (
                      <div
                        id={per?.kpiId}
                        key={per?.kpiId}
                        className="performance-tab"
                        style={{ color: mode?.color }}
                      >
                        <div className="sub-ratings">
                          <div className="kpi-name">{per?.kpiName}</div>
                          <div className="main-ratings">
                            <span>
                              {per?.rating?Number((per?.rating / 20).toFixed(2)):0}
                            </span>
                            <ReactStars
                              count={5}
                              edit={false}
                              value={Number((per?.rating / 20).toFixed(2))}
                              size={22}
                              color2={getStarColor(per?.rating)}
                              color1='#ccc'
                              className="rating-comp"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </AccordionTab>
                ))}
            </Accordion>
          ) : (
            <div className="no-data">No records to show</div>
          )}

          <Paginator
            first={currentPage * itemsPerPage}
            rows={itemsPerPage}
            totalRecords={totalItems}
            onPageChange={handlePageChange}
            template={template}
            className="mt-2 pb-0"
          />
        </Card>
      </div>
    </div>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skill-chip{
    display:flex;
    font-size:14px;
    flex-wrap:wrap;
    width: max-content;
    margin:5px;
}

.skill-icon{
    cursor:pointer;
    font-size:16px;
    width: 25px;
    height: 25px;
}

.skill-avatar{
    background-color:transparent;
    padding:5.2px;
}
.goal_table_main .p-datatable .p-datatable-tbody>tr>td{
    padding: 0.9rem 1rem !important;
}
.goal_table_main td.skill_value .skill-avatar{
    padding: 8.2px;
}
.goal_table_main td.skill_value .skill-icon{
    width: 32px;
    height: 32px;
}
.goal_table_main td.skill_value span.text-danger{
    position: absolute;
    background-color: #13BA32 !important;
    min-width: 20px;
    height: 20px;
    border-radius: 25px;
    color: #fff !important;
    font-size: 10px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
    padding: 3px;
    transform: translate(18px, -13px);
}`, "",{"version":3,"sources":["webpack://./src/css/components/goalTable.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,cAAc;IACd,cAAc;IACd,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,cAAc;IACd,cAAc;IACd,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,4BAA4B;IAC5B,aAAa;AACjB;AACA;IACI,+BAA+B;AACnC;AACA;IACI,cAAc;AAClB;AACA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,kBAAkB;IAClB,oCAAoC;IACpC,eAAe;IACf,YAAY;IACZ,mBAAmB;IACnB,sBAAsB;IACtB,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;IACZ,iCAAiC;AACrC","sourcesContent":[".skill-chip{\n    display:flex;\n    font-size:14px;\n    flex-wrap:wrap;\n    width: max-content;\n    margin:5px;\n}\n\n.skill-icon{\n    cursor:pointer;\n    font-size:16px;\n    width: 25px;\n    height: 25px;\n}\n\n.skill-avatar{\n    background-color:transparent;\n    padding:5.2px;\n}\n.goal_table_main .p-datatable .p-datatable-tbody>tr>td{\n    padding: 0.9rem 1rem !important;\n}\n.goal_table_main td.skill_value .skill-avatar{\n    padding: 8.2px;\n}\n.goal_table_main td.skill_value .skill-icon{\n    width: 32px;\n    height: 32px;\n}\n.goal_table_main td.skill_value span.text-danger{\n    position: absolute;\n    background-color: #13BA32 !important;\n    min-width: 20px;\n    height: 20px;\n    border-radius: 25px;\n    color: #fff !important;\n    font-size: 10px;\n    font-weight: 600;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    align-items: center;\n    padding: 3px;\n    transform: translate(18px, -13px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Themes } from "../../data/Themes";
import { ThemeState } from "../../dtos/common/ThemeState";
import { AppMode } from "../../data/AppMode";
import { AppModeState } from "../../dtos/common/AppModeState";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import "../../css/components/goals.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faClock, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { skillLogos } from "../../shared/constant/SkillImages";
import AddGoal from "./AddGoal";
import AssignGoals from "./AssignGoals";
import AssignToEmployees from "./AssignToEmployees";
import {
  getAllGoals,
  getGoalStepsCount,
} from "../../service/goals/goalsService";

import { GoalDto } from "../../dtos/goals/GoalsDto";
import { AssignGoalUserDto } from "../../dtos/userGoals/AssignGoalUserDto";

import UpdateGoals from "./UpdateGoal";
import { ProgressDto } from "../../dtos/goals/ProgressDto";
import { Paginator } from "primereact/paginator";
import { Toolbar } from "primereact/toolbar";

import { Tooltip } from "primereact/tooltip";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { GetPaginationRowsCount } from "../../shared/functions/GetPaginationRowsCount";
import { GetPaginationRowsInterface } from "../../dtos/common/GetPaginationRows";

const Goals: React.FC = () => {
  //#region all Variables
  const localization = useSelector(
    (state: any) => state.localization.localization
  );
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const [showAddNewGoal, setShowAddNewGoal] = useState(false);
  const [showAssignGoal, setShowAssignGoal] = useState(false);
  const [showUpdateGoal, setShowUpdateGoal] = useState(false);
  const [showAssignToEmployee, setShowAssignToEmployee] = useState(false);
  const dispatch = useDispatch();
  const [goals, setGoals] = useState<GoalDto[]>();

  const [selectedGoal, setSelectedGoal] = useState<GoalDto | null>(null);
  const [allAssignedGoalUser, setAllAssignedGoalUser] = useState<
    AssignGoalUserDto[] | []
  >([]);

  const [progress, setProgress] = useState<{
    steps: ProgressDto[];
    totalCount: number;
  }>({ steps: [], totalCount: 0 });
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);

  const [empid, setEmpId] = useState<null | number>(null);
  const [page, setPage] = useState(0);
  const [totalAssignedEmployee, setTotalAssignedEmployee] = useState<number>(0);
  const [selectedGoalId, setSelectedGoalId] = useState<number | null>(null);
  const [selectedGoalName, setSelectedGoalName] = useState<string | null>(null);
  const row: GetPaginationRowsInterface = GetPaginationRowsCount(true);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(row.rowCount); // Number of items to display per page
  const [totalUserGoalsData, setTotalUserGoalsData] = useState<number>(50);
  const [searchText, setSearchText] = useState<string>('');


  //#endregion

  //#region all Functions

  const getGoals = async () => {
    let input;
    if(searchText.length>0){
        input ={
          SearchText:searchText,
          Limit:itemsPerPage,
          page:currentPage
        }
    }else{
      input = {
        Limit:itemsPerPage,
        page:currentPage
      }
    }
  
    const response = await getAllGoals(dispatch,input);
    setGoals(response.data);
    setTotalUserGoalsData(response.totalRecords)
  };

  const handlePageChange = (event: any) => {
    setCurrentPage(event.page);
    setItemsPerPage(event.rows);
  };


  const template = {
    layout:
      "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
    RowsPerPageDropdown: (options: any) => {
      const dropdownOptions = row.rowList.map((item: number) => ({
        label: item,
        value: item,
      }));
      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Total Records {options.totalRecords}
            
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </React.Fragment>
      );
    },
  };




  const getProgress = async (id: number, goalName: string) => {
    setLoading(true);
    const input = { goalId: id, pageNo: page, rowCount: rows, goalName };
    const response = await getGoalStepsCount(dispatch, input);
    setProgress(response);
    setLoading(false);
  };

  const onPageChange = async (event: any) => {
    setLoading(true);
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page);
    const input = {
      goalId: selectedGoalId,
      pageNo: event.page,
      rowCount: event.rows,
      goalName: selectedGoalName,
    };
    const response = await getGoalStepsCount(dispatch, input);
    setProgress(response);
    setLoading(false);
  };

  const resetPaginator = () => {
    setFirst(0);
    setRows(5);
    setPage(0);
  };

  const handleAssignUserClick = async (e: any, goal: GoalDto) => {
    setTotalAssignedEmployee(
      goal?.AssignedEmployeeCount ? goal?.AssignedEmployeeCount : 0
    );
    setSelectedGoalId(goal.id);
    setSelectedGoalName(goal.Name);
    e.preventDefault();
    e.stopPropagation();
    setShowAssignToEmployee(true);
    await getProgress(goal.id, goal.Name);
    setEmpId(goal.id);
  };

  //#endregion

  //#region all Templates

  const addGoalFooterTemplate = () => {
    return (
      <div className="assign_popup assign-goal-footer-container mx-3">
        <Button
          className="modalsecondaryBtn"
          type="button"
          style={{
            // backgroundColor: mode?.backgroundSecondaryColor,
            // border: `1px solid ${modeName === "light" ? "black" : "white"}`,
            color: `${modeName === "light" ? "#0355a0" : "white"}`,
          }}
          onClick={() => {
            setShowAssignToEmployee(false);
            setSelectedGoalId(null);
            setSelectedGoalName(null);
            resetPaginator();
          }}
        >
          {localization?.Cancel || "Cancel"}
        </Button>
        {totalAssignedEmployee > 0 ? (
        <Paginator
          first={first}
          className="p-0"
          rows={rows}
          totalRecords={totalAssignedEmployee }
          onPageChange={onPageChange}
          template={{ layout: "PrevPageLink CurrentPageReport NextPageLink" }}
        /> ) : (
          <></>
        )}
      </div>
    );
  };
  const startToolbar = () => {
    return (
      <div>
        <h4>User Goals</h4>
      </div>
    )
  };
  const endToolbar = () => {
    return (
      <div className="d-flex flex-wrap flex-lg-nowrap gap-3">
        <Button
          label={localization?.AssignGoal || "Assign Goal"}
          style={{
            backgroundColor: mode?.backgroundSecondaryColor,
            color: ThemeColors?.primaryColor,
          }}
          onClick={() => setShowAssignGoal(true)}
        />
        <Button
          label={localization?.UpdateUserScore || "Update User Score"}
          onClick={() => setShowUpdateGoal(true)}
          style={{ width: "160px" }}
        />
         <div className="endToolbarPerformance">
            <div className="searchBxCont">
              <div className="searchBx border_primary">
                      <InputText className="w-100" type="search" placeholder={localization?.Search || 'Search'} onChange={(e) => {
                          setTimeout(() => {
                              setSearchText(e.target.value)
                          }, 500)
                      }} />
                <span className="searchIcon"><i className="pi pi-search"></i></span>      
              </div>
            </div>
          </div>
      </div>
    );
  };

  const goalNameTemplate = (rowData: any) => {

    const skillImg = Object.keys(skillLogos).find((skillName: string) => {
      const skillWords = rowData.Name.toLowerCase().split(/[\s\-\.]+/); // Split by spaces, dashes, or dots
      return skillWords.includes(skillName.toLowerCase());
    });
    return (
      <div className="d-flex align-items-center justify-content-between">
        <div className="fs-5 d-flex align-items-center gap-2" >

          <img
            src={skillImg && skillLogos[skillImg] || skillLogos.default}
            alt={`logo`}
            className="p-avatar-img rounded-4"
            style={{ width: '30px', height: '30px', borderRadius: '50%' }}
          />



          {rowData.Name.length > 30 ? (
            <>

              {rowData.Name.slice(0, 30)}

              <span
                className={`name-expanded-dots${rowData.id} expanded-dots`}
                data-pr-position="bottom"
              >
                ...
                <Tooltip
                  target={`.name-expanded-dots${rowData.id}`}
                  content={rowData.Name}
                  className="w-75 w-md-200px"
                />
              </span>

            </>
          ) : (
            rowData.Name
          )}
        </div>
        {rowData.deleted && <FontAwesomeIcon icon={faTrashCan} style={{ color: 'red', fontSize: '18px' }} />}
      </div>

    );
  };


  const descriptionTemplate = (rowData: any) => {
    return (
      <>
        {rowData.Description.length > 70 ? (
          <>
            {rowData.Description.slice(0, 45)}
            <span
              className={`description-expanded-dots${rowData.id} expanded-dots`}
              data-pr-position="bottom"
            >
              ...
              <Tooltip
                target={`.description-expanded-dots${rowData.id}`}
                content={rowData.Description}
                className="w-75 w-md-200px"
              />
            </span>
          </>
        ) : (
          rowData.Description
        )}
      </>
    );
  };

  //#endregion

  useEffect(() => {
    getGoals();
  }, [currentPage,itemsPerPage,searchText]);

  return (
    <div className="goalCont me-2">
      <Toolbar
        start={startToolbar}
        end={endToolbar}
        style={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        className="bg-transparent border-0 p-0 mb-3"
      />

      <div
        className="userGoalCont"
      >
        <div className="grid g-0">
          {goals?.map((goal) => {
            return (
              <div className="userGoalSingle col-12 col-sm-6 col-md-4 col-lg-3 shadow-none px-2 px-lg-2 py-0 mb-3">
                <Card
                  title={
                    <div
                      className="flex align-items-center justify-content-between w-100"
                      style={{ minHeight: "2rem" }}
                    >

                      <div style={{ flex: 1 }}>
                        {goalNameTemplate(goal)}
                      </div>

                    
                    </div>

                  }
                  className="card w-100 goal_card p-0 cs_card_shadow"
                  key={goal?.id}
                  style={{
                    backgroundColor: mode?.backgroundPrimaryColor,
                    color: mode?.color,
                  }}
                >
                  <p className="m-0 flex">
                    {!goal.deleted && goal.Description !== null && goal.Description.length > 0 && (
                      <span
                        className="light-gray goal-card-description"
                        style={{
                          color: mode?.color,
                        }}
                      >
                        {descriptionTemplate(goal)}
                      
                      </span>
                    )}
                  </p>
                  <div className="d-inline-flex duration-block rounded-2 py-1 px-2 my-2">
                    <FontAwesomeIcon
                      icon={faClock}
                      className="duration-icon"
                      style={{
                        color: mode?.color,
                      }}
                    />
                    <span
                      style={{
                        color: mode?.color,
                      }}
                    >
                      {localization?.Duration || "Duration"}{" "}
                      {goal?.Duration ? goal?.Duration : 0}{" "}
                      {localization?.Days || "days"}
                    </span>
                  </div>

                  <div
                    className="card-item-footer px-0 pt-0 pb-0"
                    onClick={(e: any) => handleAssignUserClick(e, goal)}
                  >
                    <span
                      style={{
                        color: ThemeColors?.secondaryColor,
                      }}
                    >
                      {localization?.AssignedToEmployees ||
                        "Assigned to employees"}{" "}
                      {goal?.AssignedEmployeeCount}
                    </span>
                    <FontAwesomeIcon
                      icon={faAngleRight}
                      style={{
                        color: ThemeColors?.secondaryColor,
                      }}
                    />
                  </div>
                </Card>
              </div>
            );
          })}
        </div>
      </div>
      <div style={{width:'100%'}}>
                    <Paginator
                            first={currentPage * itemsPerPage}
                            rows={itemsPerPage}
                            totalRecords={totalUserGoalsData}
                            onPageChange={handlePageChange}
                            template={template}
                            className="mt-2 "
                          />
                    </div>
      <AddGoal
        showAddNewGoal={showAddNewGoal}
        setShowAddNewGoal={setShowAddNewGoal}
        setSelectedGoal={setSelectedGoal}
        getGoals={getGoals}
      />

      <AssignGoals
        showAssignGoal={showAssignGoal}
        setShowAssignGoal={setShowAssignGoal}
        getGoals={getGoals}
      />

      <UpdateGoals
        showUpdateGoal={showUpdateGoal}
        setShowUpdateGoal={setShowUpdateGoal}
        getGoals={getGoals}
      />

      <AssignToEmployees
        allAssignedGoalUser={allAssignedGoalUser}
        showAssignToEmployee={showAssignToEmployee}
        setShowAssignToEmployee={setShowAssignToEmployee}
        progress={progress}
        addGoalFooterTemplate={addGoalFooterTemplate}
        setSelectedGoalId={setSelectedGoalId}
        setSelectedGoalName={setSelectedGoalName}
        totalAssignedEmployee={totalAssignedEmployee}
        resetPaginator={resetPaginator}
        selectedGoalName={selectedGoalName}
        loading={loading}
      />
    </div>
  );
};

export default Goals;

import { setError } from "../../store/slices/errorSlice";
import { hideLoader } from "../../store/slices/loadingSlice";
import instance from "../../utills/BaseService";

export const getProfilePicFromTracker = async (dispatch: any, input: any) => {
    let image = ''
    const headers = {
        access_token: "Bearer " + localStorage.getItem("token"),
    };

    try {
        const response = await instance.get(`/profilePicture/getUserProfilePicture?EmployeeID=${input.EmployeeID}&EmployeeCode=${input.EmployeeCode}`, {
            headers,
            responseType: 'blob'
        });

        image = URL.createObjectURL(response.data);
        return image;

    } catch (error: any) {
        dispatch(setError({
            isError: true,
            errorMessage: error.response.statusText
        }))
        dispatch(hideLoader())
    }

}
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getUserRole } from '../service/user/userService';
import { useDispatch } from 'react-redux';

const ProtectAdminRoutes = (props: any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isAdmin, setIsAdmin] = useState(false);

    const chekUserRole = (role:string) => {
        if (role?.toUpperCase() === 'ADMIN') {
            setIsAdmin(true);
        }
        else {
            setIsAdmin(false);
            navigate('/dashboard');
        }
    }
    const fetchRole = async () => {
        const roleData = await getUserRole(dispatch);
        chekUserRole(roleData)
    }

    // useEffect(() => {
    //     chekUserRole();
    // }, [role])
    useEffect(() => {
        fetchRole();
    }, [])

    return (
        <React.Fragment>
            {isAdmin ? props.children : null}
        </React.Fragment>
    )
}

export default ProtectAdminRoutes;
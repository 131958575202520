
import { CreateSkillDetailsDto } from "../../dtos/skills/CreateSkillDetailsDto";
import { SkillInfoDto } from "../../dtos/skills/SkillInfoDto";
import instance, { del, get, patch, post } from "../../utills/BaseService";

export async function getPaginatedSkills(dispatch: any, input: any): Promise<any> {
   return await post(dispatch, '/skills//getPaginatedSkills', input);
};

export async function createSkill(dispatch: any, details: CreateSkillDetailsDto): Promise<CreateSkillDetailsDto[]|string> {
   return await post(dispatch, '/skills', details);
};

export async function updateSkill(dispatch: any, param: number, details: CreateSkillDetailsDto): Promise<CreateSkillDetailsDto[]|string> {
   return await patch(dispatch, '/skills', param, details);
};

export async function delSkill(dispatch: any, param: number): Promise<CreateSkillDetailsDto[]> {
   return await del(dispatch, '/skills', param);
};

export async function getSkillById(dispatch: any, input: string): Promise<SkillInfoDto> {
   return await get(dispatch, '/skills/getSkillById', null, input);
};

export async function getAllSkills(dispatch:any):Promise<SkillInfoDto[]>{
   return await get(dispatch, '/skills/getAllSkills', null);
}

export async function getNotAssignedSkillsByEmp(dispatch: any,input:any): Promise<SkillInfoDto[]> {
   return await get(dispatch, '/skills/getNotAssignedSkillByEmp', input);
};



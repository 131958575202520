import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import "../../css/components/assignGoal.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Themes } from "../../data/Themes";
import { useDispatch, useSelector } from "react-redux";
import { ThemeState } from "../../dtos/common/ThemeState";
import { AppModeState } from "../../dtos/common/AppModeState";
import { AppMode } from "../../data/AppMode";
import {
  getSubOrdinatesByStatus,
  getAssignedGoalsByEmp,
  getAllGoalsAssignedToEmpByEmpCode,
} from "../../service/goals/goalsService";
import { SubOrdinateDto } from "../../dtos/goals/SubOrdinatesDto";
import { active } from "../../shared/constant/Common";
import { createUserGoals } from "../../service/userGoals/userGoalService";
import { Toast } from "primereact/toast";
import UpdateGoalSteps from "./UpdateGoalSteps";
import { UserGoalsDto } from "../../dtos/goals/UserGoalsDto";
import { GoalStepsDto } from "../../dtos/goals/GoalsStepsDto";

interface AssignGoalsProps {
  showUpdateGoal: boolean;
  setShowUpdateGoal: React.Dispatch<React.SetStateAction<boolean>>;
  getGoals: () => Promise<void>;
}

const UpdateGoals: React.FC<AssignGoalsProps> = ({
  showUpdateGoal,
  setShowUpdateGoal,
  getGoals,
}) => {
  //#region all Variables
  const localization = useSelector(
    (state: any) => state.localization.localization
  );
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const dispatch = useDispatch();
  const [subOrdinates, setSubOrdinates] = useState<any>();
  const [selectedSubordinate, setSelectedSubordinate] =
    useState<SubOrdinateDto | null>(null);
  const [selectedGoal, setSelectedGoal] = useState<{
    GoalName: string;
    EmployeeCode: string;
  } | null>(null);
  const [scorePercentage, setScorePercentage] = useState<number>(0);

  const [notAssignedGoals, setNotAssignedGoals] = useState<
    UserGoalsDto[] | []
  >();
  const [showUpdateGoalSteps, setShowUpdateGoalSteps] =
    useState<boolean>(false);
  const toast = useRef<Toast>(null);
  const [errors, setErrors] = useState<{
    employeeName: boolean;
    goal: boolean;
  }>({ employeeName: false, goal: false });
  const [steps, setSteps] = useState<
    | GoalStepsDto[]
    | []
  >([]);
  const [goalDescription, setGoalDescription] = useState<string | null>(null);
  const [empProgress, setEmpProgress] = useState<{
    totalValue: number;
    totalScore: number;
  }>({ totalValue: 0, totalScore: 0 });

  //#endregion

  //#region all Functions

  const getAllEmployees = async () => {
    const response = await getSubOrdinatesByStatus(dispatch, {
      Status: active,
    });
    setSubOrdinates(response);
  };

  const showToast = (severity: any, detail: string, summary: string) => {
    toast.current?.show({ severity, summary, detail, life: 3000 });
  };

  const getAllGoalsAssigned = async () => {
    if (selectedSubordinate) {
      const response = await getAllGoalsAssignedToEmpByEmpCode(dispatch, {
        empCode: selectedSubordinate.employeeCode,
      });

      setNotAssignedGoals(response);
    }
  };

  const submitUserGoal = async () => {
    if (!selectedSubordinate && !selectedGoal) {
      setErrors({ employeeName: true, goal: true });
      showToast("error", "Please fill required fields to update goal", "Error");
    } else if (!selectedSubordinate) {
      setErrors({ employeeName: true, goal: false });
      showToast("error", "Please fill required fields to update goal", "Error");
    } else if (!selectedGoal) {
      setErrors({ employeeName: false, goal: true });
      showToast("error", "Please fill required fields to update goal", "Error");
    } else {
      await createUserGoals(dispatch, {
        EmployeeCode: selectedSubordinate?.employeeCode,
        GoalName: selectedGoal?.GoalName,
        IsGoalCompleted: false,
      });
      showToast("success", "Assigned Goal Successfully", "Success");
      getGoals();
    }
  };

  const handleChange:any = (e: any) => {
    setSelectedGoal(e.value);
    setShowUpdateGoal(false);
    setShowUpdateGoalSteps(true);
    setErrors((prev) => {
      return { ...prev, goal: false };
    });
  };

  const resetUpdateGoalDialog = () => {
    setSelectedGoal(null);
    setSelectedSubordinate(null);
    setErrors({ employeeName: false, goal: false });
  };

  const getStepsByGoalNameAndEmpCode = async () => {
    if (selectedGoal) {
      const allGoals = await getAssignedGoalsByEmp(dispatch, {
        goalName: selectedGoal?.GoalName,
        empCode: selectedGoal?.EmployeeCode,
      });
      setScorePercentage(allGoals.UserGoalStepScorePercentage);
      setSteps(allGoals.GoalSteps);
      setEmpProgress({
        totalValue: allGoals.totalValue,
        totalScore: allGoals.totalScore,
      });
      setGoalDescription(allGoals.GoalDescription);
    }
  };

  //#endregion

  //#region all Templates

  const assignGoalsHeaderTemplate = () => {
    return <h1 className="m-0 p-dialog-title">{localization?.UpdateGoal || "Update Score"}</h1>;
  };

  const addGoalFooterTemplate = () => {
    return (
      <div className="form-actions">
        <Button
          type="button"
          style={{
            backgroundColor: mode?.backgroundSecondaryColor,
            border: `1px solid ${modeName === "light" ? "#970FFF" : "white"}`,
            color: `${modeName === "light" ? "#970FFF" : "white"}`,
          }}
          onClick={() => {
            setShowUpdateGoal(false);
            resetUpdateGoalDialog();
          }}
        >{localization?.Cancel || "Cancel"}
        </Button>
        <Button type="submit" onClick={submitUserGoal}>
        {localization?.Submit || "Submit"}
        </Button>
      </div>
    );
  };

  const selectedEmployeeTemplate = (option: SubOrdinateDto, props: any) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.fullName}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const selectedGoalTemplate = (option: UserGoalsDto, props: any) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.GoalName}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const goalOptionTemplate = (option: UserGoalsDto) => {
    return (
      <div className="flex align-items-center">
        <div>{option.GoalName}</div>
      </div>
    );
  };

  const employeeOptionTemplate = (option: SubOrdinateDto) => {
    return (
      <div className="flex align-items-center">
        <div>{option.fullName}</div>
      </div>
    );
  };

  //#endregion

  useEffect(() => {
    getAllGoalsAssigned();
  }, [selectedSubordinate]);

  useEffect(() => {
    getAllEmployees();
  }, []);

  useEffect(() => {
    getStepsByGoalNameAndEmpCode();
  }, [selectedGoal]);

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header={assignGoalsHeaderTemplate}
        footer={addGoalFooterTemplate}
        visible={showUpdateGoal}
        className="update_Goal"
        style={{
          width: "50vw",
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        contentStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        headerStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        onHide={() => {
          if (!showUpdateGoal) return;
          setShowUpdateGoal(false);
          resetUpdateGoalDialog();
        }}
        draggable={false}
      >
        <div className="assign-goal-form">
          <div className="form-group">
            <label htmlFor="goal-name">{localization?.EmployeeName || "Employee Name"}</label>
            <div
              className="dropdown-styles"
            >
              <Dropdown
                value={selectedSubordinate}
                onChange={(e) => {
                  setSelectedSubordinate(e.value);
                  setErrors((prev) => {
                    return { ...prev, employeeName: false };
                  });
                }}
                options={subOrdinates}
                optionLabel="fullName"
                placeholder={localization?.SelectAnEmployee || "Select an Employee"}
                valueTemplate={selectedEmployeeTemplate}
                itemTemplate={employeeOptionTemplate}
                filter
                panelStyle={{
                  backgroundColor: mode?.backgroundSecondaryColor,
                  color: mode?.color,
                }}
              />
              {errors.employeeName && (
                <span
                 className="err-msg"
                >
                  *Please enter employee name
                </span>
              )}
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="duration">{localization?.ChooseGoal || "Choose Goal"}</label>
            <div
             className="dropdown-styles"
            >
              <Dropdown
                value={selectedGoal}
                onChange={handleChange}
                options={notAssignedGoals}
                optionLabel="GoalName"
                placeholder={localization?.SelectAGoal || "Select a Goal"}
                valueTemplate={selectedGoalTemplate}
                itemTemplate={goalOptionTemplate}
                filter
                disabled={!selectedSubordinate}
                panelClassName="custom-dropdown"
                panelStyle={{
                  backgroundColor: mode?.backgroundSecondaryColor,
                  color: mode?.color,
                }}
              />
              {errors.goal && (
                <span
                  className="err-msg"
                >
                  *Please enter goal
                </span>
              )}
            </div>
          </div>
        </div>
      </Dialog>
      <UpdateGoalSteps
        showUpdateGoalSteps={showUpdateGoalSteps}
        setShowUpdateGoalSteps={setShowUpdateGoalSteps}
        selectedGoal={selectedGoal}
        selectedSubordinate={selectedSubordinate}
        setSelectedGoal={setSelectedGoal}
        setSelectedSubordinate={setSelectedSubordinate}
        steps={steps}
        goalDescription={goalDescription}
        setSteps={setSteps}
        scorePercentage={scorePercentage}
        setScorePercentage={setScorePercentage}
        empProgress={empProgress}
        setEmpProgress={setEmpProgress}
      />
    </>
  );
};

export default UpdateGoals;

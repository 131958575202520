import { CreateGoalsWithStepsDto } from "../../dtos/goals/CreateGoalsWithStepsDto";
import { UpdateGoalDto } from "../../dtos/goals/UpdateGoalDto";
import { UpdateGoalStepDto } from "../../dtos/goals/UpdateGoalStepDto";
import { UpdateGoalStepValueDto } from "../../dtos/goals/UpdateGoalStepValueDto";
import { UpdateGoalWithPrevValueDto } from "../../dtos/goals/UpdateGoalWithPrevValueDto";
import instance, { del, get, patch, post } from "../../utills/BaseService";




export async function createGoalWithSteps(dispatch: any, details: CreateGoalsWithStepsDto): Promise<any> {
    return await post(dispatch, '/goal/createGoalWithSteps', details);
 };
export async function getGoalByGoalName(dispatch: any, details:{GoalName:string}): Promise<any> {
    return await post(dispatch, '/goal/getGoalByGoalName', details);
 };

 export async function deleteGoalStep(dispatch: any, param: number): Promise<string> {
   return await del(dispatch, '/goalStep/deleteGoalStep', param);
};
 export async function deleteGoal(dispatch: any, param: number): Promise<string> {
   return await del(dispatch, '/goal/deleteGoal', param);
};

 export async function getAllGoals(dispatch: any): Promise<any> {
    return await get(dispatch, '/goal/getAllGoalsWithEmployeeCount', null);
 };



 export async function getAllAssignGoalUser(dispatch: any,input:any): Promise<any> {
    return await post(dispatch, '/userGoal/getAllAssignedGoalUsers', input);
 };
 export async function getAllPaginatedGoalSteps(dispatch: any,input:any): Promise<any> {
    return await post(dispatch, '/goalStep/getPaginatedGoalSteps', input);
 };
 export async function getSubOrdinatesByStatus(dispatch: any,input:any): Promise<any> {
    return await get(dispatch, '/employee/getSubordinatesByStatus', input);
 };
 export async function getNotAssignedGoalsByEmp(dispatch: any,input:any): Promise<any> {
    return await get(dispatch, '/goal/getNotAssignedGoalByEmp', input);
 };


 //get paginated goals
 export async function getPaginatedGoals(dispatch: any,input:any): Promise<any> {
   return await post(dispatch, '/goal/getPaginatedGoals', input);
};
 export async function getGoalStepByStepNameAndGoalName(dispatch: any,input:any): Promise<any> {
   return await post(dispatch, '/goalStep/getGoalStepByStepNameAndGoalName', input);
};
 
 export async function getAllGoalsAssignedToEmpByEmpCode(dispatch: any,input:any): Promise<any> {
    return await get(dispatch, '/userGoal/getAllAssignedGoalByEmp', input);
 };
 export async function getAssignedGoalsByEmp(dispatch: any,input:any): Promise<any> {
    return await post(dispatch, '/goal/getAssignedGoalByEmp', input);
 };
 export async function getGoalWithSteps(dispatch: any,input:any): Promise<any> {
    return await get(dispatch, '/goal/getGoalWithSteps', input);
 };
 export async function getGoalStepsCount(dispatch: any,input:any): Promise<any> {
    return await get(dispatch, '/goal/getGoalStepsCount', input);
 };

 export async function updateGoal(dispatch: any, param: number, details: CreateGoalsWithStepsDto): Promise<any> {
    return await patch(dispatch, '/goal/updateGoalWithSteps', param, details);
 };


 export async function updateGoalHeader(dispatch: any, param: number, details: UpdateGoalWithPrevValueDto): Promise<any> {
    return await patch(dispatch, '/goal/updateGoal', param, details);
 };

 export async function updateGoalStep(dispatch: any, param: number, details: UpdateGoalDto): Promise<any> {
   return await patch(dispatch, '/goalStep/updateGoalStep', param, details);
};


 export async function updateGoalStepWithScore( details: UpdateGoalStepValueDto): Promise<any> {
   const headerData = {
      access_token: "Bearer " + localStorage.getItem("token")
  }
    return await instance.patch('/goalStep/updateGoalStepScore',details,{
      headers: headerData
  });
 };

 export async function createGoalSteps(dispatch: any, details: UpdateGoalDto[],goalId:number): Promise<any> {
   const headerData = {
      access_token: "Bearer " + localStorage.getItem("token")
  }
   return await instance.post( `/goalStep/createGoalSteps/${goalId}`, details,{
      headers: headerData
  });
};
 export async function getGoalById(goalId:string): Promise<any> {
   const headerData = {
      access_token: "Bearer " + localStorage.getItem("token")
  }
   return await instance.get( `goal/getGoalById/${goalId}`,{
      headers: headerData
  });
};

export async function checkIfGoalExist(id:number): Promise<any> {
   const headerData = {
      access_token: "Bearer " + localStorage.getItem("token")
  }
   return await instance.get(`/goal/checkIfIdExist/${id}`,{
      headers: headerData
   });
};
 export async function deleteNullValuedSteps(dispatch:any,input:any): Promise<any> {
   return await post(dispatch, '/userGoal/deleteSingleStepForUser', input);
};



// Get All steps from completed user goal step table

export async function getAllStepsByUser(dispatch: any,input:{GoalName:string,EmployeeCode:string}): Promise<any> {
   return await post(dispatch, '/goalStep/getAllStepsByEmpCodeAndGoalName', input);
};


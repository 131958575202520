export const pages = "Pages";
export const administration = "Pages.Administration";
export const indivisualRole = "Pages.Administration.Roles";
export const editRoleInfo = "Pages.Administration.Roles.EditRole";
export const editRolePermission = "Pages.Administration.Roles.EditPermission";
export const userPermission = "Pages.Administration.Users";
export const editUserPermission = "Pages.Administration.Users.EditPermission";
export const kpi = "Pages.Administration.Kpi";
export const createKPI = "Pages.Administration.Kpi.CreateKpi";
export const editKPI = "Pages.Administration.Kpi.EditKpi";
export const deleteKPI="Pages.Administration.Kpi.DeleteKpi";
export const subKPI = "Pages.Administration.Kpi.SubKpi";
export const createSubKpi = "Pages.Administration.Kpi.SubKpi.CreateSubKpi";
export const deleteSubKpi = "Pages.Administration.Kpi.SubKpi.DeleteSubKpi";
export const kpiMappings = "Pages.Administration.KpiMappings";
export const roleMappings = "Pages.Administration.KpiMappings.RoleMappings";
export const createRoleMapping = "Pages.Administration.KpiMappings.RoleMappings.CreateRoleMapping";
export const editRoleMapping = "Pages.Administration.KpiMappings.RoleMappings.EditRoleMapping";
export const userMapping = "Pages.Administration.KpiMappings.UserMappings";
export const createUserMapping = "Pages.Administration.KpiMappings.UserMappings.CreateUserMapping";
export const editUserMapping = "Pages.Administration.KpiMappings.UserMappings.EditUserMapping";
export const settings = "Pages.Administration.Settings";
export const mailSettings = "Pages.Administration.Settings.MailSettings";
export const mailTemplate = "Pages.Administration.Settings.MailTemplate";
export const appSettings = "Pages.Administration.Settings.AppSettings";
export const systemAdmin = "Pages.Administration.Settings.AppSettings.SystemAdmin";
export const cronSettings = "Pages.Administration.Settings.AppSettings.CronSettings";
export const subordinateLevel = "Pages.Administration.Settings.AppSettings.SubordinateLevel";
export const systemTimeZone = "Pages.Administration.Settings.AppSettings.SystemTimeZone";
export const auditLog = "Pages.Administration.Settings.AuditLog";
export const cronJobData = "Pages.Administration.Settings.CronJobData";
export const cronJobStatus = "Pages.Administration.Settings.CronJobStatus";
export const dashboard = "Pages.Dashboard";
export const topBillingEmployee = "Pages.Dashboard.TopBillingEmployee";
export const employeePerformance = "Pages.Dashboard.EmployeePerformance";
export const employeeSkills = "Pages.Dashboard.EmployeeSkills";
export const topBillingProjects = "Pages.Dashboard.TopBillingProjects";
export const topTrackingProjects = "Pages.Dashboard.TopTrackingProjects";
export const yourPerformanceChart = "Pages.Dashboard.YourPerformanceChart";
export const project = "Pages.Project";
export const myTeam = "Pages.MyTeam";
export const myTeamSkills = "Pages.MyTeam.Skills";
export const assignSkills = "Pages.MyTeam.Skills.AssignSkills";
export const myPerformance = "Pages.MyPerformance";
export const employeeBilling = "Pages.EmployeeBilling";
export const updateEmployeeBillingTime = "Pages.EmployeeBilling.UpdateEmployeeBillingTime";
export const projectBilling = "Pages.ProjectBilling";
export const addProjectBillingTime = "Pages.ProjectBilling.AddProjectBillingTime";
export const updateProjectBillingTime = "Pages.ProjectBilling.UpdateProjectBillingTime";
export const options = "Pages.Options";
export const createOptionInfo = "Pages.Options.CreateOption";
export const editOption = "Pages.Options.EditOption";
export const deleteOptionInfo = "Pages.Options.DeleteOption";
export const ratingsAndReviews = "Pages.RatingsAndReviews";
export const scoreInfo = "Pages.RatingsAndReviews.Score";
export const reviewedBy = "Pages.RatingsAndReviews.ReviewedBy";
export const weightage = "Pages.RatingsAndReviews.Weightage";
export const target = "Pages.RatingsAndReviews.Target";
export const performance = "Pages.RatingsAndReviews.Performance";
export const roleInRatings ="Pages.RatingsAndReviews.Role";
export const view = "Pages.RatingsAndReviews.View";
export const review = "Pages.RatingsAndReviews.Review";
export const performanceReport = "Pages.PerformanceReport";
export const report = "Pages.Report";
export const projectBillableTime = "Pages.Report.ProjectBillableTime";
export const projectTrackableTime = "Pages.Report.ProjectTrackableTime";
export const totalTrackAndBillTimeOfProjects = "Pages.Report.TotalTrackAndBillTimeOfProjects";
export const employeeBillableTime = "Pages.Report.EmployeeBillableTime";
export const employeeTrackableTime = "Pages.Report.EmployeeTrackableTime";
export const goals = "Pages.Goals";
export const createGoalInfo = "Pages.Goals.CreateGoal";
export const editGoalInfo = "Pages.Goals.EditGoal";
export const deleteGoalInfo = "Pages.Goals.DeleteGoal";
export const goalStep = "Pages.Goals.GoalStep";
export const createGoalStep = "Pages.Goals.GoalStep.CreateGoalStep";
export const editGoalStepInfo = "Pages.Goals.GoalStep.EditGoalStep";
export const deleteGoalStepInfo = "Pages.Goals.GoalStep.DeleteGoalStep";
export const userGoals = "Pages.UserGoals";
export const myGoals = "Pages.MyGoals";
export const skills = "Pages.Skills";
export const createSkillInfo = "Pages.Skills.CreateSkill";
export const editSkill = "Pages.Skills.EditSkill";
export const deleteSkill = "Pages.Skills.DeleteSkill";




import ReactApexChart from "react-apexcharts";
import color from '../../shared/json/Colors.json';
import { PerformanceYearlyReportDto } from "../../dtos/performance/PerformanceYearlyReportDto";
import { useSelector } from "react-redux";
import { AppMode } from "../../data/AppMode";
import { AppModeState } from "../../dtos/common/AppModeState";
import { PerformanceHalfYearlyReportDto } from "../../dtos/performance/PerformanceHalfYearlyReportDto";
import { PerformanceQuarterlyReportDto } from "../../dtos/performance/PerformanceQuarterlyReportDto";

interface Props {
    data: PerformanceYearlyReportDto[] | PerformanceHalfYearlyReportDto[] |PerformanceQuarterlyReportDto[];
    date: Date;
    dateType: string;
}

interface ISeries {
    name: string;
    data: number[];
}

export const EmpPerformanceReportYearlyChart = (props: Props) => {
    const date = new Date(props.date)
    
    function getLastTwoDigitsUsingSplit(year:string) {
        const yearString = year.toString(); // Convert the year to a string
        return yearString.split('').slice(-2).join(''); // Take the last two characters
      }
      const initialsOfYear = getLastTwoDigitsUsingSplit(date.getFullYear().toString())
    const modeName = useSelector((state: AppModeState) => state.theme.appMode);
    const mode = AppMode.find((md) => md.name === modeName);
    let categories:string[]=[]
    if(props.dateType==='halfYearly'){
        categories.push(`Jan to Jun${initialsOfYear}`,`Jul to Dec${initialsOfYear}`)
    }else if(props.dateType==='quarterly'){
        categories.push(`Jan to Mar${initialsOfYear}`,`Apr to Jun${initialsOfYear}`,`Jul to Sep${initialsOfYear}`,`Oct to Dec${initialsOfYear}`)
    }else if (props.dateType === 'financialYear') {
        const nextYear = parseInt(initialsOfYear) + 1;
        categories = [
            `Apr${initialsOfYear}`,
            `May${initialsOfYear}`,
            `Jun${initialsOfYear}`,
            `Jul${initialsOfYear}`,
            `Aug${initialsOfYear}`,
            `Sep${initialsOfYear}`,
            `Oct${initialsOfYear}`,
            `Nov${initialsOfYear}`,
            `Dec${initialsOfYear}`,
            `Jan${nextYear}`,
            `Feb${nextYear}`,
            `Mar${nextYear}`,
        ];
    } 
    else{
        categories=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    }


    let ser: ISeries[] = [];

    
   

    if (props.dateType === "halfYearly") {
        // Type guard to ensure we're working with PerformanceHalfYearlyReportDto
        (props.data as PerformanceHalfYearlyReportDto[]).forEach((val) => {
            ser.push({
                name: val.Name,
                data: [val.FirstHalf, val.SecondHalf],
            });
        });
    }else if (props.dateType === "quarterly"){
        (props.data as PerformanceQuarterlyReportDto[]).forEach((val) => {
            ser.push({
                name: val.Name,
                data: [val.Q1, val.Q2,val.Q3,val.Q4],
            });
        });
    }
    else if (props.dateType === "financialYear") {
        // Type guard for PerformanceYearlyReportDto - Financial Year (April to March)
        (props.data as PerformanceYearlyReportDto[]).forEach((val) => {
            if (!val.isKPIPresent) {
                const data: number[] = [];
                data.push(
                    val.April === null ? 0 : val.April,
                    val.May === null ? 0 : val.May,
                    val.June === null ? 0 : val.June,
                    val.July === null ? 0 : val.July,
                    val.August === null ? 0 : val.August,
                    val.September === null ? 0 : val.September,
                    val.October === null ? 0 : val.October,
                    val.November === null ? 0 : val.November,
                    val.December === null ? 0 : val.December,
                    val.January === null ? 0 : val.January,
                    val.February === null ? 0 : val.February,
                    val.March === null ? 0 : val.March
                );
    
                ser.push({
                    name: val.Name,
                    data: data,
                });
            }
        });
    } else {
        // Type guard for PerformanceYearlyReportDto
        (props.data as PerformanceYearlyReportDto[]).forEach(val => {
            if (!val.isKPIPresent) {
                const data: number[] = [];
                data.push(
                    val.January === null ? 0 : val.January,
                    val.February === null ? 0 : val.February,
                    val.March === null ? 0 : val.March,
                    val.April === null ? 0 : val.April,
                    val.May === null ? 0 : val.May,
                    val.June === null ? 0 : val.June,
                    val.July === null ? 0 : val.July,
                    val.August === null ? 0 : val.August,
                    val.September === null ? 0 : val.September,
                    val.October === null ? 0 : val.October,
                    val.November === null ? 0 : val.November,
                    val.December === null ? 0 : val.December
                );

                ser.push({
                    name: val.Name,
                    data: data
                });
            }
        });
    }

    const legendColor:string[] = [];
    ser.forEach(s=>legendColor.push(mode?.color as string))
    
    const legendClrForX:string[] = []
    categories.forEach(cat=>legendClrForX.push(mode?.color as string))

    const chart: any = {

        series: ser,
        options: {
            chart: {
                height: 350,
                type: 'line',
                dropShadow: {
                    enabled: true,
                    color: '#000',
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2
                },
                toolbar: {
                    show: false
                }
            },
            colors: color?.colors,
            dataLabels: {
                enabled: true,
            },
            stroke: {
                curve: 'smooth'
            },
            grid: {
                borderColor: '#e7e7e7',
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            markers: {
                size: 1
            },
            xaxis: {
                categories: categories,
                title: {
                    text: 'Month',
                    style: {
                        color: mode?.color
                    }
                },
                labels:{
                    style:{
                        colors:legendClrForX
                    }
                }
            },
            yaxis: {
                min: 0, // Ensures Y-axis starts from 0
                max:100,
                labels:{
                    style:{
                        colors:legendColor
                    },
                    formatter: (val: number) => val.toFixed(2) // Limit to 2 decimal places
                },
                title: {
                    text: 'Ratings',
                    style: {
                        color: mode?.color
                    }
                },
            },
            tooltip: {
                shared: true,
                theme: 'dark',
            },
            legend: {
                position: 'top',
                horizontalAlign: 'right',
                floating: false,
                offsetY: 0,
                offsetX: 0,
                labels: {
                    colors: legendColor
                },
                markers: {
                    width: 12,
                    height: 12,
                },
                itemMargin: {
                    horizontal: 10,
                    vertical: 5
                }
            }
        },


    };

    return (
        <div>
            <div className="p-card-title"><h5 className="m-0">Performance Report of {props.date.getFullYear()}</h5></div>
            <div className="dashbord-card-cont"><ReactApexChart options={chart.options} series={chart.series} type="line" height={350} /></div>
        </div>

    )
}
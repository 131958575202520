import { Toolbar } from "primereact/toolbar"
import { useEffect, useState } from "react"
import { CronJobDataDto } from "../../dtos/cronJob/CronJobDataDto"
import { getAllCronJobData, getAllCronJobStatus } from "../../service/cronJob/CronJobServices";
import { useDispatch, useSelector } from "react-redux";
import { CronJobDataInputDto } from "../../dtos/cronJob/CronJobDataInputDto";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import '../../css/components/cronjob.css';
import { AppMode } from "../../data/AppMode";
import { Themes } from "../../data/Themes";
import { AppModeState } from "../../dtos/common/AppModeState";
import { ThemeState } from "../../dtos/common/ThemeState";
import moment from "moment";

export const CronJobData = () => {
    const [cronJobData, setCronJobData] = useState<CronJobDataDto[]>([]);
    const dispatch = useDispatch();
    const [totalCount, setTotalCount] = useState<number>(0);
    const themeName = useSelector((state: ThemeState) => state.theme.themeName);
    const ThemeColors = Themes.find(th => th.name === themeName);
    const modeName = useSelector((state: AppModeState) => state.theme.appMode);
    const mode = AppMode.find(md => md.name === modeName);
    const [lazyState, setlazyState] = useState<any>({
        page: 0,
        first: 0,
        rows: 5
    });


    const getAllCronJob = async () => {
        const input: CronJobDataInputDto = {
            limit: lazyState.rows,
            page: lazyState.page
        }

        const allCronJob = await getAllCronJobData(dispatch, input);
        setCronJobData(allCronJob.data);
        setTotalCount(allCronJob.totalLength)
    }


    const onPageChange = (event: any) => {
        setlazyState(event)
    };

    const startToolBar = () => {
        return <div>
            <h4>CronJob Data</h4>
        </div>
    }

    const createdAtBodyTemp = (rowData: any) => {
        return `${moment(rowData.createdAt).format('lll')}`
    }


    useEffect(() => {
        getAllCronJob()
    }, [lazyState])

    return (
        <div className="cronjob_container">
            <Toolbar start={startToolBar} />

            {/* cron job data */}
            <DataTable
                className="performance-table audit-log-table"
                value={cronJobData}
                dataKey="id"
                lazy
                paginator
                totalRecords={totalCount}
                first={lazyState.first}
                rows={lazyState.rows}
                onPage={onPageChange}
                showGridlines
                rowsPerPageOptions={[5, 8, 10, 20]}
                currentPageReportTemplate="Total Records {totalRecords} "
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown">

                <Column style={{ minWidth: '12rem' }} bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="EmailId" header="EmailId" ></Column>
                <Column style={{ minWidth: '12rem' }} bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="MailContent" header="Mail Content" ></Column>
                <Column style={{ minWidth: '12rem' }} bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} body={createdAtBodyTemp} field="createdAt" header="Created Date" ></Column>

            </DataTable>


        </div>
    )
}
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppModeState } from "../../dtos/common/AppModeState";
import { AppMode } from "../../data/AppMode";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { ThemeState } from "../../dtos/common/ThemeState";
import { Themes } from "../../data/Themes";
import { DataTable } from "primereact/datatable";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";

import { Tooltip } from "primereact/tooltip";
import {
  createSkill,
  delSkill,
  getPaginatedSkills,
  updateSkill,
} from "../../service/skill/skillServices";
import { SkillDto } from "../../dtos/skill/SkillsDto";

export interface LazyState {
  page: number;
  first: number;
  rows: number;
  sortField: string;
  sortOrder: number;
  filters?: string;
}

interface GoalErrors {
  Name: boolean;
  Duration: boolean;
}

interface SkillInfo {
  Name: string;
  Details: string;
}

const SkillTable = () => {
  //#region all Variables
  const localization = useSelector(
    (state: any) => state.localization.localization
  );

  const dt = React.useRef<any>(null);
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  const dispatch = useDispatch();
  const [lazyState, setlazyState] = React.useState({
    page: 0,
    first: 0,
    rows: 5,
    sortField: "createdAt",
    sortOrder: 0 as any,
  });
  const [totalSkills, setTotalSkills] = useState(0);
  const [selectedSkill, setSelectedSkill] = useState<any>();
  const [skills, setSkills] = useState<SkillDto[] | []>([]);
  const toast = useRef<Toast>(null);
  const [isEditClicked, setIsEditClicked] = useState<boolean>(false);
  const [showSkillDialog, setShowSkillDialog] = React.useState(false);
  const [skillId, setSkillId] = useState<number | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const emptyGoal = {
    Name: "",
    Details: "",
  };

  const emptySkillErrors = {
    Name: false,
    Duration: false,
  };
  const [skillErrors, setSkillErrors] = useState<GoalErrors>(emptySkillErrors);
  const [skillInfo, setSkillInfo] = useState<SkillInfo>(emptyGoal);
  const [prevSkillName, setPrevSkillName] = useState<string | null>(null);
  const [expandDescription, setExpandDescription] = useState<{
    [key: number]: boolean;
  }>({});

  //#endregion
  //#region all functions

  const onSort = (event: any) => {
    setlazyState((prev: LazyState) => {
      const newState: LazyState = {
        ...prev, // Copy all properties from the previous state
        sortOrder: event.sortOrder, // Update sortOrder
        sortField: event.sortField, // Update sortField
      };
      return newState;
    });
  };

  const onPageChange = (event: any) => {
    setlazyState(event);
  };

  const showToast = (severity: any, detail: string, summary: string) => {
    toast.current?.show({ severity, summary, detail, life: 3000 });
  };

  const accept = async (id: number) => {
    await delSkill(dispatch, id);

    await allDataFunc();

    showToast("success", "Skill deleted successfully", "Success");
  };

  const reject = () => {
    showToast("warn", "You have rejected the delete operation", "Rejected");
  };

  const resetDialog = () => {
    setShowSkillDialog(false);
    setSkillInfo(emptyGoal);
    setSkillErrors(emptySkillErrors);
    setSkillId(null);
    setIsEditClicked(false);
  };

  const allDataFunc = async () => {
    let param;

    if (searchText.length > 0) {
      param = {
        Limit: lazyState.rows,
        Page: lazyState.page,
        SortBy: lazyState.sortOrder === 1 ? "ASC" : "DESC",
        SortProperty: lazyState.sortField,
        Filter: searchText,
        PropertyNames: ["Name"],
      };
    } else {
      param = {
        Limit: lazyState.rows,
        Page: lazyState.page,
        SortBy: lazyState.sortOrder === 1 ? "ASC" : "DESC",
        SortProperty: lazyState.sortField,
      };
    }

    const paginatedSkills = await getPaginatedSkills(dispatch, param);
    setTotalSkills(paginatedSkills?.TotalCount);
    setSkills(paginatedSkills?.List);
  };

  const descriptionExpandHandler = () => {
    const tempDescriptionExpandInfo = { ...expandDescription };
    if (skills) {
      skills.forEach((skill) => {
        tempDescriptionExpandInfo[skill.id] = false;
      });
    }
    setExpandDescription(tempDescriptionExpandInfo);
  };

  const editGoal = (rowData: any) => {
    setIsEditClicked(true);
    setPrevSkillName(rowData.Name);
    setSkillId(rowData.id);
    setShowSkillDialog(true);
    setSkillInfo({
      Name: rowData.Name,
      Details: rowData.Details,
    });
  };

  const handleSave = async () => {
    if (checkForErrors(skillInfo)) {
      if (isEditClicked) {
        if (skillId) {
          const input = {
            Name: skillInfo.Name.trim(),
            Details: skillInfo.Details.trim(),
            prevSkillName,
          };
          const response = await updateSkill(dispatch, skillId, input);
          if (response === "A skill with this name  already exists.") {
            return showToast(
              "error",
              "A skill with this name  already exists.",
              "Error"
            );
          } else {
            showToast("success", "Skill Updated successfully", "Success");
          }
        }
      } else {
        const input = {
          Name: skillInfo.Name.trim(),
          Details: skillInfo.Details.trim(),
        };
        const response = await createSkill(dispatch, input);
        if (response === "Skill Name already exists") {
          return showToast("error", "Skill Name already exists", "Error");
        } else {
          showToast("success", "Skill created successfully", "Success");
        }
      }

      resetDialog();
      allDataFunc();
    }
  };

  const checkForErrors = (skill: SkillInfo) => {
    let isValid = true;
    let errors = { ...skillErrors };
    if (!skill.Name.trim()) {
      errors.Name = true;
      isValid = false;
    }

    setSkillErrors(errors);
    return isValid;
  };

  const onSkillChange = (name: string, value: string) => {
    setSkillErrors({ ...skillErrors, [name]: false });
    setSkillInfo((prevSkill) => ({
      ...prevSkill,
      [name]: value,
    }));
  };
  //#endregion

  //#region all templates
  const confirm2 = (rowData: SkillDto) => {
    confirmDialog({
      message: (
        <div>
          <p>Do you really want to delete {rowData.Name}?</p>
        </div>
      ),
      header: `${localization?.DeleteConfirmation || "Delete Confirmation"}`,

      className: "del_modal",
      headerStyle: {
        backgroundColor: mode?.backgroundSecondaryColor,
        color: mode?.color,
      },
      style: {
        width: "350px",
        backgroundColor: mode?.backgroundSecondaryColor,
        color: mode?.color,
      },
      contentStyle: {
        backgroundColor: mode?.backgroundSecondaryColor,
        color: mode?.color,
      },
      acceptClassName: "p-button-danger",
      accept: () => accept(rowData.id),
      reject,
    });
  };

  const deleteSkillById = (rowData: SkillDto) => {
    confirm2(rowData);
  };

  const startToolbar = () => {
    return (
      <div>
        <h4>{localization?.Skills || "Skills"}</h4>
      </div>
    );
  };

  const endToolbar = () => {
    return (
      <div className="endToolbar">
        <Button
          className="justify-content-center"
          label={localization?.Create || "Create"}
          onClick={() => {
            setShowSkillDialog(true);
          }}
          style={{
            width: "30%",
            backgroundColor: ThemeColors?.primaryColor,
            border: "none",
          }}
        />

        <InputText
          type="search"
          placeholder={` ${localization?.Search || "Search"}...`}
          onChange={(e) => {
            setTimeout(() => {
              setSearchText(e.target.value);
            }, 500);
          }}
        />
      </div>
    );
  };

  const editDeleteButtonTemplate = (rowData: any) => {
    return (
      <div className="editDeleteButtonTemplate skill_table_btns bengali_btn_style">
        <Button
          label={localization?.Edit || "Edit"}
          className="mr-2 editDelButton"
          style={{ backgroundColor: ThemeColors?.primaryColor }}
          onClick={() => {
            editGoal(rowData);
          }}
        />
        <Button
          label={localization?.Delete || "Delete"}
          className="editDelButton"
          onClick={() => {
            deleteSkillById(rowData);
          }}
          style={{ backgroundColor: "#d80300" }}
        />
      </div>
    );
  };

  const dialogHeader = () => {
    return (
      <>
        <div className="dialogHeader">
          <h4>
            {isEditClicked
              ? localization?.EditSkill || "Edit Skill"
              : localization?.CreateSkill || "Create Skill"}
          </h4>
        </div>
      </>
    );
  };

  const skillDescriptionTemplate = (rowData: any) => {
    return (
      <>
        {rowData?.Details?.length > 150 ? (
          <>
            {rowData.Details.slice(0, 150)}
            <span
              className={`goal-description-expanded-dots${rowData.id} expanded-dots`}
              data-pr-position="bottom"
            >
              ...
              <Tooltip
                target={`.goal-description-expanded-dots${rowData.id}`}
                content={rowData.Details}
                style={{ width: "50rem" }}
              />
            </span>
          </>
        ) : (
          rowData.Details
        )}
      </>
    );
  };

  const skillNameTemplate = (rowData: any) => {
    return (
      <>
        {rowData.Name.length > 70 ? (
          <>
            {rowData.Name.slice(0, 70)}
            <span
              className={`name-expanded-dots${rowData.id} expanded-dots`}
              data-pr-position="bottom"
            >
              .....
              <Tooltip
                target={`.name-expanded-dots${rowData.id}`}
                content={rowData.Name}
                style={{ width: "50rem" }}
              />
            </span>
          </>
        ) : (
          rowData.Name
        )}
      </>
    );
  };

  const dialogFooter = () => {
    return (
      <div
        style={{
          padding: "1rem 0",
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
      >
        <Button
          label={localization?.Save || "Save"}
          className="kpi-save-button"
          onClick={handleSave}
          style={{ backgroundColor: ThemeColors?.primaryColor }}
        />
      </div>
    );
  };
  //#endregion
  useEffect(() => {
    descriptionExpandHandler();
  }, [skills]);

  useEffect(() => {
    allDataFunc();
  }, [lazyState, searchText]);

  return (
    <div>
      <Toast ref={toast} />

      <ConfirmDialog />
      <Toolbar
        className="mb-3"
        start={startToolbar}
        end={endToolbar}
        style={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
      />

      <Card
        style={{ backgroundColor: mode?.backgroundSecondaryColor }}
        className="kpi_tabile_res"
      >
        <DataTable
          className="audit-log-table"
          ref={dt}
          value={skills}
          selection={selectedSkill}
          onSelectionChange={(e: any) => setSelectedSkill(e.value)}
          dataKey="id"
          lazy
          paginator
          first={lazyState.first}
          rows={lazyState.rows}
          totalRecords={totalSkills}
          onSort={onSort}
          onPage={onPageChange}
          sortOrder={lazyState.sortOrder}
          sortField={lazyState.sortField}
          showGridlines
          rowsPerPageOptions={[5, 8, 10, 20]}
          currentPageReportTemplate="Total Records {totalRecords}"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        >
          <Column
            className="kpiColumn"
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
              minWidth: "200px",
            }}
            field="Name"
            body={skillNameTemplate}
            header={localization?.Name || "Name"}
            sortable
          ></Column>
          <Column
            className="kpiColumn"
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            field="Details"
            body={skillDescriptionTemplate}
            header={localization?.Details || "Details"}
          ></Column>
          <Column
            className="kpiColumn col_center actionColumn"
            bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            body={editDeleteButtonTemplate}
            header={localization?.Action || "Action"}
          ></Column>
        </DataTable>
      </Card>
      <Dialog
        visible={showSkillDialog}
        header={dialogHeader}
        footer={dialogFooter}
        style={{
          width: "50vw",
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        contentStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        headerStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        baseZIndex={999}
        onHide={resetDialog}
        className="dialog"
        draggable={false}
      >
        <div className="field">
          <label htmlFor="name" className="font-bold">
            {localization?.Name || "Name"}*
          </label>
          <InputText
            id="goal-name"
            name="Name"
            value={skillInfo.Name}
            onChange={(e) => onSkillChange(e.target.name, e.target.value)}
          />

          {skillErrors.Name && (
            <small className="p-error">Skill Name is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="information" className="font-bold">
            {localization?.Details || "Details"}
          </label>
          <InputTextarea
            className="custom-textarea"
            id="goal-description"
            name="Details"
            value={skillInfo.Details}
            onChange={(e) => onSkillChange(e.target.name, e.target.value)}
            rows={5}
            cols={30}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default SkillTable;

import * as react from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import {
  faArrowUpFromGroundWater,
  faListCheck,
  faBezierCurve,
  faChartLine,
  faFolderPlus,
  faMagnifyingGlassDollar,
  faUserGear,
  faUserTie,
  faUsers,
  faChartColumn,
  faGear,
  faEnvelope,
  faUserGroup,
  faUsersGear,
  faEnvelopeOpen,
  faChartSimple,
  faClock,
  faNoteSticky,
  faHourglassHalf,
  faBullseye,
  faUsersRectangle,
  faUserPen,
  faBraille,
  faPenToSquare,
  faChartPie,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Themes } from "../../data/Themes";
import { ThemeState } from "../../dtos/common/ThemeState";
import "../../css/components/sidebar.css";
import { Link } from "react-router-dom";
import image from "../../images/epms_logo_main.png";
import icon from "../../images/icon.png";
import { setSideBar } from "../../store/slices/sidebarSlice";
import { SideBarState } from "../../dtos/sidebar/SideBarState";
import { memo, useEffect } from "react";
import { getUserRole } from "../../service/user/userService";
import { getSubordinatesNameIDCode } from "../../service/employee/employeeService";

const ProSidebar = () => {
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  const collapsed = useSelector((state: any) => state.sidebar.isOpen);
  const dispatch = useDispatch();
  const [role, setRole] = react.useState("");
  const [subordinates, setSubordinates] = react.useState("");
  const ref = react.useRef<any>(null);
  const isOpen = useSelector((state: SideBarState) => state.sidebar.isOpen);
  const onItemClick = (path: string) => {
    localStorage.setItem("lastRoute", `/${path}`);
  };
  const localization = useSelector(
    (state: any) => state.localization.localization
  );

  const handleClickOutside = (event: any) => {
    const screenWidth = window.innerWidth;
    const shouldOpenSidebar = screenWidth < 1200;
    if (!shouldOpenSidebar) {
      return false;
    } else {
      if (!isOpen) {
        if (ref.current && !ref.current.contains(event.target)) {
          dispatch(setSideBar(true));
        }
      }
    }
  };
  const fetchRole = async () => {
    const roleData = await getUserRole(dispatch);
    setRole(roleData);
  };
  const fetchSubordinates = async () => {
    const roleData = await getSubordinatesNameIDCode(dispatch);
    setSubordinates(roleData);
  };

  react.useEffect(() => {
    const handleMediaQueryChange = (e: MediaQueryListEvent) => {
      dispatch(setSideBar(!e.matches));
    };

    const mediaQuery = window.matchMedia("(min-width: 1199px)");
    mediaQuery.addEventListener("change", handleMediaQueryChange);

    // Initialize the sidebar state based on the initial media query
    dispatch(setSideBar(!mediaQuery.matches));

    // Cleanup event listener when component unmounts
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  const getPrimaryColorForPath = (path: string) => {
    const pathname = window.location.pathname;

    const colour =
      pathname === path || pathname.startsWith(path) || pathname.endsWith(path)
        ? ThemeColors?.primaryColor
        : "";
    return colour;
  };

  useEffect(() => {
    // Attach event listener when component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    fetchRole();
    fetchSubordinates();
  }, []);

  return (
    <div
      className="pro-sidebar-container"
      style={{
        backgroundColor: ThemeColors?.primaryColor,
        color: ThemeColors?.fontColor,
      }}
    >
      {/* <ProSidebarProvider > */}
      <Sidebar collapsed={collapsed} className="main-sidebar" ref={ref}>
        <header
          style={{
            backgroundColor: ThemeColors?.primaryColor,
            color: ThemeColors?.fontColor,
          }}
        >
          <img
            src={collapsed ? icon : image}
            className={collapsed ? "sidebar-icon" : "sidebar-image"}
          />
        </header>
        <Menu
          style={{
            backgroundColor: ThemeColors?.primaryColor,
            color: ThemeColors?.fontColor,
          }}
          className="sidebar-menu"
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              if (level === 0)
                return {
                  color: active
                    ? ThemeColors?.primaryColor
                    : ThemeColors?.fontColor,
                  backgroundColor: active
                    ? "white"
                    : ThemeColors?.secondaryColor,
                  borderRadius: "0.5rem",
                  "&:hover": {
                    backgroundColor: ThemeColors?.fontColor,
                    color: ThemeColors?.primaryColor,
                  },
                };
              if (level === 1)
                return {
                  color: disabled ? "red" : ThemeColors?.fontColor,
                  backgroundColor: active
                    ? "#eecef9"
                    : ThemeColors?.secondaryColor,
                  borderRadius: "0.5rem",
                  "&:hover": {
                    backgroundColor: ThemeColors?.fontColor,
                    color: ThemeColors?.primaryColor,
                  },
                };
            },
          }}
        >
          {/* dashboard */}
          <MenuItem
            component={<Link to="/dashboard" />}
            active={window.location.pathname === "/dashboard"}
            onClick={() => onItemClick("dashboard")}
            className="sidebar-menu-item"
            icon={<FontAwesomeIcon icon={faChartLine} className="nav-icon" />}
            style={{ color: getPrimaryColorForPath("/dashboard") }}
          >
            {localization?.Dashboard || "Dashboard"}
          </MenuItem>

          {/* project */}
          <MenuItem
            component={<Link to="/project" />}
            active={window.location.pathname === "/project"}
            onClick={() => onItemClick("project")}
            className="sidebar-menu-item"
            icon={<FontAwesomeIcon icon={faListCheck} className="nav-icon" />}
            style={{
              color:
                window.location.pathname === "/project"
                  ? ThemeColors?.primaryColor
                  : "",
            }}
          >
            {localization?.Project || "Project"}
          </MenuItem>

          {/* your team */}
          <MenuItem
            component={<Link to="/employee" />}
            active={window.location.pathname === "/employee"}
            className="sidebar-menu-item"
            icon={<FontAwesomeIcon icon={faUsers} className="nav-icon" />}
            style={{
              color:
                window.location.pathname === "/employee"
                  ? ThemeColors?.primaryColor
                  : "",
            }}
          >
            {localization?.MyTeam || "My Team"}
          </MenuItem>

          {/* your performance */}
          <MenuItem
            component={<Link to="/yourPerformance" />}
            active={window.location.pathname === "/yourPerformance"}
            className="sidebar-menu-item"
            icon={<FontAwesomeIcon icon={faChartPie} />}
            style={{ color: getPrimaryColorForPath("/yourPerformance") }}
          >
            {localization?.MyPerformance || "My Performance"}
          </MenuItem>

          {/* project billing */}
          <MenuItem
            component={<Link to="/projectBilling" />}
            active={window.location.pathname === "/projectBilling"}
            className="sidebar-menu-item"
            icon={<FontAwesomeIcon icon={faFolderPlus} className="nav-icon" />}
            style={{ color: getPrimaryColorForPath("/projectBilling") }}
          >
            {localization?.ProjectBilling || "Project Billing"}
          </MenuItem>

          {/* employee billing */}
          <MenuItem
            component={<Link to="/employeeBilling" />}
            active={window.location.pathname === "/employeeBilling"}
            className="sidebar-menu-item"
            icon={<FontAwesomeIcon icon={faUserTie} className="nav-icon" />}
            style={{ color: getPrimaryColorForPath("/employeeBilling") }}
          >
            {localization?.EmployeeBilling || "Employee Billing"}
          </MenuItem>

          {/* KPI */}
          {role?.toLowerCase() === "admin" && (
            <MenuItem
              component={<Link to="/kpi" />}
              active={window.location.pathname === "/kpi" || window.location.pathname.startsWith("/kpi/")}
              className="sidebar-menu-item"
              icon={
                <FontAwesomeIcon
                  icon={faArrowUpFromGroundWater}
                  className="nav-icon"
                />
              }
              style={{
                color:
                  window.location.pathname === "/kpi"
                    ? ThemeColors?.primaryColor
                    : "",
              }}
            >
              {localization?.KPI || "KPI"}
            </MenuItem>
          )}
          {role?.toLowerCase() === "admin" && (
            <MenuItem
              component={<Link to="/option" />}
              active={window.location.pathname === "/option"}
              className="sidebar-menu-item"
              icon={<FontAwesomeIcon icon={faBraille} className="nav-icon" />}
              style={{ color: getPrimaryColorForPath("/option") }}
            >
              {localization?.Options || "Options"}
            </MenuItem>
          )}

          {/* KPI Mappings */}
          {role?.toLowerCase() === "admin" && (
            <SubMenu
              label={localization?.KPIMappings || "KPI Mappings"}
              active={window.location.pathname.endsWith("ppings")}
              icon={
                <FontAwesomeIcon icon={faBezierCurve} className="nav-icon" />
              }
              className="sidebar-submenu-item"
              style={{ color: getPrimaryColorForPath("ppings") }}
            >
              <div
                style={{
                  backgroundColor: ThemeColors?.primaryColor,
                  color: ThemeColors?.fontColor,
                }}
                className="sidebar-submenu-contents"
              >
                <MenuItem
                  component={<Link to="/kpiMappings" />}
                  active={window.location.pathname === "/kpiMappings"}
                  className="sidebar-menu-item"
                  icon={
                    <FontAwesomeIcon icon={faUsersGear} className="nav-icon" />
                  }
                  style={{ color: getPrimaryColorForPath("/kpiMappings") }}
                >
                  {localization?.RoleMappings || "Role Mappings"}
                </MenuItem>

                <MenuItem
                  component={<Link to="/userMappings" />}
                  active={window.location.pathname === "/userMappings"}
                  className="sidebar-menu-item"
                  icon={
                    <FontAwesomeIcon icon={faUserGroup} className="nav-icon" />
                  }
                  style={{ color: getPrimaryColorForPath("/userMappings") }}
                >
                  {localization?.UserMappings || "User Mappings"}
                </MenuItem>
              </div>
            </SubMenu>
          )}

          {/* Performance */}
          <MenuItem
            component={<Link to="/performance" />}
            active={window.location.pathname.startsWith("/performance")}
            className="sidebar-menu-item"
            icon={
              <FontAwesomeIcon
                icon={faMagnifyingGlassDollar}
                className="nav-icon"
              />
            }
            style={{ color: getPrimaryColorForPath("/performance") }}
          >
            {localization?.RatingsReviews || "Ratings & Reviews"}
          </MenuItem>

          {/* Performance Report*/}
          <MenuItem
            component={<Link to="/ratingsReport" />}
            active={window.location.pathname.startsWith("/ratingsReport")}
            className="sidebar-menu-item"
            icon={<FontAwesomeIcon icon={faChartSimple} className="nav-icon" />}
            style={{ color: getPrimaryColorForPath("/ratingsReport") }}
          >
            {localization?.PerformanceReport || "Performance Report"}
          </MenuItem>

          {/* Report */}
          <MenuItem
            component={<Link to="/report" />}
            active={window.location.pathname === "/report"}
            className="sidebar-menu-item"
            icon={<FontAwesomeIcon icon={faChartColumn} className="nav-icon" />}
            style={{ color: getPrimaryColorForPath("/report") }}
          >
            {localization?.Report || "Report"}
          </MenuItem>
          {role?.toLowerCase() === "admin" && (
            <MenuItem
              component={<Link to="/goals" />}
              active={
                window.location.pathname === "/goals" ||
                window.location.pathname.startsWith("/updateGoal/")
              }
              style={{
                color:
                  getPrimaryColorForPath("/goals") ||
                  getPrimaryColorForPath("/updateGoal/"),
              }}
              className="sidebar-menu-item"
              icon={<FontAwesomeIcon icon={faBullseye} />}
            >
              {localization?.Goals || "Goals"}
            </MenuItem>
          )}
          {/*User Goals */}
          {subordinates.length > 1 && (
            <MenuItem
              component={<Link to="/userGoals" />}
              active={window.location.pathname === "/userGoals"}
              className="sidebar-menu-item"
              icon={<FontAwesomeIcon icon={faUsersRectangle} />}
              style={{ color: getPrimaryColorForPath("/userGoals") }}
            >
              {localization?.UserGoals || "User Goals"}
            </MenuItem>
          )}
          {/* My Goals */}
          <MenuItem
            component={<Link to="/myGoals" />}
            active={window.location.pathname === "/myGoals"}
            className="sidebar-menu-item"
            icon={<FontAwesomeIcon icon={faUserPen} />}
            style={{ color: getPrimaryColorForPath("/myGoals") }}
          >
            {localization?.MyGoals || "My Goals"}
          </MenuItem>
          {/* Skills */}

          {subordinates.length > 1 && (
            <MenuItem
              component={<Link to="/skills" />}
              active={window.location.pathname === "/skills"}
              className="sidebar-menu-item"
              icon={<FontAwesomeIcon icon={faPenToSquare} />}
              style={{ color: getPrimaryColorForPath("/skills") }}
            >
              {localization?.Skills || "Skills"}
            </MenuItem>
          )}

          {/* Settings */}
          {role?.toLowerCase() === "admin" && (
            <SubMenu
              active={window.location.pathname.endsWith("ttings")}
              className="sidebar-submenu-item"
              icon={<FontAwesomeIcon icon={faGear} className="nav-icon" />}
              label={localization?.Settings || "Settings"}
              style={{ color: getPrimaryColorForPath("ttings") }}
            >
              <div
                style={{
                  backgroundColor: ThemeColors?.primaryColor,
                  color: ThemeColors?.fontColor,
                }}
                className="sidebar-submenu-contents"
              >
                {/* Mail Settings */}
                <MenuItem
                  component={<Link to="/mailSettings" />}
                  active={window.location.pathname === "/mailSettings"}
                  style={{
                    paddingTop: "0",
                    paddingRight: "0.2rem",
                    color: getPrimaryColorForPath("/mailSettings"),
                  }}
                  className="sidebar-menu-item"
                  icon={
                    <FontAwesomeIcon icon={faEnvelope} className="nav-icon" />
                  }
                >
                  {localization?.MailSettings || "Mail Settings"}
                </MenuItem>

                {/* Mail Template  */}
                <MenuItem
                  component={<Link to="/mailTemplateSettings" />}
                  active={window.location.pathname === "/mailTemplateSettings"}
                  style={{
                    paddingRight: "0.8rem 0.1rem 0 0.2rem",
                    color: getPrimaryColorForPath("/mailTemplateSettings"),
                  }}
                  className="sidebar-menu-item"
                  icon={
                    <FontAwesomeIcon
                      icon={faEnvelopeOpen}
                      className="nav-icon"
                    />
                  }
                >
                  {localization?.MailTemplate || "Mail Template"}
                </MenuItem>

                {/* Project Settings  */}
                {/* <MenuItem
                  component={<Link to="/projectSettings" />}
                  active={window.location.pathname === "/projectSettings"}
                  style={{ paddingRight: "0.8rem 0.1rem 0 0.2rem" }}
                  className="sidebar-menu-item"
                  icon={
                    <FontAwesomeIcon icon={faGears} className="nav-icon" />
                  }
                >
                  {localization?.ProjectSettings || "Project Settings"}
                </MenuItem> */}

                {/* Application Settings */}
                <MenuItem
                  component={<Link to="/applicationSettings" />}
                  active={window.location.pathname === "/applicationSettings"}
                  style={{
                    paddingRight: "0.8rem 0.1rem 0 0.2rem",
                    color: getPrimaryColorForPath("/applicationSettings"),
                  }}
                  className="sidebar-menu-item"
                  icon={
                    <FontAwesomeIcon icon={faUserGear} className="nav-icon" />
                  }
                >
                  {localization?.AppSettings || "App Settings"}
                </MenuItem>
                {/* Audit log */}
                <MenuItem
                  component={<Link to="/auditLog" />}
                  active={window.location.pathname === "/auditLog"}
                  style={{
                    paddingRight: "0.8rem 0.1rem 0 0.2rem",
                    color: getPrimaryColorForPath("/auditLog"),
                  }}
                  className="sidebar-menu-item"
                  icon={
                    <FontAwesomeIcon icon={faNoteSticky} className="nav-icon" />
                  }
                >
                  {localization?.AuditLog || "Audit Log"}
                </MenuItem>
                {/* Cron Job Data */}
                <MenuItem
                  component={<Link to="/cronJobDataSettings" />}
                  active={window.location.pathname === "/cronJobDataSettings"}
                  style={{
                    paddingRight: "0.8rem 0.1rem 0 0.2rem",
                    color: getPrimaryColorForPath("/cronJobDataSettings"),
                  }}
                  className="sidebar-menu-item"
                  icon={<FontAwesomeIcon icon={faClock} className="nav-icon" />}
                >
                  {localization?.CronJobData || "CronJob Data"}
                </MenuItem>
                {/* Cron Job Status */}
                <MenuItem
                  component={<Link to="/cronJobStatusSettings" />}
                  active={window.location.pathname === "/cronJobStatusSettings"}
                  style={{
                    paddingRight: "0.8rem 0.1rem 0 0.2rem",
                    color: getPrimaryColorForPath("/cronJobStatusSettings"),
                  }}
                  className="sidebar-menu-item"
                  icon={
                    <FontAwesomeIcon
                      icon={faHourglassHalf}
                      className="nav-icon"
                    />
                  }
                >
                  {localization?.CronJobStatus || "CronJob Status"}
                </MenuItem>
              </div>
            </SubMenu>
          )}
        </Menu>
      </Sidebar>
      {/* </ProSidebarProvider> */}
    </div>
  );
};

export default ProSidebar;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cron-input {
    padding: 0.6rem 1rem;
    border: 1px solid #c9c5c5;
    border-radius: 4.9px;
    width: 50%;
    font-size: 1.2rem;
    text-align: center;
}

.cron-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
}

.cron-container .p-button {
    padding: 0.75rem 1rem;
}

.react-js-cron>div,
.react-js-cron-field {
    flex-wrap: wrap;
    justify-content: center;
}

.cron-btn-container {
    display: flex;
    gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/css/components/cronSettings.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,yBAAyB;IACzB,oBAAoB;IACpB,UAAU;IACV,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;;IAEI,eAAe;IACf,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,SAAS;AACb","sourcesContent":[".cron-input {\n    padding: 0.6rem 1rem;\n    border: 1px solid #c9c5c5;\n    border-radius: 4.9px;\n    width: 50%;\n    font-size: 1.2rem;\n    text-align: center;\n}\n\n.cron-container {\n    display: flex;\n    flex-direction: column;\n    gap: 1.5rem;\n    align-items: center;\n}\n\n.cron-container .p-button {\n    padding: 0.75rem 1rem;\n}\n\n.react-js-cron>div,\n.react-js-cron-field {\n    flex-wrap: wrap;\n    justify-content: center;\n}\n\n.cron-btn-container {\n    display: flex;\n    gap: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

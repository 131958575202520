import { createSlice } from "@reduxjs/toolkit";
import { MainPerformanceDto } from "../../dtos/performance/MainPerformanceDataDto";
import {
  performanceDate,
  performanceEmpCode,
} from "../../shared/constant/Common";

interface State {
  mode: string;
  date: any;
  data: MainPerformanceDto | {};
  roleName: string;
  employeeCode: string;
}
interface Action {
  type: string;
  payload: State;
}

const performanceViewInputSlice = createSlice({
  name: "performanceViewInputSlice",
  initialState: {
    mode: "view",
    date: localStorage.getItem(performanceDate)
      ? new Date(localStorage.getItem(performanceDate) as string)
      : new Date().toLocaleString(),
    data: {},
    roleName: "",
    employeeCode: localStorage.getItem(performanceEmpCode)
      ? localStorage.getItem(performanceEmpCode) as string
      : "",
  },
  reducers: {
    setInputs(state: State, action: Action) {
      state.mode = action.payload.mode;
      state.date = action.payload.date;
      state.data = action.payload.data;
      state.roleName = action.payload.roleName;
      state.employeeCode = action.payload.employeeCode;
      return state;
    },
  },
});

export const { setInputs } = performanceViewInputSlice.actions;
export default performanceViewInputSlice.reducer;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mail-template {
    padding: 2rem 0;
}

.mail-template-buttons {
    display: flex;
    gap: 15px;
    padding: 1rem 0;
    justify-content: space-between;
}

.add-content-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.mail-template-save-btn {
    /* margin-left: auto; */
    display: block;
    margin-top: 0.9rem;
    line-height: 0;
}

.editor {
    margin-bottom: 10px;
}

.p-editor-container .p-editor-content .ql-editor {
    background-color: inherit !important;
    color: inherit !important;
}

.dark .p-editor-container .p-editor-toolbar {
    background-color: #222 !important;
}

.dark .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    background-color: #222 !important;
}

.dark .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
    color: #999 !important;
}
.mail-template-dialog{
    width: 35vw;
}
.mail-template-dialog table{
    width: 100%;
  }`, "",{"version":3,"sources":["webpack://./src/css/components/mailTemplate.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,eAAe;IACf,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,eAAe;IACf,SAAS;AACb;;AAEA;IACI,uBAAuB;IACvB,cAAc;IACd,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,oCAAoC;IACpC,yBAAyB;AAC7B;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,sBAAsB;AAC1B;AACA;IACI,WAAW;AACf;AACA;IACI,WAAW;EACb","sourcesContent":[".mail-template {\n    padding: 2rem 0;\n}\n\n.mail-template-buttons {\n    display: flex;\n    gap: 15px;\n    padding: 1rem 0;\n    justify-content: space-between;\n}\n\n.add-content-buttons {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 15px;\n}\n\n.mail-template-save-btn {\n    /* margin-left: auto; */\n    display: block;\n    margin-top: 0.9rem;\n    line-height: 0;\n}\n\n.editor {\n    margin-bottom: 10px;\n}\n\n.p-editor-container .p-editor-content .ql-editor {\n    background-color: inherit !important;\n    color: inherit !important;\n}\n\n.dark .p-editor-container .p-editor-toolbar {\n    background-color: #222 !important;\n}\n\n.dark .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {\n    background-color: #222 !important;\n}\n\n.dark .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {\n    color: #999 !important;\n}\n.mail-template-dialog{\n    width: 35vw;\n}\n.mail-template-dialog table{\n    width: 100%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

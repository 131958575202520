import { useEffect, useRef, useState } from "react"
import { AllAuditLog } from "../../dtos/auditLog/AllAuditLog";
import { getAllLog } from "../../service/auditLog/AuditLogService";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { LazyState } from "../KPI/KPITable";
import { AppMode } from "../../data/AppMode";
import { Themes } from "../../data/Themes";
import { AppModeState } from "../../dtos/common/AppModeState";
import { ThemeState } from "../../dtos/common/ThemeState";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { DateRange } from "react-date-range";
import { Button } from "primereact/button";
import '../../css/components/auditlog.css';
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import moment from "moment";

export const AuditLog = () => {
    const [allLog, setAllLog] = useState<AllAuditLog[]>([]);
    const dispatch = useDispatch();
    const [totalCount, setTotalCount] = useState<number>(0);
    const themeName = useSelector((state: ThemeState) => state.theme.themeName);
    const ThemeColors = Themes.find(th => th.name === themeName);
    const localization = useSelector((state: any) => state.localization.localization);
    const modeName = useSelector((state: AppModeState) => state.theme.appMode);
    const mode = AppMode.find(md => md.name === modeName);
    const [date, setDate] = useState<Date[]>([new Date(), new Date()]);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const datePickerRef = useRef<any>(null);
    const [searchUserName, setSearchUserName] = useState<string>('');
    const [searchEmployeeCode, setSearchEmployeeCode] = useState<string>('');
    const [searchResponseType, setSearchResponseType] = useState<any>();
    const [searchMethodName, setSearchMethodName] = useState<string>('');
    const [searchDuration, setSearchDuration] = useState<number>(0);
    const [showFilters, setShowFilters] = useState<boolean>(true);
    const types = [{ name: 'Success', code: 'Success' }, { name: 'Failed', code: 'Failed' }]
    const [lazyState, setlazyState] = useState<any>({
        page: 0,
        first: 0,
        rows: 10,
        sortField: 'id',
        sortOrder: 0 as number
    });


    function addDays(date: Date, days: number) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }
    function removeDays(date: Date, days: number) {
        var result = new Date(date);
        result.setDate(result.getDate() - days);
        return result;
    }
    const handleFilters = () => {
        let filters: any[] = []

        if (searchUserName.length > 0) {
            filters.push({ Field: 'UserName', Value: searchUserName })
        }
        if (searchEmployeeCode.length > 0) {
            filters.push({ Field: 'EmployeeCode', Value: searchEmployeeCode })
        }
        if (searchMethodName.length > 0) {
            filters.push({ Field: 'MethodName', Value: searchMethodName })
        }
        if (searchResponseType?.code.length > 0) {
            filters.push({ Field: 'ResponseType', Value: searchResponseType?.code })
        }
        if (searchDuration > 0) {
            filters.push({ Field: 'Duration', Value: searchDuration })
        }
        return filters
    }

    const getAllAuditLog = async () => {
        //get all the filters
        const filterData = handleFilters();

        let startDate;
        let endDate;

        if (date[0].getDate() === date[1].getDate()) {
            endDate = date[0].toISOString();
            startDate = removeDays(date[0], 1).toISOString()
        }
        else {
            startDate = date[0];
            endDate = addDays(date[1], 1)
        }

        const param: any = {
            Limit: lazyState.rows,
            Page: lazyState.page,
            SortBy: lazyState.sortOrder === 1 ? 'ASC' : 'DESC',
            SortProperty: lazyState.sortField,
            Filter: filterData,
            StartDate: startDate,
            EndDate: endDate
        }



        if (!showDatePicker) {
            const data = await getAllLog(dispatch, param);

            setAllLog(data?.List);
            setTotalCount(data?.TotalCount)
        }
    }

    const onPageChange = (event: any) => {
        setlazyState(event)
    };

    const onSort = (event: any) => {
        setlazyState((prev: LazyState) => {
            const newState: LazyState = {
                ...prev, // Copy all properties from the previous state
                sortOrder: event.sortOrder, // Update sortOrder
                sortField: event.sortField, // Update sortField
            };
            return newState;
        });
    };

    //This function will execute when user select dates from date range calender which will show after clicking the date range button
    const handleDateRange = (item: any) => {
        const dateRan = [];
        dateRan.push(item?.selection?.startDate, item?.selection?.endDate);
        setDate(dateRan);
    };

    const handleDatePicker = () => {
        setShowDatePicker(true);
    }

    const handleShowFilters = () => {
        if (showFilters) {
            setShowFilters(false)
        }
        else {
            setShowFilters(true)
        }
    }

    //#region all UseEffects
    const handleClickOutside = (event: any) => {
        if (
            datePickerRef.current &&
            !datePickerRef.current.contains(event.target)
        ) {
            setShowDatePicker(false);
        }
    };

    const startToolbar = () => {
        return (
            <div>

                {showDatePicker && (
                    <div

                        ref={datePickerRef}
                        className="date-range"
                        style={{
                            backgroundColor: ThemeColors?.primaryColor,
                            color: "white",
                        }}
                    >
                        <DateRange
                            onChange={(item) => handleDateRange(item)}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={[
                                {
                                    startDate: date[0],
                                    endDate: date[1],
                                    key: "selection",
                                },
                            ]}
                            direction="horizontal"
                        />

                    </div>
                )}
                <div className="custom_date_range audit-daterange" onClick={handleDatePicker}>
                    {String(date[0].getDate()).padStart(2, "0")}/
                    {String(date[0].getMonth() + 1).padStart(2, "0")}/
                    {date[0].getFullYear()} -{" "}
                    {String(date[1].getDate()).padStart(2, "0")}/
                    {String(date[1]?.getMonth() + 1).padStart(2, "0")}/
                    {date[1].getFullYear()}
                </div>
            </div>
        )
    };

    const endToolbar = () => {
        return (
            <div className="endToolbar audit-endtoolbar">
                <div className="upper-end-toolbar">
                    <InputText type="search" style={{ width: '16rem' }} placeholder={` ${localization?.Search || 'Search by user name'}...`}
                        onChange={(e) =>
                            setSearchUserName(e.target.value as string)
                        } />
                    <Button label='Refresh' icon='pi pi-sync' onClick={getAllAuditLog} />
                </div>
                {showFilters && <div className="lower-end-toolbar row">
                    <div className="col-xl-3 col-md-6">
                        <InputText className="w-full mb-xl-0 mb-2" type="search" style={{ width: '100%' }} placeholder={` ${localization?.Search || 'Search by employee code'}...`}
                            onChange={(e) =>
                                setSearchEmployeeCode(e.target.value as string)

                            } />
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <Dropdown value={searchResponseType} onChange={(e) => setSearchResponseType(e.value)} style={{ width: '100%' }} options={types} optionLabel="name"
                            placeholder="Select a type" showClear className="w-full mb-xl-0 mb-2" />
                    </div>
                    <div className="col-xl-3 col-md-6">

                        <InputText className="w-full mb-xl-0 mb-2" type="search" style={{ width: '100%' }} placeholder={` ${localization?.Search || 'Search by method name'}...`}
                            onChange={(e) =>
                                setSearchMethodName(e.target.value as string)
                            } />
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <InputNumber className="w-full mb-xl-0 mb-2" type="search" style={{ width: '100%' }} placeholder={` ${localization?.Search || 'Search by duration '}...`}
                            onValueChange={(e) =>
                                setSearchDuration(e.value as number)
                            } />
                    </div>
                </div>}
                <div className="show-filters" onClick={handleShowFilters} >{showFilters ? <><i className="pi pi-angle-up"></i> Hide advanced filters</> : <><i className="pi pi-angle-down"></i> Show advanced filters</>}</div>
            </div>
        )
    };

    const durationTemplate = (data: AllAuditLog) => {
        return data.Duration + ' ms'
    }

    const executionTimeBodyTemp = (rowData: any) => {
        return `${moment(rowData.ExecutionTime).format('lll')}`
    }

    useEffect(() => {
        getAllAuditLog();
    }, [showDatePicker, lazyState]);


    useEffect(() => {
        // Attach event listener when component mounts
        document.addEventListener("mousedown", handleClickOutside);

        // Clean up the event listener when component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div>
            <Toolbar className="mb-3" start={startToolbar} end={endToolbar} style={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} />
            <Card style={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }}>
                <DataTable
                    className="performance-table audit-log-table"
                    value={allLog}
                    dataKey="id"
                    lazy
                    paginator
                    totalRecords={totalCount}
                    first={lazyState.first}
                    rows={lazyState.rows}
                    onSort={onSort}
                    onPage={onPageChange}
                    sortOrder={lazyState.sortOrder}
                    sortField={lazyState.sortField}
                    showGridlines
                    rowsPerPageOptions={[8, 10, 20]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Total Records {totalRecords} ">
                    <Column sortable style={{ minWidth: '12rem' }} bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="UserName" header='User Name' ></Column>
                    <Column sortable style={{ minWidth: '12rem' }} bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="EmployeeCode" header='Employee Code' ></Column>
                    <Column sortable bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} body={executionTimeBodyTemp} field="ExecutionTime" header='ExecutionTime' ></Column>
                    <Column bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="ResponseType" header='Type' ></Column>
                    <Column bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="MethodName" header='MethodName' ></Column>
                    <Column sortable bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="ResponseType" body={durationTemplate} header='Duration' ></Column>
                    <Column bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="Exception" header='Exception' ></Column>
                    <Column bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="ExceptionMessage" header='Exception Message' ></Column>
                    <Column bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="ClientIpAddress" header='Client Ip' ></Column>
                    <Column bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="Parameters" header='Parameters' ></Column>
                    <Column bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="ReturnValue" header='Return Value' ></Column>
                    <Column bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="BrowserInfo" header='Browser Info' ></Column>
                </DataTable>
            </Card>
        </div>
    )
}
import * as React from 'react'
import  UpperNavbar  from '../navbars/UpperNavbar';
import "../../css/components/main.css";
import { useSelector } from 'react-redux';
import { AppModeState } from '../../dtos/common/AppModeState';
import { AppMode } from '../../data/AppMode';
import { Outlet } from 'react-router-dom';
import { SideBarState } from '../../dtos/sidebar/SideBarState';
import { ThemeState } from "../../dtos/common/ThemeState";
import { ThemeButton } from "../subComponents/ThemeButton";
import { changeMode, changeTheme } from "../../store/slices/themeSlice";
import { Themes } from "../../data/Themes";

export const Main = () => {
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find(md => md.name === modeName);
  const isOpen = useSelector((state: SideBarState) => state.sidebar.isOpen);
  const screenWidth = window.innerWidth;
  const shouldOpenSidebar = screenWidth < 1200;


  // ${isOpen ? 'sidebar-close' : 'sidebar-open'}

  return (
    <div className={`main ${(!isOpen && shouldOpenSidebar) && 'sidebar_after' } ${isOpen ? 'sidebar-close' : 'sidebar-open'}`} style={{ backgroundColor: mode?.backgroundPrimaryColor }}   >
      
      <div className="rounded_top_left" style={{ backgroundColor: ThemeColors?.secondaryColor } }>
        <div className='leftPrimaryMainBg p-4' 
        // style={{ backgroundColor: mode?.backgroundPrimaryColor }}
        >
          <div className="cs_card cs_card_shadow p-0 mb-3">
            <UpperNavbar/>
          </div>
          <div className="under-main-container m-0 p-0">
            <Outlet />
          </div>
        </div>
      </div>
      
    </div>
  )
}

import { Toolbar } from "primereact/toolbar";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeState } from "../../dtos/common/ThemeState";
import { Themes } from "../../data/Themes";
import { AppModeState } from "../../dtos/common/AppModeState";
import { AppMode } from "../../data/AppMode";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { getSubordinatesByStatus } from "../../service/employee/employeeService";
import { EmployeeDto } from "../../dtos/employee/EmployeeDto";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import React from "react";
import { Button } from "primereact/button";
import "../../css/components/goalTable.css";
import { Dialog } from "primereact/dialog";
import { Tree } from "primereact/tree";
import { treeDataWithoutSelectable, treeDataWithSelectable, treeWithUnCheckedRoles } from "../roles/roleData";
import {
  checkPermissionExistance,
  createPermissionAssignByEmployeeCode,
  getPermissionByEmployeeCode,
} from "../../service/permissionAssign/permissionAssignServices";
import { Toast } from "primereact/toast";
import { TreeItemDto } from "../../dtos/employeeRoles/TreeItemDto";
import { isPermissionGiven } from "../../shared/functions/HasPermission";
import { createRoleMapping, createUserMapping, editRoleInfo, editRoleMapping, editRolePermission, editUserMapping, editUserPermission, indivisualRole, kpiMappings, roleMappings, userMapping, userPermission } from "../../shared/constant/PermissionVariables";
import { GetPaginationRowsInterface } from "../../dtos/common/GetPaginationRows";
import { GetPaginationRowsCount } from "../../shared/functions/GetPaginationRowsCount";
import { ADMIN } from "../../shared/constant/RoleNames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/free-solid-svg-icons";

interface PermissionCheckState {
  [key: string]: {
    checked: boolean;
    partialChecked: boolean;
  };
}

interface EmployeeInfoDetails {
  employeeCode: string;
  roleNames: string[];
  fullName: string;
  emailAddress: string;
  isActive: boolean;
  id: number;
}

interface Permission {
  id: number;
  RoleName: string;
  PermissionName: string;
  EmployeeCode: string | null;
  IsGranted: boolean;
}
const UserTable = () => {
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const dispatch = useDispatch();
  const [employee, setAllEmployee] = useState<EmployeeDto[]>([]);
  const dt = React.useRef<any>(null);
  const [searchText, setSearchText] = useState<string>("");
  const localization = useSelector(
    (state: any) => state.localization.localization
  );

  const [showPermissionDialog, setShowPermissionDialog] =
    useState<boolean>(false);
  const [clickedShowPermissionDialog, setClickedShowPermissionDialog] =
    useState<number | null>(null);

  const [selectedKeys, setSelectedKeys] = useState<PermissionCheckState | {}>(
    {}
  );
  const [previouslySelectedNodes, setPreviouslySelectedNodes] = useState<
    PermissionCheckState | {}
  >({});
  const [employeeInfo, setEmployeeInfo] = useState<EmployeeInfoDetails | null>(
    null
  );
  const toast = useRef<Toast>(null);
  const [allUserPermissions, setAllUserPermissions] = useState<
    { [key: string]: { checked: boolean; partialChecked: boolean } } | {}
  >({});
  const [alreadyCheckedPermission, setAlreadyCheckedPermission] = useState<
    PermissionCheckState | {}
  >({});
  const [expandedKeys, setExpandedKeys] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [totalSubordinates,setTotalSubordinates] = useState<number>(0)
  const userPermissions = useSelector(
    (state: any) => state.permission.permissions
  );
  const [permissionsInDB,setPermissionsInDB] = useState<any>()
  const row: GetPaginationRowsInterface = GetPaginationRowsCount();
  const [tree, setTree] = useState<any>([]);
  const [lazyState, setlazyState] = React.useState({
    page: 0,
    first: 0,
    rows: row.rowCount,
    sortField: "createdAt",
    sortOrder: 0 as any,
  });
  const [permissionExist,setPermissionExist] = useState<boolean|null>(null)
  //#region all Functions

  const getAllEmp = async () => {
    const result: {subordinates:EmployeeDto[],totalResult:number} = await getSubordinatesByStatus(dispatch, {
      Status: "active" as string,
      Limit: lazyState.rows,
      Page: lazyState.page,
      SearchValue:searchText?.toLowerCase()
    });
    const filteredEmp = result?.subordinates?.filter((emp) => {
      return (
        emp?.fullName?.toLowerCase().includes(searchText?.toLowerCase()) ||
        emp?.emailAddress?.toLowerCase().includes(searchText.toLowerCase()) ||
        emp?.employeeCode?.toLowerCase().startsWith(searchText?.toLowerCase())
      );
    });
    setTotalSubordinates(result.totalResult)  
    setAllEmployee(filteredEmp);
  };

  const showSkillsDialogHandler = (rowData: EmployeeInfoDetails) => {
    const hasPermission = isPermissionGiven(
      editUserPermission,
      userPermissions,
      dispatch
    );
    if (!hasPermission) {
      return;
    }
    checkIfPermissionExist(rowData)
    setShowPermissionDialog(true);
    setClickedShowPermissionDialog(rowData.id);
    setEmployeeInfo(rowData);
    getUserPermissions(rowData.employeeCode, rowData.roleNames[0]);
  };

  // Function to collect all node keys for expansion
  const expandAllNodes = (nodes: TreeItemDto[]) => {
    let keys: { [key: string]: boolean } = {};
    const traverse = (nodeArray: TreeItemDto[]) => {
      nodeArray.forEach((node) => {
        keys[node.key] = true; // Mark the node as expanded
        if (node.children) {
          traverse(node.children); // Recursively collect keys from children
        }
      });
    };
    traverse(nodes);
    return keys;
  };

  const resetDialog = () => {
    setShowPermissionDialog(false);
    setClickedShowPermissionDialog(null);
    setEmployeeInfo(null);
    setAlreadyCheckedPermission({});
    setAllUserPermissions({});
    setSelectedKeys({});
    setAllUserPermissions({});
    setPreviouslySelectedNodes({})
  };

  const showToast = (severity: any, detail: string, summary: string) => {
    toast.current?.show({ severity, summary, detail, life: 3000 });
  };

  const findParentNode = (
    nodes: any,
    childKey: any,
    parentNodes: any[] = []
  ): any[] | null => {
    for (const node of nodes) {
      // If current node has children, continue searching
      if (node.key === childKey) {
        return parentNodes.concat([node]);
      }
      if (node.children) {
        // Check if any child has the specified key
        if (node.children.some((child: any) => child.key === childKey)) {
          // Add the current node (parent) to the parentNodes array
          parentNodes.push(node);
          // Continue searching upwards by calling the function with the current node's key
          findParentNode(treeDataWithoutSelectable, node.key, parentNodes);
          return parentNodes; // Return once all parents are found
        }

        // Recursively call the function on children if child key not found at this level
        const result = findParentNode(node.children, childKey, parentNodes);
        if (result) return result; // Return if result is found in deeper recursive calls
      }
    }
    return null;
  };

  const getUserPermissions = async (empCode: string, roleName: string) => {
    const isPermissionExist = await checkPermissionExistance(dispatch, {
      roleName: roleName,
    });
    if (isPermissionExist && roleName===ADMIN) {
      setTree(treeDataWithoutSelectable);
    }else if(isPermissionExist && roleName!==ADMIN){
      setTree(treeWithUnCheckedRoles);
    } else{
      setTree(treeDataWithSelectable);
    }
    const alreadyGivenPermissions = await getPermissionByEmployeeCode(
      dispatch,
      {
        empCode,
        roleName,
      }
    );
    const checkedPermissions = alreadyGivenPermissions.filter((node)=>{
      return node.IsGranted
    })
    let obj: any = {};
    const permissions = checkedPermissions.forEach((permission) => {
      obj[permission.PermissionName] = { checked: true };
    });
    Object.keys(obj).forEach((item: any) => {
      const parents = findParentNode(treeDataWithoutSelectable, item);
      parents?.forEach((parent) => {
        obj[parent.key] = { checked: true };
      });
    });
    setSelectedKeys({ ...obj });
    setPreviouslySelectedNodes({ ...obj });
    setAllUserPermissions({ ...obj });
    setPermissionsInDB(alreadyGivenPermissions)
  };

  const handleSave = async () => {
  
 
    let updatedPermissions: any = [];
     

    const selectedNodes = Object.keys(selectedKeys);

    selectedNodes.forEach((permission: any) => {
      if (permissionsInDB.length === 0) {
        // If permissionsInDB is empty, add all selected keys to uncheckedNodes
        updatedPermissions.push({
          EmployeeCode: employeeInfo?.employeeCode,
          IsGranted: true,
          PermissionName: permission,
        });
      } else {
        // permissionsInDB is not empty; check if permission exists and compare IsGranted status
        const dbPermission = permissionsInDB.find(
          (node: any) => node.PermissionName === permission
        );
      
        if (dbPermission) {
          // Permission exists in DB, compare IsGranted status
          const selectedIsGranted = (selectedKeys as PermissionCheckState)[
            permission
          ]?.checked;
          
          if (dbPermission.IsGranted !== selectedIsGranted) {
            updatedPermissions.push({
              EmployeeCode: employeeInfo?.employeeCode,
              IsGranted: selectedIsGranted,
              PermissionName: permission,
            });
          }
        } else {
          // Permission is not in permissionsInDB but is in selectedKeys
          updatedPermissions.push({
            EmployeeCode:employeeInfo?.employeeCode,
            IsGranted: (selectedKeys as PermissionCheckState)[permission]
              ?.checked,
            PermissionName: permission,
          });
        }
      }
    });

    // Handle permissions in previouslySelectedNodes that are not in selectedKeys
    Object.keys(previouslySelectedNodes).forEach((dbPermission: any) => {
      if (!selectedKeys.hasOwnProperty(dbPermission)) {
        // If permission is in previouslySelectedNodes but missing in selectedKeys, set IsGranted to false
        updatedPermissions.push({
          EmployeeCode: employeeInfo?.employeeCode,
          IsGranted: false,
          PermissionName: dbPermission,
        });
      }
    });

    const createInput = [...updatedPermissions];
    if(createInput.length===0){
      return showToast(
        "error",
        "Select some permissions to save it!",
        "Error"
      );
    }
 
    const res = await createPermissionAssignByEmployeeCode(dispatch, createInput);
    resetDialog();
    if (employeeInfo?.employeeCode) {
      getUserPermissions(employeeInfo?.employeeCode, employeeInfo.roleNames[0]);
    }
    return showToast("success", "Permission assigned successfully", "Success");
  };

  const updateCheckedValues = (obj: any): any => {
    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        if ("checked" in obj[key]) {
          obj[key].checked = true;
          obj[key].partialChecked = false;
        }
      }
    }
    Object.keys(obj).forEach((item: any) => {
      const parents = findParentNode(treeDataWithoutSelectable, item);
      parents?.forEach((parent) => {
        obj[parent.key] = { checked: true };
      });
    });

    if(employeeInfo?.roleNames[0]!==ADMIN){
      delete obj[indivisualRole];
      delete obj[editRoleInfo];
      delete obj[editRolePermission];
      delete obj[userPermission];
      delete obj[editUserPermission]
    }
   if(!permissionExist){
    delete obj[kpiMappings];
      delete obj[roleMappings];
      delete obj[createRoleMapping];
      delete obj[editRoleMapping];
      delete obj[userMapping];
      delete obj[createUserMapping];
      delete obj[editUserMapping];
   }

    setSelectedKeys({ ...obj });
    return obj;
  };

  const checkIfPermissionExist = async (rowData:any)=>{
    if(rowData && Object.keys(rowData).length>0){
    const isPermissionExist = await checkPermissionExistance(dispatch, {
      roleName: rowData?.roleNames[0],
    });
    setPermissionExist(isPermissionExist)
  }}

  const onPageChange = (event: any) => {
    setlazyState(event);
  };

  //#endregion

  //#region all Templates

  const startToolbar = () => {
    return (
      <div>
        <h4>{localization?.ViewUser || "View User"}</h4>
        <p>{localization?.ViewUserInformation || "View user information"}</p>
      </div>
    );
  };

  const dialogFooter = () => {
    return (
      <div
        style={{
          padding: "1rem 0",
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
      >
        <Button
          label={localization?.Save || "Save"}
          className="kpi-save-button"
          onClick={() => handleSave()}
          style={{ backgroundColor: ThemeColors?.primaryColor }}
        />
      </div>
    );
  };

  const permissionTemplate = (rowData: EmployeeInfoDetails) => {
    return (
      <div className="actionPannel d-flex align-items-center justify-content-center">
     
       <Button icon="pi pi-unlock" onClick={() => showSkillsDialogHandler(rowData)}/>
        {showPermissionDialog && clickedShowPermissionDialog === rowData.id && (
          <Dialog
            header={localization?.Permissions || "Permissions"}
            footer={dialogFooter}
            visible={showPermissionDialog}
            style={{
              width: "35vw",
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            contentStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            onHide={() => {
              if (!showPermissionDialog) return;
              resetDialog();
            }}
          >
            <Tree
              value={tree}
              selectionMode="checkbox"
              selectionKeys={selectedKeys}
              onSelectionChange={(e: any) => {
                updateCheckedValues(e.value);

              }}
              className="w-full"
              expandedKeys={expandedKeys}
              onToggle={(e) => setExpandedKeys(e.value)} // Allows manual toggle by user
              propagateSelectionUp={false}
              style={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
            />
          </Dialog>
        )}
      </div>
    );
  };
  const endToolbar = () => {
    return (
      <div className="endToolbarPerformance">
        <div className="searchBxCont">
          <div className="searchBx border_primary">
            <InputText
              className="w-100"
              type="search"
              placeholder={localization?.Search || "Search"}
              onChange={(e) => {
                setTimeout(() => {
                  setSearchText(e.target.value);
                }, 500);
              }}
            />
            <span className="searchIcon"><i className="pi pi-search"></i></span>
          </div>
        </div>
      </div>
    );
  };

  //#endregion
  // On initial render, expand all nodes by default
  useEffect(() => {
    if (tree) {
      const allExpandedKeys = expandAllNodes(tree);
      setExpandedKeys(allExpandedKeys);
    }
  }, [tree]);

  useEffect(() => {
    getAllEmp();
  }, [searchText,lazyState]);



  useEffect(() => {
    setTree(treeDataWithoutSelectable);
  }, []);



  return (
    <div>
      <Toast ref={toast}/>
      <Toolbar
        start={startToolbar}
        end={endToolbar}
        style={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        className="bg-transparent border-0 mb-3 p-0"
      />
      <div
        style={{ backgroundColor: mode?.backgroundSecondaryColor }}
        className="empolyee_table1 userTable"
      >
        <DataTable
        className="userTable Tableborder_body"
        ref={dt} dataKey="id" 
        value={employee}
        onPage={onPageChange}
        paginator
        first={lazyState.first}
        rows={lazyState.rows}
        totalRecords={totalSubordinates}  
        showGridlines
        lazy
        rowsPerPageOptions={row.rowList}
        currentPageReportTemplate="Total Records {totalRecords}"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        >
          <Column
            field="fullName"
            sortable
            header="Employee Name"
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            className="Name_code"
          ></Column>
          <Column
            field="employeeCode"
            sortable
            header="Employee Code"
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            className="emp_code"
          ></Column>
          <Column
            className="wrap_all1"
            field="emailAddress"
            sortable
            header="Email ID"
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
          ></Column>
        
          {userPermissions.includes(editUserPermission) &&<Column
            className="kpiColumn col_center col_150"
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              textAlign: "center",
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            field="skills"
            header={`${localization?.Permissions || "Permissions"}`}
            body={permissionTemplate}
          ></Column>}
       
        </DataTable>
      </div>
    </div>
  );
};

export default UserTable;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.audit-endtoolbar{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    padding: 0px 20px;
}

.upper-end-toolbar {
    display: flex;
    gap: 10px;
    align-items: center;
}

.show-filters{
    color: #6e6d6d;
    cursor: pointer;
    display: flex;
    gap: 4px;
    align-items: center;
}
.audit-daterange{
    margin: 8px 10px -50px !important;
    position: absolute;
    top: 0;
    z-index: 99;
}
@media screen and (max-width:768px) {
   .audit-daterange{
    margin: 8px !important;
    position: relative;
   }
}
@media screen and (max-width:480px) {
    .lower-end-toolbar input[type="search"] {
        width: 100% !important;
    }
}
`, "",{"version":3,"sources":["webpack://./src/css/components/auditlog.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,qBAAqB;IACrB,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,aAAa;IACb,QAAQ;IACR,mBAAmB;AACvB;AACA;IACI,iCAAiC;IACjC,kBAAkB;IAClB,MAAM;IACN,WAAW;AACf;AACA;GACG;IACC,sBAAsB;IACtB,kBAAkB;GACnB;AACH;AACA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".audit-endtoolbar{\n    display: flex;\n    flex-direction: column;\n    align-items: flex-end;\n    width: 100%;\n    padding: 0px 20px;\n}\n\n.upper-end-toolbar {\n    display: flex;\n    gap: 10px;\n    align-items: center;\n}\n\n.show-filters{\n    color: #6e6d6d;\n    cursor: pointer;\n    display: flex;\n    gap: 4px;\n    align-items: center;\n}\n.audit-daterange{\n    margin: 8px 10px -50px !important;\n    position: absolute;\n    top: 0;\n    z-index: 99;\n}\n@media screen and (max-width:768px) {\n   .audit-daterange{\n    margin: 8px !important;\n    position: relative;\n   }\n}\n@media screen and (max-width:480px) {\n    .lower-end-toolbar input[type=\"search\"] {\n        width: 100% !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

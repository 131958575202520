import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppMode } from "../../data/AppMode";
import { AppModeState } from "../../dtos/common/AppModeState";
import { Themes } from "../../data/Themes";
import { ThemeState } from "../../dtos/common/ThemeState";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { SelectButton } from "primereact/selectbutton";
import "../../css/components/subKpi.css";
import {
  getAllOptionGroupWithMinMaxValue,
  getOptionGroup,
} from "../../service/options/optionService";
import { optionGroupDto } from "../../dtos/options/OptionGroupDto";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  createSubKpiOption,
  deleteSubKpiOption,
  getMaxValueByOptionGroupId,
  getSubKpiOptionsById,
} from "../../service/subkpi/subKpiService";
import { Toast } from "primereact/toast";
import { InputNumber } from "primereact/inputnumber";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputTextarea } from "primereact/inputtextarea";
import { Tooltip } from "primereact/tooltip";
import { OverlayPanel } from "primereact/overlaypanel";
import { Active, InActive } from "../../shared/constant/Common";
import { setSeconds } from "date-fns";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { getKpiById } from "../../service/kpi/kpiService";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createSubKpi, deleteSubKpi } from "../../shared/constant/PermissionVariables";
import { isPermissionGiven } from "../../shared/functions/HasPermission";
import { GetPaginationRowsCount } from "../../shared/functions/GetPaginationRowsCount";
import { GetPaginationRowsInterface } from "../../dtos/common/GetPaginationRows";

export interface LazyState {
  page: number;
  first: number;
  rows: number;
  sortField: string;
  sortOrder: number;
  filters?: string;
}

const SubKpi = () => {
  //#region all variables
  const toast = useRef<Toast>(null);
  const localization = useSelector(
    (state: any) => state.localization.localization
  );

  const [searchText, setSearchText] = React.useState<string>("");
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const statusOptions = [Active, InActive];
  const [statusValue, setStatusValue] = React.useState(statusOptions[0]);
  const [allOptions, setAllOptions] = useState<
    { name: string; code: number }[]
  >([]);
  const [kpiId, setKpiId] = useState<string | null>(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const dt = React.useRef<any>(null);
  const [subKpis, setSubKpis] = useState<any[]>([]);
  const row: GetPaginationRowsInterface = GetPaginationRowsCount();
  const [lazyState, setlazyState] = React.useState({
    page: 0,
    first: 0,
    rows: row.rowCount,
    sortField: "id",
    sortOrder: 1 as any,
  });
  const [selectedData, setSelectedData] = useState<any | null>(null);
  const [deleteSubKpisDialog, setDeleteSubKpisDialog] = useState(false);
  const [deleteSingleSubKpiDialog, setDeleteSingleSubKpiDialog] =
    useState(false);
  const [formErrors, setFormErrors] = useState<any>({});
  const op = useRef<OverlayPanel>(null);
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null);
  const [desc, setDesc] = useState<string>("");
  const [subkpiIndex, setSubKpiIndex] = useState(-1);
  const [optionMaxValue, setOptionMaxValue] = useState<{
    OptionGroupId: number;
    maxOptionValue: string;
  } | null>(null);

  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [optionsWithMinMaxVal, setOptionWithMinMaxVal] = useState<
    optionGroupDto[] | []
  >([]);
  const [subKpiComesFromDB, setSubKpiComesFromDB] = useState<any[]>([]);
  const [kpiInfo, setKpiInfo] = useState<{ Name: string } | null>(null);
  const navigate = useNavigate();
  const userPermissions = useSelector((state: any) => state.permission.permissions);
  //#endregion

  //#region all functions

  const confirmDeleteSelected = () => {
    const hasPermission = isPermissionGiven(deleteSubKpi, userPermissions, dispatch);
    if (!hasPermission) {
      return
    }
    setDeleteSubKpisDialog(true);
  };

  const getKpiInfo = async () => {
    let KPIId = location.pathname.split("/")[2];
    const result = await getKpiById(dispatch, KPIId);
    setKpiInfo(result);
  };

  const addNewRow = () => {
    const hasPermission = isPermissionGiven(createSubKpi, userPermissions, dispatch);
    if (!hasPermission) {
      return
    }
    const newRow = {
      id: subkpiIndex,
      Name: "",
      Description: "",
      Score: 5,
      Status: Active,
      isNew: true,
    };
    setSubKpiIndex((i: number) => i + 1);
    setSubKpis([ ...subKpis,newRow]);
  };

  const onInputChange = async (e: any, field: string, index: number) => {
    const updatedSubKpis = [...subKpis];
    const updatedErrors = { ...formErrors };
    const itemId = updatedSubKpis[index].id;

    if (field === "Score") {
      updatedSubKpis[index][field] = e.value;
    } else if (field === "Description") {
      updatedSubKpis[index][field] = e;
    } else if (field === "Status") {
      updatedSubKpis[index][field] = e.value === "Active" ? true : false;
    } else if (field === "OptionGroupId") {
      updatedSubKpis[index][field] = e.value.code;
      updatedSubKpis[index]["Score"] = e.value.maxOptionValue;

      updatedSubKpis[index].optionGroup = {
        Name: e.value.name.slice(0, e.value.name.indexOf("(") - 1),
      };
      updatedSubKpis[index] = {
        ...updatedSubKpis[index],
        minOptionScore: e.target?.value.minOptionValue,
        maxOptionScore: e.target?.value.maxOptionValue,
      };
    } else {
      updatedSubKpis[index][field] = e.target.value;
    }
    if (updatedErrors[itemId]) {
      delete updatedErrors[itemId][field];
      if (Object.keys(updatedErrors[itemId]).length === 0) {
        delete updatedErrors[itemId];
      }
    }
    setSubKpis(updatedSubKpis);
    setFormErrors(updatedErrors);
  };

  const handleSave = async () => {
    const hasPermission = isPermissionGiven(createSubKpi, userPermissions, dispatch);
    if (!hasPermission) {
      return
    }
    const data = subKpis[0]; // Get the first row
    const isValidArr = validateArray(subKpis);
    if (isValidArr[0] === true && isValidArr[1] === true) {
      const updatedData: any[] = subKpis.map((item: any, index: number) => {
        if (item.isNew === true) {
          const {
            isNew,
            optionGroup,
            createdAt,
            updatedAt,
            deletedAt,
            Status,
            Name,
            Description,
            ...rest
          } = item;
          let isactive;

          if (Status === Active) {
            isactive = Status === "Active" ? true : false;
          } else {
            isactive = Status;
          }

          return {
            ...rest,
            IsUpdated: false,
            KPIId: parseInt(kpiId!),
            Status: isactive,
            Name: Name.trim(),
            Description: Description.trim(),
          };
        } else {
          const {
            optionGroup,
            createdAt,
            updatedAt,
            deletedAt,
            Status,
            Name,
            Description,
            ...rest
          } = item;
          let isactive;
          if (Status === Active) {
            isactive = Status === "Active" ? true : false;
          } else {
            isactive = Status;
          }
          const originalSubKpi = subKpiComesFromDB.find(
            (subKpi) => subKpi.id === item.id
          );
          if (
            item.Name === originalSubKpi.Name &&
            item.Description === originalSubKpi.Description &&
            item.Status === originalSubKpi.Status &&
            item.Score === originalSubKpi.Score &&
            item.OptionGroupId === originalSubKpi.OptionGroupId
          ) {
            return {
              ...rest,
              IsUpdated: false,
              Status: isactive,
              Name: Name.trim(),
              Description: Description.trim(),
            };
          } else {
            return {
              ...rest,
              IsUpdated: true,
              Status: isactive,
              Name: Name.trim(),
              Description: Description.trim(),
            };
          }
        }
      });

      const nameCounts: any = {};
      let hasDuplicates = false;

      for (const obj of updatedData) {
        const name = obj.Name;

        // Count occurrences
        if (nameCounts[name]) {
          nameCounts[name]++;
        } else {
          nameCounts[name] = 1;
        }
      }

      for (const count of Object.values(nameCounts)) {
        if ((count as number) > 1) {
          hasDuplicates = true;
          break;
        }
      }
      if (hasDuplicates) {
        return showErrorInfo("Sub-kPI's with same name can't be created");
      }

      const isFirstTimeSubKpiCreation = updatedData.every(
        (subkpi) => subkpi.id < 0 && subkpi.Status === false
      );
      if (isFirstTimeSubKpiCreation) {
        return showErrorInfo(
          "For a single KPI minimum active subkpi count is 1 so you can't create all inactive subkpis."
        );
      }

      const result = await createSubKpiOption(dispatch, updatedData);
      if (result.error) {
        showErrorInfo(result.message);
      } else {
        showSuccessInfo("Sub-KPI created successfully");
      }
      await dataToShowOnTable();
    } else if (isValidArr[0] === false && isValidArr[1] === true) {
      showErrorInfo("Please provide required fields to create sub-KPI");
    } else {
      showErrorInfo("Any option value can't be more than score");
    }
  };

  const hideDeleteSubKpisDialog = () => {
    setDeleteSubKpisDialog(false);
  };

  const deleteSelectedSubKpis = async () => {
    const deleteId: number[] = [];

    if (selectedData?.length !== 0) {
      selectedData?.forEach((subkpi: any) => {
        if (subkpi.isNew) {
          // let subKPIS = subKpis.filter((subkpitemp) => subkpitemp !== subkpi);
          // setSubKpis(subKPIS);
        } else {
          deleteId.push(subkpi.id);
        }
      });

      const isAllSubkpiNewLyCreated = selectedData.every(
        (subkpi: any) => subkpi.isNew === true
      );
      if (!isAllSubkpiNewLyCreated) {
        const deletedMsg = await deleteSubKpiOption(
          deleteId,
          parseInt(kpiId as string)
        );
        if (deletedMsg?.response?.data?.Error) {
          setDeleteSubKpisDialog(false);
          showErrorInfo(deletedMsg?.response?.data?.Error);
          return;
        } else {
          setDeleteSubKpisDialog(false);
          showSuccessInfo(deletedMsg?.Result);
        }
      } else {
        setDeleteSubKpisDialog(false);
        showSuccessInfo("Sub KPI deleted successfully!");
      }
      await dataToShowOnTable();

      setSelectedData(null);
    }
  };

  const accept = async (rowData: any, rowIndex: number) => {
    const res: any = await confirmDeleteSubKpi(rowData, rowIndex);
  };

  const reject = () => {
    toast.current?.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };

  const confirmDeleteSingleSubKpi = (rowData: any, rowIndex: number) => {
    const hasPermission = isPermissionGiven(deleteSubKpi, userPermissions, dispatch);
    if (!hasPermission) {
      return
    }
    confirmDialog({
      message: `Do you really want to delete this subkpi?`,
      header: "Delete Confirmation",
      icon: "",
      className: "del_modal",
      headerStyle: {
        backgroundColor: mode?.backgroundSecondaryColor,
        color: mode?.color,
      },
      style: {
        width: "350px",
        backgroundColor: mode?.backgroundSecondaryColor,
        color: mode?.color,
      },
      contentStyle: {
        backgroundColor: mode?.backgroundSecondaryColor,
        color: mode?.color,
      },
      acceptClassName: "bg-primary",
      accept: () => accept(rowData, rowIndex),
      reject,
    });
  };

  const confirmDeleteSubKpi = async (rowData: any, rowIndex: number) => {
    let deleteId = [];
    if (subKpis[rowIndex].isNew) {
      let subKPIS = subKpis.filter((val, i) => i !== rowIndex);
      setSubKpis(subKPIS);
    } else {
      deleteId.push(rowData.id);

      const deletedMsg = await deleteSubKpiOption(
        deleteId,
        parseInt(kpiId as string)
      );
      if (deletedMsg?.response?.data?.Error) {
        showErrorInfo(deletedMsg?.response?.data?.Error);
        return;
      } else {
        showSuccessInfo(deletedMsg?.Result);
      }

      await dataToShowOnTable();
      setSelectedData(null);
    }
  };

  const handleStatusValue = (e: any, field: string, index: number) => {
    if (e.value === null) {
      setStatusValue((prev) => prev);
    } else {
      setStatusValue(e.value);
      onInputChange(e, field, index);
    }
  };

  const handleOptionChange = async (e: any, field: string, index: number) => {
    onInputChange(e, field, index);
  };

  const showErrorInfo = (msg: string) => {
    toast.current?.show({
      severity: "error",
      summary: "Error",
      detail: msg,
      life: 3000,
    });
  };
  const showSuccessInfo = (msg: string) => {
    toast.current?.show({
      severity: "success",
      summary: "Success",
      detail: msg,
      life: 3000,
    });
  };

  const getAllOptionGroupData = async () => {
    const result: optionGroupDto[] = await getAllOptionGroupWithMinMaxValue(
      dispatch
    );

    setOptionWithMinMaxVal(result);
    let fetchOptionNames = result.map((option) => {
      return {
        name: option.Name + " " + `( ${option.minScore}-${option.maxScore} )`,
        code: option.id,
        maxOptionValue: option.maxScore,
        minOptionValue: option.minScore,
      };
    });

    let KPIId = location.pathname.split("/")[2];
    setKpiId(KPIId);
    setAllOptions(fetchOptionNames);
  };

  const validateArray: any = (array: any) => {
    let isValid: any = true;
    const errors: any = {};
    let isScoreValid = true;

    array.forEach((item: any, index: any) => {
      if (!item.Name || item.Name.trim() === "") {
        errors[item.id] = { ...errors[item.id], Name: true };
        isValid = false;
      }
      if (!item.OptionGroupId) {
        errors[item.id] = { ...errors[item.id], OptionGroupId: true };
        isValid = false;
      }
      if (!item.Score) {
        errors[item.id] = { ...errors[item.id], Score: true };
        isValid = false;
      }
      if (item.Score < 0 || item.Score > 100) {
        errors[item.id] = { ...errors[item.id], Score: true };
        isValid = false;
      }
      if (item.maxOptionScore > item.Score) {
        isValid = false;
        isScoreValid = false;
      }
    });

    setFormErrors(errors);
    return [isValid, isScoreValid];
  };
  const saveDesc = () => {
    onInputChange(desc, "Description", selectedRowId!);
    setDesc("");
    op.current?.hide();
  };

  const dataToShowOnTable = async () => {
    let KPIId = location.pathname.split("/")[2];
    const inputForSubKpis = {
      kpiId: KPIId,
      SortBy: lazyState.sortOrder === 1 ? "ASC" : "DESC",
      SortProperty: lazyState.sortField,
      Filter: searchText,
    };
    const res = await getSubKpiOptionsById(dispatch, inputForSubKpis);
    const maxId = res.length > 0 ? Math.max(...res.map((subkpi:any) => subkpi.id)) : 0;
    const subkpiIndex = maxId + 1;
    setSubKpiIndex(subkpiIndex)
    setSubKpiComesFromDB(res);
    await getAllOptionGroupData();
    setSubKpis(res);
  };

  const onSort = (event: any) => {
    setlazyState((prev: LazyState) => {
      const newState: LazyState = {
        ...prev, // Copy all properties from the previous state
        sortOrder: event.sortOrder, // Update sortOrder
        sortField: event.sortField, // Update sortField
      };
      return newState;
    });
  };
  //#region all Templates
  const nameTemplate = (field: string, index: number, rowData: any) => {
    return (
      <InputTextarea
        value={subKpis[index][field]}
        onChange={(e) => onInputChange(e, field, index)}
        rows={5}
        cols={20}
        style={{
          width: "100%",
          borderColor: formErrors[rowData.id]?.Name ? "red" : undefined,
          borderWidth: formErrors[rowData.id]?.Name ? "2px" : undefined,
        }}
      />
    );
  };

  const optionTemplate = (field: string, index: number, rowData: any) => {
    return (
      <Dropdown
        value={{
          name:
            subKpis[index].optionGroup?.Name +
            " " +
            `( ${subKpis[index].minOptionScore}-${subKpis[index].maxOptionScore} )`,
          code: subKpis[index].OptionGroupId,
          maxOptionValue: subKpis[index].maxOptionScore,
          minOptionValue: subKpis[index].minOptionScore,
        }}
        onChange={(e: any) => handleOptionChange(e, field, index)}
        options={allOptions}
        optionLabel="name"
        className="w-full kpi_dropdown"
        name="OptionGroup"
        style={{
          borderColor: formErrors[rowData.id]?.OptionGroupId
            ? "red"
            : undefined,
          borderWidth: formErrors[rowData.id]?.OptionGroupId
            ? "2px"
            : undefined,
        }}
        panelStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
      />
    );
  };

  const statusButtonTemplate = (field: string, index: number) => {
    return (
      <div className="status-button-container">
        <SelectButton
          value={subKpis[index].Status ? Active : InActive}
          onChange={(e) => handleStatusValue(e, field, index)}
          options={statusOptions}
          className="status-button"
        />
      </div>
    );
  };

  const endToolbar = () => {
    const isSubKpisEmpty = subKpis.length === 0;
    const isSelectedDataEmpty = !selectedData || selectedData.length === 0;

    return (
      <div className="endToolbar d-flex flex-wrap gap-3">
        <div className="">
          <div className="d-flex flex-wrap flex-md-nowrap gap-2">
            <Button
              className="justify-content-center"
              label={
                localization?.back || (
                  <>
                    <FontAwesomeIcon icon={faArrowLeft} /> Back
                  </>
                )
              }
              onClick={() => navigate("/kpi")}
              style={{
                width: "85px",
                backgroundColor: ThemeColors?.primaryColor,
                border: "none",
              }}
            />
            {userPermissions.includes(createSubKpi) && <Button
              className="justify-content-center"
              label={localization?.Add || "Add"}
              onClick={addNewRow}
              style={{
                width: "85px",
                backgroundColor: ThemeColors?.primaryColor,
                border: "none",
              }}
            />}
            {userPermissions.includes(createSubKpi) && <Button
              className="justify-content-center"
              label={localization?.Save || "Save"}
              style={{
                width: "85px",
                backgroundColor: "#008000",
                border: "none",
              }}
              onClick={handleSave}
              disabled={isSubKpisEmpty}
            />
            }
            {userPermissions.includes(deleteSubKpi) && <Button
              className="justify-content-center"
              label={localization?.Delete || "Delete"}
              style={{
                width: "85px",
                backgroundColor: "rgb(216, 3, 0)",
                border: "none",
              }}
              onClick={confirmDeleteSelected}
              disabled={isSubKpisEmpty || isSelectedDataEmpty}
            />}
          </div>
        </div>



        <div className="searchBxCont">
          <div className="searchBx border_primary">
            <InputText
              className="w-100"
              type="search"
              placeholder={localization?.Search || "Search"}
              onChange={(e) => {
                setTimeout(() => {
                  setSearchText(e.target.value);
                }, 500);
              }}
            />
            <span className="searchIcon"><i className="pi pi-search fs-4"></i></span>
          </div>
        </div>

      </div>
    );
  };

  const deleteSubKpisDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteSubKpisDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedSubKpis}
      />
    </React.Fragment>
  );

  const removeButtonTemplate = (rowData: any, rowIndex: number) => {

    return (
      <div className="status-button-container subkpi_delete_btn ">
        <Button
          className="bg-transparent border-0"
          icon="pi pi-trash"
          onClick={
            () => confirmDeleteSingleSubKpi(rowData, rowIndex)
            // confirmDeleteSubKpi(rowData, rowIndex)
          }
        />
      </div>
    );
  };

  const startToolbar = () => {
    return (
      <div className="subKpi-heading">
        <h4>{kpiInfo?.Name}</h4>
      </div>
    );
  };

  const renderTooltipIcon = (field: string, index: number) => {
    return (
      <>
        <Tooltip target=".pi-info-circle" />
        <span className="infoIcon">
          <i
            className="pi pi-info-circle"
            onClick={(e) => {
              setSelectedRowId(index);
              setDesc(subKpis[index][field]);
              op.current?.toggle(e);
            }}
            data-pr-tooltip={subKpis[index][field]}
            data-pr-position="right"
            data-pr-at="right+5 top"
            data-pr-my="left center-2"
          ></i>
        </span>

      </>
    );
  };

  //#endregion

  useEffect(() => {
    const updatedSubKpis = subKpis.map((subKpi) => {
      const matchedOptionGroup = optionsWithMinMaxVal.find(
        (item) => item.id === subKpi.OptionGroupId
      );
      return {
        ...subKpi,
        maxOptionScore: matchedOptionGroup?.maxScore,
        minOptionScore: matchedOptionGroup?.minScore,
      };
    });
    setSubKpis(updatedSubKpis);
  }, [optionsWithMinMaxVal]);

  useEffect(() => {
    dataToShowOnTable();
  }, [searchText, lazyState]);

  useEffect(() => {
    setFormErrors({});
    getKpiInfo();
  }, []);

  useEffect(() => {
    if (subKpis.length > 0) {
      setTimeout(() => {
        const tableBody = dt.current?.getTable().querySelector("tbody");
        const lastRow = tableBody?.lastElementChild;
        lastRow?.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }, 100);
    }
  }, [subKpis]);

  return (
    <div>
      <Toast ref={toast} />
      <ConfirmDialog />
      <Toolbar
        className="sub_kpi_toolbar1 d-flex gap-1 flex-wrap bg-transparent border-0 p-0 mb-3 "
        start={startToolbar}
        end={endToolbar}
        style={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
      />
      <div
        // style={{ backgroundColor: mode?.backgroundSecondaryColor }}
        className="kpi_tabile_res main_kpi_table border_primary cs_card_shadow overflow-hidden"
      >
        <DataTable
          className="audit-log-table subKpiTable"
          ref={dt}
          value={subKpis}
          onSort={onSort}
          sortOrder={lazyState.sortOrder}
          sortField={lazyState.sortField}
          selection={selectedData}
          onSelectionChange={(e: any) => {
            if (Array.isArray(e.value)) {
              setSelectedData(e.value);
            }
          }}
          selectionMode="checkbox"
          dataKey="id"
        >
          <Column
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            selectionMode="multiple"
            className="col_center"
            exportable={false}
            style={{ width: "5%" }}
          ></Column>
          <Column
            className="kpiColumn"
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            field="Name"
            header={localization?.SubKpiName || "Sub-KPI Name"}
            sortable
            body={(rowData, { rowIndex }) =>
              nameTemplate("Name", rowIndex, rowData)
            }
            style={{ width: "20%", color: mode?.color }}
          ></Column>
          <Column
            className="kpiColumn"
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            field="OptionGroupId"
            header={localization?.SelectAnOption || "Select an option"}
            body={(rowData, { rowIndex }) =>
              optionTemplate("OptionGroupId", rowIndex, rowData)
            }
          ></Column>
          <Column
            field="Description"
            header={localization?.Description || "Description"}
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            body={(rowData, { rowIndex }) =>
              renderTooltipIcon("Description", rowIndex)
            }
            className="text-center col_center"
            style={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
          />

          <Column
            className="kpiColumn col_center col_150"
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              textAlign: "center",
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            field="Status"
            body={(rowData, { rowIndex }) =>
              statusButtonTemplate("Status", rowIndex)
            }
            header={localization?.Status || "Status"}
          ></Column>
          {userPermissions.includes(deleteSubKpi) && <Column
            className="kpiColumn col_center col_150"
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              textAlign: "center",
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            field="Remove"
            body={(rowData, { rowIndex }) =>
              removeButtonTemplate(rowData, rowIndex)
            }
            header={localization?.Remove || "Remove"}
          ></Column>}
        </DataTable>
      </div>

      {/* diaglog boxes */}

      <Dialog
        visible={deleteSubKpisDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteSubKpisDialogFooter}
        onHide={hideDeleteSubKpisDialog}
        draggable={false}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {<span>Are you sure you want to delete the selected sub-kpis?</span>}
        </div>
      </Dialog>

      <OverlayPanel
        ref={op}
        style={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        onHide={() => setDesc("")}
      >
        <div className="align-center">
          <InputTextarea
            className="custom-textarea"
            value={desc}
            onChange={(e) => {
              setDesc(e.target.value);
            }}
          />
          <div>
            <Button label={localization?.Save || "Save"} onClick={saveDesc} />
          </div>
        </div>
      </OverlayPanel>
    </div>
  );
};

export default SubKpi;

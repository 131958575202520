import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import "../../css/components/assignGoal.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Themes } from "../../data/Themes";
import { useDispatch, useSelector } from "react-redux";
import { ThemeState } from "../../dtos/common/ThemeState";
import { AppModeState } from "../../dtos/common/AppModeState";
import { AppMode } from "../../data/AppMode";
import { getSubOrdinatesByStatus } from "../../service/goals/goalsService";
import { SubOrdinateDto } from "../../dtos/goals/SubOrdinatesDto";

import { active } from "../../shared/constant/Common";

import { Toast } from "primereact/toast";

import { MultiSelect } from "primereact/multiselect";
import {
  createSkill,
  getAllSkills,
  getNotAssignedSkillsByEmp,
} from "../../service/skill/skillServices";
import { SkillDto } from "../../dtos/skill/SkillsDto";
import { createUserSkill } from "../../service/userSkill/userSkillServices";
import ModifiedMultiselect from "../modifiedMultiselect/ModifiedMultiselect";
import { GoalSkillDto } from "../../dtos/goalSkill/GoalSkillDto";
import { UserSkillDto } from "../../dtos/userSkill/UserSkillDto";

interface AssignGoalsProps {
  showAssignSkills: boolean;
  setShowAssignSkills: React.Dispatch<React.SetStateAction<boolean>>;
  getAllEmp: () => void;
}

const AssignSkillDialog: React.FC<AssignGoalsProps> = ({
  showAssignSkills,
  setShowAssignSkills,
  getAllEmp,
}) => {
  //#region all Variables
  const localization = useSelector(
    (state: any) => state.localization.localization
  );
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const dispatch = useDispatch();
  const [subOrdinates, setSubOrdinates] = useState<any>();
  const [selectedSubordinate, setSelectedSubordinate] =
    useState<SubOrdinateDto | null>(null);
  const [selectedSkills, setSelectedSkills] = useState<SkillDto[] | []>([]);

  const [errors, setErrors] = useState<{
    employeeName: boolean;
    skill: boolean;
  }>({ employeeName: false, skill: false });
  const toast = useRef<Toast>(null);
  const [allSkills, setAllSkills] = useState<SkillDto[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  //#endregion

  //#region all Functions

  

  const getAllEmployees = async () => {
    const response = await getSubOrdinatesByStatus(dispatch, {
      Status: active,
    });
    setSubOrdinates(response);
  };

  const showToast = (severity: any, detail: string, summary: string) => {
    toast.current?.show({ severity, summary, detail, life: 3000 });
  };

  const submitUserSkill = async () => {
    if (
      !selectedSubordinate &&
      ((selectedSkills && selectedSkills.length < 1) || selectedSkills === null)
    ) {
      setErrors({ employeeName: true, skill: true });
      showToast(
        "error",
        "Please fill required fields to assign skills",
        "Error"
      );
    } else if (!selectedSubordinate) {
      setErrors({ employeeName: true, skill: false });
      showToast(
        "error",
        "Please fill required fields to assign skills",
        "Error"
      );
    } else if (
      (selectedSkills && selectedSkills.length < 1) ||
      selectedSkills === null
    ) {
      setErrors({ employeeName: false, skill: true });
      showToast(
        "error",
        "Please fill required fields to assign skills",
        "Error"
      );
    } else {
      assignSkill();
      const existingSkills = await getNotAssignedSkillsByEmp(dispatch, {
        empCode: selectedSubordinate.employeeCode,
      });
      const createdSkillsByAdmin = selectedSkills
        .filter(
          (selectedSkill) =>
            !existingSkills.some((skill) => skill.Name === selectedSkill.Name)
        )
        .map((skill) => ({
          Name: skill.Name,
          Details: skill.Details || "", // Assuming Details might be empty, defaulting to an empty string if not provided
        }));
      const skillCreationPromises = createdSkillsByAdmin.map((skill) =>
        createSkill(dispatch, skill)
      );
      await Promise.all(skillCreationPromises);
      showToast("success", "Assigned Skills Successfully", "Success");
      setShowAssignSkills(false);
      resetAssignGoalDialog();
      getAllEmp();

      setInputValue("");
    }
  };

  const resetAssignGoalDialog = () => {
    setSelectedSkills([]);
    setSelectedSubordinate(null);
    setErrors({ employeeName: false, skill: false });
  };

  const getNotAssignedSkills = async () => {
    if (selectedSubordinate) {
      const response = await getNotAssignedSkillsByEmp(dispatch, {
        empCode: selectedSubordinate.employeeCode,
      });
      setAllSkills(response);
    }
  };

  const dataToSendToUserSkill = () => {
    let checkedSkills = selectedSkills.map(skill => ({
      ...skill, // Spread the properties to create a shallow copy of the skill object
    }));
    // let checkedSkills = selectedSkills as any;
    checkedSkills?.forEach((skill: any) => {
      skill.EmployeeCode = selectedSubordinate!.employeeCode;
      skill.GoalName = null;
      skill.IsAcquire = true;
      skill.SkillName = skill.Name;
      delete skill.Name;
      delete skill.id;
    });
    return checkedSkills;
  };

  const assignSkill = async () => {
    let input = dataToSendToUserSkill();
    await createUserSkill(dispatch, input as any);
  };

  //#endregion

  //#region all Templates
  const selectedEmployeeTemplate = (option: SubOrdinateDto, props: any) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.fullName}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const employeeOptionTemplate = (option: SubOrdinateDto) => {
    return (
      <div className="flex align-items-center">
        <div>{option.fullName}</div>
      </div>
    );
  };

  const assignGoalsHeaderTemplate = () => {
    return (
      <h1 className="m-0 p-dialog-title">
        {localization?.AssignGoal || "Assign Skills"}
      </h1>
    );
  };

  const addGoalFooterTemplate = () => {
    return (
      <div className="form-actions">
        <Button
          type="button"
          style={{
            backgroundColor: mode?.backgroundSecondaryColor,
            border: `1px solid ${modeName === "light" ? "#970FFF" : "white"}`,
            color: `${modeName === "light" ? "#970FFF" : "white"}`,
          }}
          onClick={() => {
            setShowAssignSkills(false);
            resetAssignGoalDialog();
          }}
        >
          {localization?.Cancel || "Cancel"}
        </Button>
        <Button type="submit" onClick={submitUserSkill}>
          {localization?.Submit || "Submit"}
        </Button>
      </div>
    );
  };

  //#endregion

  useEffect(() => {
    getNotAssignedSkills();
  }, [selectedSubordinate]);

  useEffect(() => {
    getAllEmployees();
  }, []);

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        className="assign_Goals"
        header={assignGoalsHeaderTemplate}
        footer={addGoalFooterTemplate}
        visible={showAssignSkills}
        style={{
          width: "50vw",
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        contentStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        headerStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        onHide={() => {
          if (!showAssignSkills) return;
          setShowAssignSkills(false);
          resetAssignGoalDialog();
        }}
        draggable={false}
      >
        <div className="assign-goal-form">
          <div className="form-group">
            <label htmlFor="goal-name">
              {localization?.EmployeeName || "Employee Name"}
            </label>
            <div className="dropdown-styles">
              <Dropdown
                value={selectedSubordinate}
                onChange={(e) => {
                  setSelectedSubordinate(e.value);
                  setErrors((prev) => {
                    return { ...prev, employeeName: false };
                  });
                }}
                options={subOrdinates}
                optionLabel="fullName"
                placeholder={
                  localization?.SelectAnEmployee || "Select an Employee"
                }
                valueTemplate={selectedEmployeeTemplate}
                itemTemplate={employeeOptionTemplate}
                filter
                panelStyle={{
                  backgroundColor: mode?.backgroundSecondaryColor,
                  color: mode?.color,
                }}
              />
              {errors.employeeName && (
                <span className="err-msg">*Please enter employee name</span>
              )}
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="duration">
              {localization?.AddGoal || "Skills"}
            </label>

            <div className="dropdown-styles">
              {selectedSubordinate ? (
                <ModifiedMultiselect
                  selectedSkills={selectedSkills}
                  setSelectedSkills={setSelectedSkills}
                  allSkills={allSkills}
                  setAllSkills={setAllSkills}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  errors={errors}
                  setErrors={setErrors}
                />
              ) : (
                <MultiSelect
                  optionLabel="Name"
                  value={selectedSkills}
                  onChange={(e) => setSelectedSkills(e.value)}
                  options={allSkills}
                  placeholder="Select Skills"
                  className="w-full md:w-75rem"
                  disabled
                />
              )}

              {errors.skill && (
                <span className="err-msg">*Please enter skills</span>
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AssignSkillDialog;

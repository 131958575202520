import { UserSkillDto } from "../../dtos/userSkill/UserSkillDto";
import instance, { del, get, patch, post } from "../../utills/BaseService";

export async function createUserSkill(dispatch: any, details: UserSkillDto): Promise<string|null> {
    return await post(dispatch, '/userSkill/createUserSkill', details);
 };
export async function getUniqueSkillWithEmpCount(dispatch: any): Promise<string|{SkillName:string,employeeCount:number}[]|[]> {
    return await get(dispatch, '/userSkill/uniqueSkillsWithEmpCount',null);
 };

 export async function getAllUserSkillsRelatedToGoal(dispatch:any,input:{GoalName:string,EmployeeCode:string}):Promise<{SkillName:string}[]|[]>{
    return await get(dispatch, '/userSkill/getAllUserSkillsOfAGoal',input);
 }
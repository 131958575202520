import React, { useEffect, useState } from "react";
import "../../css/components/assignGoal.css";
import "../../css/components/userGoalInfo.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Themes } from "../../data/Themes";
import { useDispatch, useSelector } from "react-redux";
import { ThemeState } from "../../dtos/common/ThemeState";
import { AppModeState } from "../../dtos/common/AppModeState";
import { AppMode } from "../../data/AppMode";
import { GoalStepsDto } from "../../dtos/goals/GoalsStepsDto";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAward } from "@fortawesome/free-solid-svg-icons";

interface AssignGoalsProps {
  showUserGoalInfo: boolean;
  setShowUserGoalInfo: React.Dispatch<React.SetStateAction<boolean>>;
  selectedGoalSteps: GoalStepsDto[] | [];
  scorePercentage: number;
  selectedGoalName: string;
  selectedGoalDesc: string;
  empProgress: { totalValue: number; totalScore: number };
  allUserSkills: { SkillName: string }[] | [];
}

const UserGoalInfo: React.FC<AssignGoalsProps> = ({
  showUserGoalInfo,
  setShowUserGoalInfo,
  selectedGoalSteps,
  scorePercentage,
  selectedGoalName,
  selectedGoalDesc,
  empProgress,
  allUserSkills,
}) => {
  //#region all Variables
  const localization = useSelector(
    (state: any) => state.localization.localization
  );
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const [scores, setScores] = useState<number[] | []>([]);
  const dispatch = useDispatch();

  //#endregion

  //#region all Functions

  const getButtonColor = (index: number) => {
    const allNull = scores?.every(
      (element) => element === null || element === undefined
    );

    if (allNull) {
      if (index === 0) {
        return "#BFBF00"; // Yellow for the first button
      } else {
        return "gray"; // Gray for the rest
      }
    }

    if (scores[index] != null && scores[index] >= 0) {
      return "#20af16"; // Green
    } else if (
      index > 0 &&
      scores[index - 1] != null &&
      scores[index - 1] >= 0
    ) {
      return "#BFBF00"; // Yellow
    } else {
      return "gray"; // Gray
    }
  };

  const scoreSetter = () => {
    if (selectedGoalSteps === null || selectedGoalSteps === undefined) {
      setScores([]);
    } else {
      const scoreArr = selectedGoalSteps?.map((goal) => {
        return goal?.Value;
      });
      setScores(scoreArr);
    }
  };

  //#endregion

  //#region all Templates
  const assignGoalsHeaderTemplate = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <div className="goal_popup_info">
          <h1 className="mb-0 user-goal-name">{selectedGoalName}</h1>
          {selectedGoalDesc && (
            <p className="user-goal-description">{selectedGoalDesc}</p>
          )}
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="toal_done_work d-flex justify-content-center align-items-center">
            <span className="progress-key">
              {localization?.Progress || "Progress"}:
            </span>
            <span className="progress-value">
              {empProgress && empProgress.totalValue}/
              {empProgress && empProgress.totalScore}
            </span>
          </div>
          <div className="toal_done_work d-flex justify-content-center align-items-center">
            <span className="score-key">{localization?.Score || "Score"}:</span>
            <span className="score-value">{Math.round(scorePercentage)}%</span>
          </div>
        </div>
      </div>
    );
  };

  const addGoalFooterTemplate = () => {
    return (
      <div className="form-actions">
        <Button
          type="button"
          style={{
            backgroundColor: mode?.backgroundSecondaryColor,
            border: `1px solid ${modeName === "light" ? "#970FFF" : "white"}`,
            color: `${modeName === "light" ? "#970FFF" : "white"}`,
          }}
          onClick={() => setShowUserGoalInfo(false)}
        >
          {localization?.Close || "Close"}
        </Button>
      </div>
    );
  };

  //#endregion

  useEffect(() => {
    scoreSetter();
  }, [selectedGoalSteps]);

  return (
    <Dialog
      className="user_goal_dialog"
      header={assignGoalsHeaderTemplate}
      footer={addGoalFooterTemplate}
      visible={showUserGoalInfo}
      style={{
        width: "50vw",
        backgroundColor: mode?.backgroundSecondaryColor,
        color: mode?.color,
      }}
      contentStyle={{
        backgroundColor: mode?.backgroundSecondaryColor,
        color: mode?.color,
      }}
      headerStyle={{
        backgroundColor: mode?.backgroundSecondaryColor,
        color: mode?.color,
      }}
      onHide={() => {
        if (!showUserGoalInfo) return;
        setShowUserGoalInfo(false);
      }}
      draggable={false}
    >
      <div className="goal-form p-0">
        <div className="flex flex-column goal-wrapper">
          {selectedGoalSteps.length > 0 ? (
            <div
              style={{
                width: "100%",
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
            >
              {selectedGoalSteps?.map((elem, i) => {
                const buttonColor = getButtonColor(i);

                return (
                  <>
                    <div className="goal-step">
                      <Button
                        className="justify-content-center border-none"
                        style={{ backgroundColor: buttonColor }}
                      >
                        {i + 1}
                      </Button>

                      <div className="flex flex-column goal-content goal_cont_total">
                        <div className="ug_headding_title justify-content-between">
                          <span
                            style={{
                              backgroundColor: mode?.backgroundSecondaryColor,
                              color: mode?.color,
                            }}
                          >
                            {elem.StepName}
                          </span>
                          <span className="given-score">
                            {localization?.Score || "Score"}: {elem?.Value || 0}
                            /{elem?.Score || 0}
                          </span>
                        </div>
                        <div className="step-description">
                          <div>{elem.StepDescription}</div>
                        </div>
                      </div>
                      {i < selectedGoalSteps.length - 1 && (
                        <div className="goal-connector"></div>
                      )}
                    </div>
                  </>
                );
              })}
              <h5 style={{fontWeight:'700'}}>Skills:</h5>
              <div className="d-flex mr-3">
                {allUserSkills.length > 0 ? (
                  <>
                    {allUserSkills.map((skill) => {
                      return (
                        <div className="avatar-container" style={{marginRight:'14px'}}>
                          <FontAwesomeIcon
                            icon={faAward}
                            className="skill-icon"
                          />
                          <p className="skill-text">{skill.SkillName}</p>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  "No skills available for this employee"
                )}
              </div>
            </div>
          ) : (
            "No goal steps is available"
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default UserGoalInfo;

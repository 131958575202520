import * as react from 'react';
import { Toolbar } from 'primereact/toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { AppMode } from '../../data/AppMode';
import { Themes } from '../../data/Themes';
import { AppModeState } from '../../dtos/common/AppModeState';
import { ThemeState } from '../../dtos/common/ThemeState';
import { Card } from 'primereact/card';
import { RatingsChart } from '../charts/RatingsChart';
import DatePicker from "react-datepicker";
import '../../css/components/yourPerformance.css';
import { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import growingUser from '../../images/Growing-bro.png'
import { Dialog } from 'primereact/dialog';
import { getPerformanceWithKPIByEmployee } from '../../service/performance/PerformanceService';
import { isNotUpcomingDate } from '../../shared/functions/IsNotUpcomingDateFunc';
import { LoggedInUserData } from '../../shared/functions/LoggedInUserData';
import { DashboardInputDto } from '../../dtos/dashboard/DashboardInputDto';
import { ISTToDate } from '../../shared/functions/ISTToDate';
import { Toast } from 'primereact/toast';
import { MainPerformanceDto } from '../../dtos/performance/MainPerformanceDataDto';
import { PerformanceOptionsDto } from '../../dtos/performance/PerformanceOptionsDto';
import { GetPreviousMonth } from '../../shared/functions/GetPreviousMonth';

export const YourPerformance = () => {
    const themeName = useSelector((state: ThemeState) => state.theme.themeName);
    const ThemeColors = Themes.find(th => th.name === themeName);
    const modeName = useSelector((state: AppModeState) => state.theme.appMode);
    const mode = AppMode.find(md => md.name === modeName);
    const [date, setDate] = useState<any>(GetPreviousMonth(new Date));
    const [visibleDialog, setVisibleDialog] = useState<boolean>(false);
    const dispatch = useDispatch();
    const [kpiInfo, setKpiInfo] = useState<string>('');
    const [data, setData] = useState<PerformanceOptionsDto[]>([]);
    const [userName, setUserName] = useState<string>('');
    const toast = useRef<any>(null);
    const [text, setText] = useState<string>('')
    const [avgRating,setAvgRating]=useState<number|null>(null)
    const localization = useSelector(
        (state: any) => state.localization.localization
    );

    //For previous date button 
    const handlePrevMonth = () => {
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        firstDay.setMonth(firstDay.getMonth() - 1);
        setDate(firstDay);
    }

    //For next date button
    const handleNextMonth = () => {
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        firstDay.setMonth(firstDay?.getMonth() + 1);

        //if the date is upcoming date then set it current date
        if (!isNotUpcomingDate(firstDay.toString())) {
            setDate((prev: any) => {
                const newDate = prev;
                return newDate
            })
            toast.current.show({ severity: 'warn', summary: 'Sorry!', detail: "You can't go to the current and upcoming months!", life: 3000 });
            return;
        }

        setDate(firstDay);
    }

    const handleKpiInfo = (kpiInfo: string) => {
        setVisibleDialog(true)
        setKpiInfo(kpiInfo)
    }

    const hideKpiInfoDialog = () => {
        setVisibleDialog(false);
        setKpiInfo('')
    }

    function getFirstAndLastDatesOfMonth(inputDate: Date) {
        const year = inputDate.getFullYear();
        const month = inputDate.getMonth();
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);
        return {
            firstDay,
            lastDay
        };
    }

    const createStartAndEndDate = (input: Date[]) => {
        let dates: DashboardInputDto = {
            startDate: '',
            endDate: ''
        };
        const allStrDates = ISTToDate(input);
        dates = {
            startDate: allStrDates[0],
            endDate: allStrDates[1]
        }
        return dates;
    }

    const getAllData = async () => {
        const isPresentDate = isNotUpcomingDate(date);
        const user = await LoggedInUserData(dispatch);
        setUserName(user?.UserName);

        if (!isPresentDate) {
            setData([]);
            return;
        }
        const loggedInUser = await LoggedInUserData(dispatch);

        const input = {
            submittedDate: ISTToDate(date),
            empCode: loggedInUser?.EmployeeCode,
            loggedInEmpCode: loggedInUser.EmployeeCode,
            roleName: ''
        }

        const res: MainPerformanceDto = await getPerformanceWithKPIByEmployee(dispatch, input);
        setAvgRating(res.AverageRating)

        if (typeof res === 'string') {
            setText(res)
            return
        }

        //if all the data is null then set the kpis blank
        let isRatingNull: boolean[] = [];
        res.KPIs.forEach(kpi => {
            if (kpi.rating === null) {
                isRatingNull.push(true)
            }
        })

        if (isRatingNull.length === res.KPIs.length) {
            setData([])
        }
        else {
            setData(res.KPIs);
        }

        const name = res.EmployeeName.split(' ');

        const avgRating = res.AverageRating / 20

        if (avgRating > 2 && avgRating < 3.5) {
            setText(` Dear ${name[0].charAt(0)?.toUpperCase() + name[0]?.slice(1)}, we've noticed some areas where improvement is needed. While your efforts are appreciated, we encourage you to focus on enhancing your performance. Consistency and attention to detail are crucial for success. Let's work together to set achievable goals and develop a plan for improvement. Your dedication to growth is essential for both personal and team success. We're here to support you every step of the way.`)
        }
        else if (avgRating < 2 && avgRating !== 0) {
            setText(`Dear ${name[0].charAt(0)?.toUpperCase() + name[0]?.slice(1)}, your dedication is valued, but there's room for improvement. We encourage proactive communication, seeking guidance when needed, and focusing on areas identified for growth. Consistency and attention to detail are vital for success. Remember, we're here to support your development and help you reach your full potential.`)
        }
        else if (avgRating === 0) {
            setText(`Dear ${name[0].charAt(0)?.toUpperCase() + name[0]?.slice(1)}, We are unable to rate your performance. Since your KPI ratings are not available. As soon as the KPI ratings are available we will be able to provide valuable comments and suggestions on your performance.`)
        }
        else {
            setText(`Dear ${name[0].charAt(0)?.toUpperCase() + name[0]?.slice(1)}, your outstanding performance continues to elevate our team and organization. Your dedication, innovation, and exceptional results are invaluable assets. Keep up the excellent work, your commitment to excellence is truly inspiring. We appreciate your contributions and look forward to continued success together.`)
        }
    }


    const handleDate = (e: any) => {
        if (!isNotUpcomingDate(e)) {
            setDate((prev: any) => {
                const newDate = prev;
                return newDate
            })
            toast.current.show({ severity: 'warn', summary: 'Sorry!', detail: "You can't go to the current and upcoming months!", life: 3000 });
            return;
        }
        setDate(e);
    }

    const startToolbar = () => {
        return (
            <div>
                <h4>{localization?.YourPerformance || "Your Performance"}</h4>
                <p>{localization?.Watchyourperformancethroughthemonth || "Watch your performance through the month"}</p>
            </div>
        )
    };

    const CustomInput = react.forwardRef(({ value, onClick }: any, ref: any) => (
        <div className="example-custom-input" onClick={onClick} ref={ref}>
            {value}
        </div>
    ));

    const endToolbar = () => {
        return (
            <div className="date-input" >
                <Button icon={<AiOutlineLeft />} onClick={handlePrevMonth} className="date-comp-prev-btn" />
                <DatePicker selected={date} customInput={<CustomInput />} value={date} onChange={handleDate} dateFormat="MMMM yyyy"
                    showMonthYearPicker />
                <Button icon={<AiOutlineRight />} onClick={handleNextMonth} className="date-comp-next-btn" />
            </div>
        )
    }

    react.useEffect(() => {
        getAllData()
    }, [date])

    return (
        <div>
            <Toast ref={toast} />
            <Toolbar start={startToolbar} end={endToolbar} style={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} className='mb-3' />
            <div className='growing-container mb-3' style={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }}>
                <img src={growingUser} />
                <div>{text}</div>
            </div>
            {data.length > 0 && avgRating?
                <div className='main-your-performance-card row'>
                    {data?.map(val => (

                        <div className='col-md-6 p-2 col-xl-4 '><Card className='your-performance-card' style={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }}>
                            <div className='performance-container'>
                                <h3>{val.kpiName}</h3>
                                <div className='chart-container'>
                                    <span className='rating'>{val.rating}%</span>
                                    <RatingsChart rating={val.rating} />
                                </div>
                                <div className='comment'>
                                    {val.review.length > 180 ? <span>{val.review.slice(0, 180)} <span className='read-more' onClick={() => handleKpiInfo(val.review)}>Read More...</span></span> :
                                        (val.review.length < 180 && val.review.length > 0) ? val.review
                                            : 'No comments to Show'}
                                </div>
                                <Button onClick={() => handleKpiInfo((val.kpiInfo && val.kpiInfo.length > 0) ? val.kpiInfo : 'No information to show')} label='KPI Information' className='kpi-info-btn' style={{ backgroundColor: ThemeColors?.primaryColor, border: 'none' }} />
                            </div>
                        </Card></div>

                    ))}
                </div>
                :
                <div className='no-data'>No Performance To Show</div>
            }
            <Dialog header="KPI Information" visible={visibleDialog} style={{ width: '50vw', backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} contentStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} onHide={hideKpiInfoDialog}>
                <p className="m-0" style={{ padding: '1.5rem 0' }}>
                    {kpiInfo}
                </p>
            </Dialog>
        </div>
    )
}

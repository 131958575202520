// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border-none {
  border: none;
}

.user-goal-name {
  font-size: 20px;
  font-weight: 700;
}

.user-goal-description {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.score-info {
  font-size: 35px !important;
  font-weight: 800 !important;
}

.given-score {
  color: #9f53ec;
  font-size: 16px;
  font-weight: bold;
}

.step-description {
  padding: 6px;
  display: flex;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/css/components/userGoalInfo.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,0BAA0B;EAC1B,2BAA2B;AAC7B;;AAEA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,8BAA8B;AAChC","sourcesContent":[".border-none {\n  border: none;\n}\n\n.user-goal-name {\n  font-size: 20px;\n  font-weight: 700;\n}\n\n.user-goal-description {\n  font-size: 14px;\n  font-weight: 500;\n  margin: 0;\n}\n\n.score-info {\n  font-size: 35px !important;\n  font-weight: 800 !important;\n}\n\n.given-score {\n  color: #9f53ec;\n  font-size: 16px;\n  font-weight: bold;\n}\n\n.step-description {\n  padding: 6px;\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

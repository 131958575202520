import { Toolbar } from "primereact/toolbar"
import { useEffect, useState } from "react"
import { CronJobDataDto } from "../../dtos/cronJob/CronJobDataDto"
import { getAllCronJobData, getAllCronJobStatus } from "../../service/cronJob/CronJobServices";
import { useDispatch, useSelector } from "react-redux";
import { CronJobDataInputDto } from "../../dtos/cronJob/CronJobDataInputDto";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import '../../css/components/cronjob.css';
import { AppMode } from "../../data/AppMode";
import { Themes } from "../../data/Themes";
import { AppModeState } from "../../dtos/common/AppModeState";
import { ThemeState } from "../../dtos/common/ThemeState";
import moment from "moment";
import { GetPaginationRowsCount } from "../../shared/functions/GetPaginationRowsCount";
import { GetPaginationRowsInterface } from "../../dtos/common/GetPaginationRows";
import { Dialog } from "primereact/dialog";
import parse from 'html-react-parser';
import { Button } from "primereact/button";
import DOMPurify from "dompurify";

export const CronJobData = () => {
    const [cronJobData, setCronJobData] = useState<CronJobDataDto[]>([]);
    const dispatch = useDispatch();
    const [totalCount, setTotalCount] = useState<number>(0);
    const themeName = useSelector((state: ThemeState) => state.theme.themeName);
    const ThemeColors = Themes.find(th => th.name === themeName);
    const modeName = useSelector((state: AppModeState) => state.theme.appMode);
    const mode = AppMode.find(md => md.name === modeName);
    const row: GetPaginationRowsInterface = GetPaginationRowsCount();
    const [lazyState, setlazyState] = useState<any>({
        page: 0,
        first: 0,
        rows: row.rowCount
    });
    const [visible, setVisible] = useState(false);
    const [clickedItemIndex, setClickedItemIndex] = useState<number | null>(null)


    const getAllCronJob = async () => {
        const input: CronJobDataInputDto = {
            limit: lazyState.rows,
            page: lazyState.page
        }

        const allCronJob = await getAllCronJobData(dispatch, input);
        setCronJobData(allCronJob.data);

        setTotalCount(allCronJob.totalLength)
    }

    const previewTemplate = (rowData: any) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(rowData.MailContent, "text/html");

         // Modify styles dynamically
    doc.querySelectorAll("table").forEach((table) => {
        table.style.marginTop = "0px"; // Adjust margin-top dynamically
        table.style.borderCollapse = "collapse"; // Ensure border collapse for table
        table.style.width = "fit-content"; // Adjust width dynamically
      });
      doc.querySelectorAll("p").forEach((p) => {
        p.style.fontSize = "15px"; // Adjust font size dynamically
      });
      doc.querySelectorAll("th").forEach((th) => {
        th.style.fontSize = "15px"; // Adjust table header font size
        th.style.backgroundColor = "#2E6198"; // Background color for headers
        th.style.color = "white"; // Text color for headers
        th.style.padding = "0.8rem 8rem"; // Padding for header
        th.style.textAlign = "center"; // Text alignment for header
      });
      doc.querySelectorAll("td").forEach((td) => {
        td.style.fontSize = "15px"; // Adjust table data font size
        td.style.padding = "0.8rem 8rem"; // Padding for table data
        td.style.textAlign = "center"; // Text alignment for data cells
        td.style.border = "1px solid #7D7C7C"; // Border style for data cells
      });
  
     
        return <div className="d-flex justify-content-center align-items-center">
            <Button
                className="p-button"
                onClick={() => {
                    setVisible(true);
                    setClickedItemIndex(rowData.id);
                }}
                icon="pi pi-arrow-up-right"
            >
                
            </Button>
            {visible && clickedItemIndex === rowData.id && (
                <Dialog header="Mail Content" visible={visible} style={{ width: '50vw' }} onHide={() => { if (!visible) return; setVisible(false); }}>
                    <p className="m-0">
                        {parse(DOMPurify.sanitize(doc.body))}

                    </p>
                </Dialog>
            )}
        </div>
    }

    const mailContentTemplate = (rowData: any) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(rowData.MailContent, "text/html");

        return (
            <div className="d-flex flex-column mb-3" style={{ width: '100%' }}>
                <p className="mb-3">{doc.body.innerText.trim()}</p>
            </div>
        );
    };




    const onPageChange = (event: any) => {
        setlazyState(event)
    };

    const startToolBar = () => {
        return <div>
            <h4>CronJob Data</h4>
        </div>
    }

    const createdAtBodyTemp = (rowData: any) => {
        return `${moment(rowData.createdAt).format('lll')}`
    }


    useEffect(() => {
        getAllCronJob()
    }, [lazyState])

    return (
        <div className="cronjob_container cronJobData">
            <Toolbar className="bg-transparent border-0 p-0" start={startToolBar} />

            {/* cron job data */}
            <div className="cronJobTable border_primary cs_card_shadow cs_card">
                <DataTable
                    className="performance-table audit-log-table"
                    value={cronJobData}
                    dataKey="id"
                    lazy
                    paginator
                    totalRecords={totalCount}
                    first={lazyState.first}
                    rows={lazyState.rows}
                    onPage={onPageChange}
                    showGridlines
                    rowsPerPageOptions={row.rowList}
                    currentPageReportTemplate="Total Records {totalRecords} "
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown">

                    <Column style={{ minWidth: '12rem' }} bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="EmailId" header="EmailId" ></Column>
                    <Column style={{ minWidth: '12rem' }} bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} body={createdAtBodyTemp} field="createdAt" header="Created Date" ></Column>
                    <Column
                        style={{ minWidth: '12rem' }}
                        bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }}
                        headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }}
                        field="MailContent"
                        header="Mail Content"
                        body={mailContentTemplate}
                    ></Column>
                    <Column
                        style={{ minWidth: '12rem' }}
                        bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }}
                        headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }}
                        field="Preview"
                        header="Preview"
                        body={previewTemplate}
                    ></Column>
                    

                </DataTable>
            </div>




        </div>
    )
}
import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Tooltip } from "primereact/tooltip";
import { OverlayPanel } from "primereact/overlaypanel";
import { InputNumber } from "primereact/inputnumber";
import { useDispatch, useSelector } from "react-redux";
import { AppModeState } from "../../dtos/common/AppModeState";
import { AppMode } from "../../data/AppMode";
import { ThemeState } from "../../dtos/common/ThemeState";
import { Themes } from "../../data/Themes";
import "../../css/components/subKpiOptions.css";
import { OptionDto } from "../../dtos/options/OptionDto";
import { CreateOptionInputDto } from "../../dtos/options/CreateOptionInputDto";
import { Toast } from "primereact/toast";
import { SelectButton } from "primereact/selectbutton";
import {
  createOption,
  deleteOption,
  getAllPaginatedOptionGroupName,
  getOptionGroupById,
  updateOptions,
} from "../../service/options/optionService";
import { optionGroupDto } from "../../dtos/options/OptionGroupDto";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Paginator } from "primereact/paginator";
import { Card } from "primereact/card";
import { Active, InActive } from "../../shared/constant/Common";
import _ from "lodash";
import { Dropdown } from "primereact/dropdown";
const SubKpiOptions = () => {
  //#region all Variables
  const localization = useSelector(
    (state: any) => state.localization.localization
  );
  const actionOverleyRef = useRef<OverlayPanel>(null);
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [visible, setVisible] = useState(false);
  const [desc, setDesc] = useState("");
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null);
  const [subKpiOptions, setSubKpiOptions] = useState<OptionDto[]>([]);
  const [shouldPostOption, setShouldPostOption] = useState(false);
  const [savedOption, setSavedOption] = useState<CreateOptionInputDto | null>(
    null
  );
  const [searchInput, setSearchInput] = useState<string>("");
  // const statusOptions = [Active, InActive];
  const statusOptions = [
    localization?.Active || "Active",
    localization?.InActive || "Inactive",
  ];
  const [statusValue, setStatusValue] = React.useState(statusOptions[0]);
  const [val, setVal] = useState<string>("");
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const op = useRef<OverlayPanel>(null);
  const toast = useRef<Toast>(null);
  const dispatch = useDispatch();
  const [allOptionGroupData, setAllOptionGroupData] = useState<
    optionGroupDto[]
  >([]);
  const [optionToEdit, setOptionToEdit] = useState<number | null>(null);
  const [shouldUpdateOption, setShouldUpdateOption] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10); // Number of items to display per page
  const [totalOptionGroupData, setTotalOptionGroupData] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [groupOptionNameError, setGroupOptionNameError] =
    useState<boolean>(false);
  const [savedOptionInDB, setSavedOptionInDB] = useState<any>({});
  //#endregion
  //#region all Functions
  const handleStatusValue = (e: any) => {
    if (e.value === null) {
      setStatusValue((prev: any) => prev);
    } else {
      setStatusValue(e.value);
    }
  };

  const onOptionNameChange = (e: any) => {
    setVal(e.target.value);
    setGroupOptionNameError(false);
  };
  const onInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    rowData: OptionDto,
    field: keyof OptionDto
  ) => {
    const value = e.target.value;
    const updatedRows = subKpiOptions.map((row) => {
      if (row.id === rowData.id) {
        return { ...row, [field]: value };
      }
      return row;
    });
    setSubKpiOptions(updatedRows);
  };

  const onInputNumberChange = (
    e: any,
    rowData: OptionDto,
    field: keyof OptionDto
  ) => {
    const value = e.value;
    const updatedRows = subKpiOptions.map((row) => {
      if (row.id === rowData.id) {
        return { ...row, [field]: value };
      }
      return row;
    });
    setSubKpiOptions(updatedRows);
  };

  const onCheckboxChange = (
    e: { checked: boolean },
    rowData: OptionDto,
    field: keyof OptionDto
  ) => {
    const checked = e.checked;
    const updatedRows = subKpiOptions.map((row) => {
      if (row.id === rowData.id) {
        return { ...row, [field]: checked };
      }
      return row;
    });
    setSubKpiOptions(updatedRows);
  };

  const deleteRow = (rowData: OptionDto) => {
    if (subKpiOptions.length === 2) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "More than two options needed to delete any option",
        life: 3000,
      });
    } else {
      const updatedRows = subKpiOptions.filter((row) => row.id !== rowData.id);
      setSubKpiOptions(updatedRows);
    }
  };

  const addNewRow = () => {
    const newRow: OptionDto = {
      id: subKpiOptions.length + 1,
      Label: "",
      Value: 0,
      IsActive: false,
      Description: "",
    };
    setSubKpiOptions([...subKpiOptions, newRow]);
  };

  const saveDescription = () => {
    if (selectedRowId !== null) {
      const updatedRows = subKpiOptions.map((row) => {
        if (row.id === selectedRowId) {
          return { ...row, Description: desc };
        }
        return row;
      });

      setSubKpiOptions(updatedRows);
      setSelectedRowId(null);
      setDesc("");
      op.current?.hide();
    }
  };

  const handleCancel = () => {
    setSubKpiOptions([]);
    setVal("");
    setVisible(false);
    setGroupOptionNameError(false);
    setOptionToEdit(null);
    setIsEditClicked(false);
  };

  const postOption = async () => {
    const modifiedOptions = savedOption?.OptionList.map(({ id, ...rest }) => {
      const { Label, Description, ...remainingProps } = rest;
      return {
        Label: Label?.trim(),
        Description: Description?.trim(),
        ...remainingProps,
      };
    });

    const result = await createOption(dispatch, {
      ...savedOption,
      OptionList: modifiedOptions,
    });

    if (
      result.error
    ) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: result.message,
        life: 3000,
      });
    }

    if (result === "Options created successfully") {
      showSuccess();

      setSubKpiOptions([]);
      setVal("");
      setVisible(false);

      setShouldPostOption(false);
      setSavedOption(null);
    }
    await getAllOptionGroupData();

    return result;
  };
  const updateOption = async () => {
    const fetchedOption: any = savedOption?.OptionList[0];

    const modifiedOptions = savedOption?.OptionList.map(({ id, ...rest }) => {
      const { Label, Description, ...remainingProps } = rest;
      return {
        Label: Label?.trim(),
        Description: Description?.trim(),
        ...remainingProps,
      };
    });

    if (
      _.isEqual(savedOptionInDB, {
        ...savedOption,
        OptionList: modifiedOptions,
      })
    ) {
      setSubKpiOptions([]);
      setVal("");
      setVisible(false);
      setIsEditClicked(false);
      setShouldUpdateOption(false);
      setSavedOption(null);
      setSavedOptionInDB({})
      return 

    }

    const result = await updateOptions(dispatch, fetchedOption.OptionGroupId, {
      ...savedOption,
      OptionList: modifiedOptions,
    });

    if (result.error) {
      return toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: result.message,
        life: 3000,
      });
    }

    if (
      result === "Option already exist" ||
      result === "Labels cannot have same name." 
    ) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: result,
        life: 3000,
      });
    } else {
      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: "Option Updated Successfully",
        life: 3000,
      });
      setSubKpiOptions([]);
      setVal("");
      setVisible(false);
      setIsEditClicked(false);
      setShouldUpdateOption(false);
      setSavedOption(null);
    }

    await getAllOptionGroupData();
    return;
  };

  const confirm2 = (id: number) => {
    confirmDialog({
      message: "Do you want to delete this option?",
      header: "Delete Confirmation",
      acceptClassName: "p-button-danger",
      className: "del_modal",
      headerStyle: {
        backgroundColor: mode?.backgroundSecondaryColor,
        color: mode?.color,
      },
      style: {
        width: "350px",
        backgroundColor: mode?.backgroundSecondaryColor,
        color: mode?.color,
      },
      contentStyle: {
        backgroundColor: mode?.backgroundSecondaryColor,
        color: mode?.color,
      },

      accept: () => accept(id),
      reject,
    });
  };

  const accept = async (id: number) => {
    const res = await deleteOption(dispatch, id as unknown as number);
    await getAllOptionGroupData();

    toast.current?.show({
      severity: res.length > 38 ? "warn" : "success",
      summary: "Delete Info",
      detail: res,
      life: 5000,
    });
  };

  const reject = () => {
    toast.current?.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };
  const handleActionClick = async (e: any, id: number) => {
    actionOverleyRef.current?.toggle(e);
    setOptionToEdit(id);
  };
  const handleEditClick = async () => {
    if (optionToEdit !== null) {
      setIsEditClicked(true);
      const result = await getOptionGroupById(
        dispatch,
        optionToEdit.toString()
      );
      const updatedOptionList = result.OptionList.map((option: any) => {
        const { id, ...rest } = option;
        return rest;
      });
      setSavedOptionInDB({ ...result, OptionList: updatedOptionList });
      setVal(result.OptionName);
      setStatusValue(
        result.OptionIsActive == true ? statusOptions[0] : statusOptions[1]
      );
      setSubKpiOptions(result.OptionList);
      openNew();
    }
  };

  const getAllOptionGroupData = async () => {
    const input = {
      Limit: itemsPerPage,
      Page: currentPage,
      Filter: searchInput,
    };
    const result = await getAllPaginatedOptionGroupName(dispatch, input);
    setAllOptionGroupData(result?.List);
    setTotalOptionGroupData(result?.TotalCount);
  };

  const processOptions = (
    options: OptionDto[],
    optionName: string
  ): OptionDto[] | false => {
    // Check if all Name properties in options are empty
    const allNamesEmpty = options.every(
      (option) => option.Label.trim().length === 0
    );

    // If all Name properties are empty and optionName is also empty, return false
    if (allNamesEmpty || optionName.trim().length === 0) {
      return false;
    }

    // If optionName is not empty, filter out options with empty Name properties
    const nonEmptyOptions = options.filter(
      (option) => option.Label.trim().length !== 0
    );

    return nonEmptyOptions;
  };

  const handleSave = () => {
    val.length !== 0
      ? setGroupOptionNameError(false)
      : setGroupOptionNameError(true);
    const valid = validateOptions() && val.length !== 0 ? true : false;

    if (valid && isEditClicked) {
      const filteredData = processOptions(subKpiOptions, val);

      if (filteredData) {
        setSavedOption({
          OptionName: val.trim(),
          OptionList: filteredData,
          OptionIsActive: statusValue === Active ? true : false,
        });
        setShouldUpdateOption(true);
      }
    } else if (valid) {
      const filteredData = processOptions(subKpiOptions, val);
      if (filteredData) {
        setSavedOption({
          OptionName: val.trim(),
          OptionList: filteredData,
          OptionIsActive: statusValue === Active ? true : false,
        });
        setShouldPostOption(true);
      }
    } else {
      setShouldPostOption(false);
    }
  };

  const handlePageChange = (event: any) => {
    setCurrentPage(event.page);
    setItemsPerPage(event.rows);
  };

  const showError = (severity: any, summary: string, detail: string) => {
    return toast.current?.show({
      severity,
      summary,
      detail,
      life: 3000,
    });
  };

  const validateOptions = () => {
    if (subKpiOptions.length < 2) {
      showError("error", "Error", "Please enter atleast two options");
      return false;
    }
    const notValidOptions = subKpiOptions.filter(
      (option) =>
        option.Label.trim().length === 0 ||
        option.Value === undefined ||
        option.Value === null ||
        option.Value < 0 ||
        option.Value > 100
    );
    const isNotExistLabel = notValidOptions.find(
      (option) => option.Label.trim().length === 0
    );
    const isNotExistValue = notValidOptions.find(
      (option) => option.Value === undefined || option.Value === null
    );
    const isValueWithInRamge = notValidOptions.find(
      (option) => option.Value < 0 || option.Value > 100
    );

    if (isNotExistLabel && isNotExistValue) {
      showError("error", "Error", "Please enter label and value");
      return false;
    }

    if (isValueWithInRamge) {
      showError("error", "Error", "Value should not be greater than 100");
      return false;
    }
    if (isNotExistLabel) {
      showError("error", "Error", "Please enter label");
      return false;
    }
    if (isNotExistValue) {
      showError("error", "Error", "Please enter value");
      return false;
    }

    const activeOptions = subKpiOptions.filter((option) => {
      return option.IsActive === true;
    });
    if (activeOptions.length < 2) {
      showError(
        "error",
        "Error",
        "Please set the status to active for atleast two options"
      );
      return false;
    }

    return true;
  };

  const showSuccess = () => {
    toast.current?.show({
      severity: "success",
      summary: "Success",
      detail: "Option Saved Successfully",
      life: 3000,
    });
  };

  //#endregion

  //#region all Templates
  const renderFooter = () => {
    return (
      <div className="align-in-row">
        <Button
          label={localization?.Cancel || "Cancel"}
          icon="pi pi-times"
          onClick={handleCancel}
          className="p-button-danger" // button to clear all the data that we have in ISubKpiOptions state variable
        />
        <Button
          label={localization?.Save || "Save"}
          icon="pi pi-check"
          onClick={handleSave}
          className={mode?.backgroundPrimaryColor}
        />
      </div>
    );
  };

  const startToolbar = () => {
    return (
      <div>
        <h4>{localization?.Options || "Options"}</h4>
      </div>
    );
  };

  const endToolbar = () => {
    return (
      <div className="endToolbar">
        <Button
          icon="pi pi-plus"
          label={localization?.CreateOptions || "Create Option"}
          onClick={openNew}
          className="endtoolbar-button"
          style={{
            backgroundColor: ThemeColors?.primaryColor,
          }}
        />
      </div>
    );
  };

  const renderLabelInput = (rowData: OptionDto) => {
    //changed

    return (
      <InputText
        value={rowData.Label}
        onChange={(e) => onInputChange(e, rowData, "Label")}
      />
    );
  };

  const renderPointInput = (rowData: OptionDto) => {
    return (
      <InputNumber
        value={rowData.Value}
        onValueChange={(e) => onInputNumberChange(e, rowData, "Value")}
        min={0}
      />
    );
  };

  const renderIsActiveCheckbox = (rowData: OptionDto) => {
    return (
      <Checkbox
        checked={rowData.IsActive}
        onChange={(e: any) => onCheckboxChange(e, rowData, "IsActive")}
      />
    );
  };

  const renderTooltipIcon = (rowData: OptionDto) => {
    return (
      <>
        <Tooltip target=".pi-info-circle" />
        <i
          className="pi pi-info-circle"
          onClick={(e) => {
            setSelectedRowId(rowData.id!);
            setDesc(rowData.Description);
            op.current?.toggle(e);
          }}
          data-pr-tooltip={rowData.Description}
          data-pr-position="right"
          data-pr-at="right+5 top"
          data-pr-my="left center-2"
        ></i>
      </>
    );
  };

  const renderDeleteIcon = (rowData: OptionDto) => {
    return (
      <Button
        icon="pi pi-trash"
        className="p-button-danger"
        onClick={() => deleteRow(rowData)}
      />
    );
  };

  const openNew = () => {
    setVisible(true);
  };

  const template = {
    layout:"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
    RowsPerPageDropdown: (options:any) => {
        const dropdownOptions = [
            { label: 5, value: 5 },
            { label: 8, value: 8 },
            { label: 10, value: 10 },
            { label: 20, value: 20 }
        ];

        return (
            <React.Fragment>
                <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
                    Total Records {options.totalRecords}
                </span>
                <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
            </React.Fragment>
        );
    },
  
};



  //#endregion

  //#region all UseEffects
  useEffect(() => {
    getAllOptionGroupData();
  }, [itemsPerPage, searchInput, currentPage]);

  useEffect(() => {
    if (shouldUpdateOption && savedOption) {
      updateOption();
    }
  }, [shouldUpdateOption, savedOption]);

  useEffect(() => {
    if (shouldPostOption && savedOption) {
      postOption();
    }
  }, [shouldPostOption, savedOption]);
  //#endregion

  return (
    <div>
      <Toast ref={toast} />
      {/*Toolbar started*/}
      <Toolbar
        className="mb-3"
        start={startToolbar}
        end={endToolbar}
        style={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
      />

      {/*Table started*/}
      <Card
        style={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
      >
        <div className="option_kpi_container">
          <div className="top-bar">
            <div className="search-container">
              <input
                type="text"
                className="search-input p-inputtext p-component"
                style={{
                  border: "1px solid #dad9d9",
                  backgroundColor: mode?.backgroundSecondaryColor,
                  color: mode?.color,
                }}
                placeholder={`${localization?.Search || "Search"}...`}
                onChange={(e) =>
                  setTimeout(() => {
                    setSearchInput(e.target.value);
                  }, 500)
                }
              />
              <i className="pi pi-search search-icon"></i>
            </div>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th className="col-30">
                    {localization?.Actions || "Actions"}
                  </th>
                  <th className="col-70">{localization?.Name || "Name"}:</th>
                </tr>
              </thead>
              <tbody>
                {(searchInput.length === 0
                  ? allOptionGroupData
                  : allOptionGroupData.filter((option) =>
                      option.Name.toLowerCase().includes(
                        searchInput.toLowerCase()
                      )
                    )
                ).map((option) => (
                  <tr key={option.id}>
                    <td className="col-30">
                      <button
                        className="action-button"
                        onClick={(e) => handleActionClick(e, option.id)}
                        ref={buttonRef}
                        style={{
                          backgroundColor: ThemeColors?.secondaryColor,
                        }}
                      >
                        <i className="pi pi-cog settings-icon"></i>
                        {localization?.Action || "Action"}
                        <i className="pi pi-chevron-down arrow-icon"></i>
                      </button>
                      <OverlayPanel
                        ref={actionOverleyRef}
                        style={{
                          backgroundColor: mode?.backgroundSecondaryColor,
                          color: mode?.color,
                          margin: 0,
                          width: buttonRef.current?.offsetWidth,
                        }}
                      >
                        <div className="overley-item" onClick={handleEditClick}>
                          {localization?.Edit || "Edit"}
                        </div>
                        <div
                          className="overley-item"
                          onClick={() => confirm2(optionToEdit!)}
                        >
                          {localization?.Delete || "Delete"}
                        </div>
                      </OverlayPanel>
                    </td>
                    <td className="col-70">{option.Name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <hr />
        <Paginator
          first={currentPage * itemsPerPage}
          rows={itemsPerPage}
          totalRecords={totalOptionGroupData}
          onPageChange={handlePageChange}
          template={template}
          className="mt-2 pb-0"
        />
      </Card>

      {/* option dialog box started */}
      <ConfirmDialog />
      <Dialog
        header={localization?.CreateAnswerType || "Create Answer Type"}
        visible={visible}
        style={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
          width: "55vw",
        }}
        contentStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        headerStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        onHide={() => {
          setVisible(false);
          setSubKpiOptions([]);
          setVal("");
          setGroupOptionNameError(false);
          setOptionToEdit(null);
          setIsEditClicked(false);
        }}
        footer={renderFooter}
        draggable={false}
      >
        <div className="field">
          <label htmlFor="name" className="font-bold">
            {localization?.GroupOptionName || "Group Option Name"}
            <span className="required-marker">*</span>
          </label>
          <InputText
            value={val}
            onChange={(e) => onOptionNameChange(e)}
            name=" OptionName"
          />
          {groupOptionNameError && (
            <small style={{ color: "red", marginTop: "6px" }}>
              *Group option name is required
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="status" className="font-bold">
            {localization?.OptionStatus || "Option Status"}
          </label>
          <SelectButton
            value={statusValue}
            onChange={(e) => handleStatusValue(e)}
            options={statusOptions}
          />
        </div>
        <div className="card">
          <DataTable
            value={subKpiOptions}
            reorderableColumns
            reorderableRows
            onRowReorder={(e: any) => setSubKpiOptions(e.value)}
            tableStyle={{ minWidth: "50rem" }}
          >
            <Column
              rowReorder
              header={
                <i
                  className="pi pi-plus add_row_btn"
                  onClick={addNewRow}
                  style={{ cursor: "pointer" }}
                ></i>
              }
              style={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
            />
            <Column
              field="Label"
              header={localization?.Label || "Label"}
              body={renderLabelInput}
              style={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
            />
            <Column
              field="Value"
              header={localization?.Value || "Value"}
              body={renderPointInput}
              style={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
            />
            <Column
              field="IsActive"
              header={localization?.Active || "Active"}
              body={renderIsActiveCheckbox}
              className="text-center"
              style={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
            />
            <Column
              field="tooltip"
              header={localization?.Tooltip || "Tooltip"}
              body={renderTooltipIcon}
              className="text-center"
              style={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
            />
            <Column
              field="action"
              header={localization?.Action || "Action"}
              body={renderDeleteIcon}
              className="text-center"
              style={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
            />
          </DataTable>
        </div>
      </Dialog>
      <OverlayPanel
        ref={op}
        style={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
      >
        <div className="align-center">
          <InputTextarea
            className="custom-textarea"
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
          <div>
            <Button
              label={localization?.Save || "Save"}
              onClick={saveDescription}
            />
          </div>
        </div>
      </OverlayPanel>
    </div>
  );
};

export default SubKpiOptions;

import { GetSubKpisWithValueDto } from "../../dtos/subPerformance/GetSubKpisWithValueDto";
import { GetSubKpisWithValueInputDto } from "../../dtos/subPerformance/GetSubKpisWithValueInputDto";
import instance, { del, get, patch, post } from "../../utills/BaseService";

export async function createSubKpiOption(dispatch: any, input: any): Promise<any> {
   return await post(dispatch, '/subKpi', input);
};

export async function getSubKpiOptionsById(dispatch: any, input: any): Promise<any> {
   return await get(dispatch, '/subKpi/getSubKpisByKpiId', input, undefined);
};

export async function getAllSubPerformanceValues(dispatch: any, input: GetSubKpisWithValueInputDto): Promise<GetSubKpisWithValueDto[]> {
   return await post(dispatch, '/subKpi/getAllSubPerformanceValues', input);
};

export async function updateSubKpiOption(dispatch: any, param: number, details: any): Promise<any> {
   return await patch(dispatch, '/subKpi/updateSubKpi', param, details);
};

export async function deleteSubKpiOption(ids: number[], kpiId: number): Promise<any> {
   try {

      const queryString = ids.map(id => `ids=${id}`).join('&');
      const fullUrl = `${'/subKpi/deleteSubKpis'}?kpiId=${kpiId}&${queryString}`;
      const res = await instance.delete(fullUrl);
      return res.data;

   } catch (error) {
      return error
   }
};

export async function getMaxValueByOptionGroupId(OptionGroupId:number): Promise<any> {
   const headerData = {
      access_token: "Bearer " + localStorage.getItem("token")
  }
   return await instance.get( `/subKpi/getMaxValuedOption/${OptionGroupId}`,{
      headers: headerData
  });
};


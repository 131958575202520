import { createSlice } from "@reduxjs/toolkit";

interface State {
    localization:{}
}
interface Action {
    type: string,
    payload: State
}


const localizationSlice = createSlice({
    name: "LocalizationSlice",
    initialState: {
        localization: {}
    },
    reducers: {
        setLocalization(state: State, action: Action) {
            state.localization = action.payload
        }
    }
});

export const { setLocalization } = localizationSlice.actions;
export default localizationSlice.reducer;
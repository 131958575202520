// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assign-to-employees-header {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: flex-start !important;
}

.assign-to-employees-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
}
`, "",{"version":3,"sources":["webpack://./src/css/components/assignToEmployees.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,iCAAiC;EACjC,kCAAkC;EAClC,kCAAkC;AACpC;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,sBAAsB;AACxB","sourcesContent":[".assign-to-employees-header {\n  display: flex !important;\n  flex-direction: column !important;\n  justify-content: center !important;\n  align-items: flex-start !important;\n}\n\n.assign-to-employees-table {\n  width: 100%;\n  border-collapse: separate;\n  border-spacing: 0 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

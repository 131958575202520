import * as React from "react";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { getAllPerformanceWithKPI } from "../../service/performance/PerformanceService";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, AccordionTab } from "primereact/accordion";
import "../../css/components/performance.css";
import { MainPerformanceDto } from "../../dtos/performance/MainPerformanceDataDto";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AppModeState } from "../../dtos/common/AppModeState";
import { AppMode } from "../../data/AppMode";
import { ThemeState } from "../../dtos/common/ThemeState";
import { Themes } from "../../data/Themes";
import {
  AiFillDownCircle,
  AiFillRightCircle,
  AiOutlineLeft,
  AiOutlineRight,
} from "react-icons/ai";
import { SelectButton } from "primereact/selectbutton";
import { Paginator } from "primereact/paginator";
import { forwardRef, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { GetOneUserDto } from "../../dtos/user/GetOneUserDto";
import { LoggedInUserData } from "../../shared/functions/LoggedInUserData";
import { setPath } from "../../store/slices/routePathSlice";
import DatePicker from "react-datepicker";
import Rating from '@mui/material/Rating';
import ReactStars from "react-stars";
import { Dropdown } from "primereact/dropdown";
import { getAllRoleFromKpiMappings } from "../../service/kpiMappings/kpiMappingsService";
import { KpiMappingsRoleDto } from "../../dtos/role/KpiMappingsRoleDto";
import { ISTToDate } from "../../shared/functions/ISTToDate";
import { isNotUpcomingDate } from "../../shared/functions/IsNotUpcomingDateFunc";
import { Toast } from "primereact/toast";
import { setInputs } from "../../store/slices/performanceViewInput";
import { HighStarColor, LowStarColor, LowStarVal, MidStarColor, MidStarVal } from "../../shared/constant/StarValue";
import { GetPreviousMonth } from "../../shared/functions/GetPreviousMonth";
import { getUserRole } from "../../service/user/userService";
import { performanceDate, performanceEmpCode } from "../../shared/constant/Common";
import { performance, roleInRatings } from "../../shared/constant/PermissionVariables";
import { getProfilePicFromTracker } from "../../shared/functions/GetProfilePicFromTracker";

export const PerformanceTable = () => {
  // #region all Variables

  const [data, setData] = React.useState<any>([]);
  const [searchText, setSearchText] = useState<string>("");
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const localization = useSelector(
    (state: any) => state.localization.localization
  );
  const mode = AppMode.find((md) => md.name === modeName);
  const columnOptions: any = [
    { name: localization?.Name || "Name", colName: "EmployeeName" },
    { name: localization?.Rating || "Rating", colName: "AverageRating" },
  ];
  const [sortColumn, setSortColumn] = useState<any>(columnOptions[1]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [itemsPerPage, setItemsPerPage] = useState<number>(8); // Number of items to display per page
  const totalItems = data.length;
  const [totalRecords, setTotalRecords] = useState(0)
  const [currentPage, setCurrentPage] = useState(0);


  const [date, setDate] = useState<any>(GetPreviousMonth(new Date()));
  const [empCode, setEmpCode] = useState<string>()
  const [performanceViewData, setPerformanceViewData] =
    useState<MainPerformanceDto>();
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [allRoles, setAllRoles] = useState<KpiMappingsRoleDto[]>([]);
  const [selectedRole, setSelectedRole] = useState<KpiMappingsRoleDto | null>(
    null
  );
  const [disableSelectBtn, setDisableSelectBtn] = useState<boolean>(false);
  const toast = useRef<any>(null);
  const [role, setRole] = useState('');
  const userPermissions = useSelector((state: any) => state.permission.permissions);

  //#endregion

  // #region all Functions
  const fetchRole = async () => {
    const roleData = await getUserRole(dispatch);
    setRole(roleData);
  }

  const handlePageChange = (event: any) => {
    setCurrentPage(event.page);
    setItemsPerPage(event.rows)
    setActiveIndex(0);
  };

 
  const getAllData = async () => {
    // Fetch logged in user data
    const data: GetOneUserDto = await LoggedInUserData(dispatch);

    // Prepare inputDate for performance query
    const inputDate = {
      submittedDate: ISTToDate(date),
      loggedInEmpCode: data.EmployeeCode,
      roleName: selectedRole === null ? "" : typeof selectedRole?.RoleName === 'undefined' ? "" : selectedRole?.RoleName,
      itemsPerPage,
      currentPage,
      searchText

    };

    // Disable select button while fetching data
    setDisableSelectBtn(true);

    // Fetch performance data with KPI
    const performanceWithKPI: { result: MainPerformanceDto[], totalRecords: number } = await getAllPerformanceWithKPI(dispatch, inputDate);
    setTotalRecords(performanceWithKPI.totalRecords)
    // Filter the data based on the search text
    const filterData = performanceWithKPI?.result.filter((perf) => {
      return perf?.EmployeeName?.toLowerCase().includes(searchText.toLowerCase());
    });
    setData(filterData);
    // Set the filtered data immediately (without waiting for profile pictures)
    //setData(filterData);

    // Handle profile pictures asynchronously in the background
    const profilePicPromises = filterData.map(async (e: any) => {
      const input = {
        EmployeeID: e.EmployeeId,
        EmployeeCode: e.EmployeeCode,
      };

      // Fetch the profile picture
      const image = await getProfilePicFromTracker(dispatch, input);
      e.ProfilePicture = image;
      return e;
    });

    // Use Promise.allSettled to update the data once profile pictures are fetched
    Promise.allSettled(profilePicPromises).then((results) => {
      const updatedData = results.map((result, index) => {
        if (result.status === "fulfilled") {
          filterData[index] = result.value;
        }
        return filterData[index];
      });

      // Update the data with profile pictures added
      setData(updatedData);
    });


    // Re-enable the select button after all operations
    setDisableSelectBtn(false);
  };


  const getAllRoles = async () => {
    const roles = await getAllRoleFromKpiMappings(dispatch);
    const transformedRoles = roles.map((role: { RoleName: string }) => {
      return {
        ...role,
        RoleName: role.RoleName.slice(0, 1).toUpperCase() + role.RoleName.slice(1)
      }
    })
    setAllRoles(transformedRoles);
  };

  const handleSort = () => {
    if (arrow === 'up') {
      const newData = [...data];
      if (sortColumn.colName === "AverageRating") {
        newData.sort((a: MainPerformanceDto, b: MainPerformanceDto) => {
          return a.AverageRating - b.AverageRating;
        });
        setData(newData);
      } else {
        newData.sort((a: MainPerformanceDto, b: MainPerformanceDto) =>
          b.EmployeeName.localeCompare(a.EmployeeName)
        );
        setData(newData);
      }
      setArrow("down");
    }
    else {
      const newData = [...data];
      if (sortColumn.colName === "AverageRating") {
        newData.sort((a: MainPerformanceDto, b: MainPerformanceDto) => {
          return b.AverageRating - a.AverageRating;
        });
        setData(newData);
      } else {
        newData.sort((a: MainPerformanceDto, b: MainPerformanceDto) =>
          a.EmployeeName.localeCompare(b.EmployeeName)
        );
        setData(newData);
      }
      setArrow("up");
    }
  };
  const [arrow, setArrow] = React.useState("up");

  const routePath = localStorage.getItem("lastRoute");

  const handleRoute = (
    path: string,
    mode: string,
    btn: string,
    data: MainPerformanceDto
  ) => {
    dispatch(setInputs({
      mode: mode,
      date: date,
      data: data,
      employeeCode: data.EmployeeCode,
      roleName: selectedRole === null ? "" : typeof selectedRole?.RoleName === 'undefined' ? "" : selectedRole?.RoleName,
    }))
    localStorage.setItem(performanceDate, date)
    localStorage.setItem(performanceEmpCode, data.EmployeeCode)
    localStorage.setItem("lastRoute", path);
    navigate(path);
    dispatch(setPath({ path: path }));
  };

  //handle date input
  const handleDate = (e: any) => {
    //if the date is upcoming date then set it current date
    const today = new Date();
    if (e.getFullYear() > today.getFullYear() || e.getFullYear() === today.getFullYear() && e.getMonth() >= today.getMonth()) {
      setDate((prev: any) => {
        const newDate = prev;
        return newDate;
      });
      toast?.current?.show({
        severity: "warn",
        summary: "Sorry!",
        detail: "You can't go to the current and upcoming months!",
        life: 3000,
      });
      return; // Prevent further actions if the date is in the future
    }
    setDate(e);
  }

  const handlePrevMonth = () => {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    firstDay.setMonth(firstDay.getMonth() - 1);
    setDate(firstDay);
  };

  const handleNextMonth = () => {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    firstDay.setMonth(firstDay?.getMonth() + 1);

   
    if (
      firstDay.getFullYear() > new Date().getFullYear() ||
      (firstDay.getFullYear() === new Date().getFullYear() && firstDay.getMonth() >= new Date().getMonth())
    ) {
      toast.current.show({ severity: 'warn', summary: 'Sorry!', detail: "You can't go to the current and upcoming months!", life: 3000 });
      return;
    }
    setDate(firstDay);
  };

  const handleSortColumn = (e: any) => {
    if (e.value.name === 'Name') {
      const newData = [...data];
      setSortColumn(e.value)
      newData.sort((a: MainPerformanceDto, b: MainPerformanceDto) =>
        a.EmployeeName.localeCompare(b.EmployeeName)
      );
      setData(newData);
      setArrow("up");
    }
    else {
      const newData = [...data];
      setSortColumn(e.value)
      newData.sort((a: MainPerformanceDto, b: MainPerformanceDto) => {
        return b.AverageRating - a.AverageRating;
      });
      setData(newData);
      setArrow("up");
    }
  }

  const getStarColor = (val: number) => {
    const rating = Number((val / 20).toFixed(2));
    if (rating <= LowStarVal) {
      return LowStarColor;
    }

    if (rating > LowStarVal && rating <= MidStarVal) {
      return MidStarColor
    }

    if (rating > MidStarVal) {
      return HighStarColor;
    }
  }

  // #endregion

  // #region all Templates

  const startToolbar = () => {
    return (
      <div>
        <h4>{localization?.RatingsReviews || "Ratings & Reviews"}</h4>
        <p>{localization?.Viewratingsreviewsinformation || "View ratings & reviews information"}</p>
      </div>
    );
  };
  const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <div className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
    </div>
  ));

  const sortTemplate = (option: any) => {
    return option.name;
  };

  const endToolbar = () => {
    return (
      // role dropdown only shows for admin
      < div className="endToolbarPerformance" >
        {/* {(roleOfLoggedInUser !== null && roleOfLoggedInUser === 'admin') && ( */}
        {userPermissions.includes(roleInRatings) && <Dropdown
          value={selectedRole}
          onChange={(e) => setSelectedRole(e.value)}
          resetFilterOnHide={true}
          options={allRoles}
          optionLabel="RoleName"
          filter
          placeholder={localization?.Role || "Role"}
          className="w-full md:w-12rem Etool_dropdown border_primary"
          disabled={disableSelectBtn}
          showClear
          panelStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }}
        />}
        {/* )} */}
        <div className="date-input border_primary flex-fill rounded-3">
          <Button
            icon={<AiOutlineLeft />}
            onClick={handlePrevMonth}
            className="date-comp-prev-btn"
            disabled={disableSelectBtn}
          />
          <DatePicker
            selected={date}
            onChange={handleDate}
            customInput={<CustomInput />}
            dateFormat="MMMM yyyy"
            showMonthYearPicker
          />
          <Button
            icon={<AiOutlineRight />}
            onClick={handleNextMonth}
            className="date-comp-next-btn"
            disabled={disableSelectBtn}
          />
        </div>

        <div className="sortingContainer">
          <SelectButton
            value={sortColumn}
            onChange={handleSortColumn}
            options={columnOptions}
            itemTemplate={sortTemplate}
            optionLabel="colName"
            disabled={disableSelectBtn}
          />
          {arrow === "down" ? (
            <i
              className="pi pi-sort-amount-down-alt sortIcon"
              onClick={handleSort}
            ></i>
          ) : (
            <i
              className="pi pi-sort-amount-up-alt sortIcon"
              onClick={handleSort}
            ></i>
          )}
        </div>
        <div className="searchBxCont">
          <div className="searchBx border_primary">
            <InputText
              className="w-100 border-0"
              type="search"
              placeholder={localization?.Search || "Search"}
              onChange={(e) => {
                setTimeout(() => {
                  setSearchText(e.target.value?.trim());
                }, 1000);
              }}
            />
            <span className="searchIcon"><i className="pi pi-search"></i></span>
          </div>
        </div>
      </div >
    );
  };
  const template = {
    layout: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
    RowsPerPageDropdown: (options: any) => {
      const dropdownOptions = [
        { label: 8, value: 8 },
        { label: 10, value: 10 },
        { label: 20, value: 20 }
      ];

      return (
        <React.Fragment>
          <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
            Total Records {options.totalRecords}
          </span>
          <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
        </React.Fragment>
      );
    },

  };

  // #endregion

  // #region useEffects
  const handleBackButton = () => {
    // This function will be called when the user clicks the back button
    if (location.pathname === "/performance") {
      navigate("/performance");
    }
  };

  useEffect(() => {
    getAllRoles();
    window.addEventListener("popstate", handleBackButton);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  useEffect(() => {
    setSearchText("");
  }, [routePath]);

  useEffect(() => {
    getAllData();
  }, [searchText, date, routePath, selectedRole, itemsPerPage, currentPage]);

  useEffect(() => {
    fetchRole()
  }, [])

  // #endregion

  return (
    <div className="me-2">
      <Toast ref={toast} />
      <div>
        <Toolbar
          start={startToolbar}
          end={endToolbar}
          style={{
            backgroundColor: mode?.backgroundSecondaryColor,
            color: mode?.color,
          }}
          className="performance_toolbar bg-transparent border-0 mb-3 p-0 "
        />

        {userPermissions.includes(performance) &&
          <div

          >
            {data.length > 0 ? (
              <Accordion
                multiple
                onTabChange={(e: any) => setActiveIndex(e.index)}
                activeIndex={activeIndex}
                className="main-performance performanceCont TPE_lightbg border_primary cs_card p-4"
                expandIcon={
                  <AiFillRightCircle

                    size={22}
                  />
                }
                collapseIcon={
                  <AiFillDownCircle

                    size={22} />
                }
              >
                {data
                  ?.map((emp: any) => (
                    <AccordionTab
                      key={emp?.EmployeeCode}
                      className="per-head topAccord cs_card_shadow"
                      headerStyle={{

                      }}
                      header={
                        <div>
                          <div className="header-performance col p-0" >
                            <div className="reviewBtn">
                              {emp.IsSubmitted ? (
                                <Button
                                  label="View"
                                  className="per-view-btn"
                                  onClick={() =>
                                    handleRoute(
                                      `/performance/${emp.EmployeeCode}`,
                                      "View",
                                      "Review",
                                      emp
                                    )
                                  }
                                  outlined

                                />
                              ) : (
                                <Button
                                  label="Review"
                                  className="per-view-btn"
                                  onClick={() =>
                                    handleRoute(
                                      `/performance/${emp.EmployeeCode}`,
                                      "Review",
                                      "View",
                                      emp
                                    )
                                  }
                                  outlined
                                // style={{ color: "white" }}
                                />
                              )}
                            </div>


                            <div className="employees-picture me-5"><img src={emp.ProfilePicture || "https://i.pinimg.com/736x/64/81/22/6481225432795d8cdf48f0f85800cf66.jpg"} alt="" /></div>

                          </div>

                          <div className="RatingbottomPart pt-2 mt-2">
                            <div>
                              <div className="employee-name w-100 d-flex align-items-start my-1 py-2">
                                {" "}
                                <div className="flex-fill d-flex">
                                  <span className="text-primary d-inline-flex ">{emp?.EmployeeName}</span> ({emp.EmployeeCode})
                                </div>

                              </div>
                              <div className="main-ratings">
                                <span className="AverageRating">
                                  {Number((emp.AverageRating / 20).toFixed(2))}
                                </span>

                                <Rating
                                  name="half-rating-read"
                                  style={{ color: getStarColor(Number(emp.AverageRating)) }}
                                  value={Number((emp.AverageRating / 20).toFixed(2))}
                                  precision={0.5} readOnly />

                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    >
                      {emp?.KPIs?.map((per: any) => (

                        <div
                          id={per?.kpiId}
                          key={per?.kpiId}
                          className="performance-tab"
                          style={{ color: mode?.color }}
                        >
                          <div className="sub-ratings">
                            <div className="kpi-name">{per?.kpiName}</div>
                            <div className="main-ratings align-items-center">
                              <span className={`${per.rating === 0 ? "text-danger" : ''}`}>
                                {per.rating ? `(${Number((per?.rating / 20).toFixed(2))})` : "(0)"}
                              </span>

                              <Rating
                                name="half-rating-read"
                                className="top_sub_ratings"
                                style={{ color: getStarColor(per?.rating) }}
                                value={Number((per?.rating / 20).toFixed(2))}
                                precision={0.5} readOnly />
                            </div>
                          </div>
                        </div>
                      ))}
                    </AccordionTab>
                  ))}
              </Accordion>
            ) : (
              <div className="no-data">No records to show</div>
            )}

            <Paginator
              first={currentPage * itemsPerPage}
              rows={itemsPerPage}
              totalRecords={totalRecords}
              onPageChange={handlePageChange}
              template={template}
              className="mt-2"
            />
          </div>}
      </div>
    </div>
  );
};

import { createSlice } from "@reduxjs/toolkit";

interface State {
    themeName: string,
    appMode:string
}
interface Action {
    type: string,
    payload: State
}
const storedTheme = localStorage.getItem('theme');
const storedMode = localStorage.getItem('Mode');
const themeSlice = createSlice({
    name: "ThemeSlice",
    initialState: {
        themeName:  storedTheme || 'violet',
        appMode: storedMode || 'light'
    },
    reducers: {
        changeTheme(state: State, action: Action) {
            state.themeName = action.payload.themeName
            localStorage.setItem('theme',  action.payload.themeName)
        },
        changeMode(state:State,action:Action){
            state.appMode = action.payload.appMode
            localStorage.setItem('Mode', action.payload.appMode
            )
        }
    }
});

export const { changeTheme , changeMode} = themeSlice.actions;
export default themeSlice.reducer;
import instance, { get, patch, post } from "../../utills/BaseService";

export async function createUserGoals(dispatch: any, details:any): Promise<any> {
    return await post(dispatch, '/userGoal/createUserGoals', details);
 };


 export async function getAllGoals(dispatch: any,input:any): Promise<any> {
    return await get(dispatch, 'userGoal/getAllAssignedGoalUsers', input);
 };
 export async function getAllUserGoals(dispatch: any,input:any): Promise<any> {
    return await get(dispatch, '/goal/getAllOneUserGoals', input);
 };
 export async function getAllGoalsWithEmployeeCount(dispatch: any): Promise<any> {
    return await get(dispatch, '/goal/getAllGoalsWithEmployeeCount',null);
 };

 export async function updateGoal(dispatch: any, param: number, details: any): Promise<any> {
    return await patch(dispatch, '/userGoal/updateUserGoals', param, details);
 };
import { CreateOptionInputDto } from "../../dtos/options/CreateOptionInputDto";
import { EditOptionDto } from "../../dtos/options/EditOptionDto";
import instance, { del, get, patch, post } from "../../utills/BaseService";

export async function createOption(dispatch: any, input: any): Promise<any> {
   return await post(dispatch, '/option/createOptions', input);
};

export async function getOptionGroup(dispatch: any): Promise<any> {
   return await get(dispatch, '/optionGroup', null, undefined);
};
export async function getAllOptionGroupWithMinMaxValue(dispatch: any): Promise<any> {
   return await get(dispatch, '/optionGroup/getAllOptionGroupWithMinMaxVal', null, undefined);
};
export async function getAllPaginatedOptionGroupName(dispatch: any, input: any): Promise<any> {
   return await get(dispatch, '/optionGroup/getAllPaginatedOptionGroupName', input, undefined);
};
export async function getOptionGroupById(dispatch: any, pathEndpoint: string): Promise<any> {
   return await get(dispatch, '/option/getByOptionGroupId', null, pathEndpoint);
};

export async function updateOptions(dispatch: any, param: number, details: any): Promise<any> {
   return await patch(dispatch, '/option/updateOptions', param, details);
};

export async function deleteOption(dispatch: any, param: number): Promise<any[]> {
   return await del(dispatch, '/optionGroup', param);
};
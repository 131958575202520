export const LowStarVal = 2;
export const MidStarVal = 3.5;

//colors
export const LowStarColor = '#cd3131';
export const MidStarColor = '#FACA10';
export const HighStarColor = '#08fcb1';

//mail settings strings
export const Is_Stop_Mails = 'isStopMails';
export const SMTP_Username = 'smtpUserName';
export const SMTP_Password = 'smtpPassword';
export const Host = 'host';
export const Port = 'port';
export const Cron_Time = 'CronTime';
export const Mail_Template = 'mailTemplate';
export const System_Time_Zone = 'System_Time_Zone';

//admin credentials
export const Admin_Email = 'adminEmail';
export const Admin_Password = 'adminPassword';
export const Mail_Temp = 'mailTemplate';
export const GetPreviousMonth = (date:Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    
    // Set the date to the first of the current month
    const firstOfMonth = new Date(year, month, 1);
    
    // Subtract one day to get the last day of the previous month
    const lastDayOfPreviousMonth = new Date(firstOfMonth.getTime() - 1);
    
    return lastDayOfPreviousMonth;
  };
import * as React from 'react'
import  UpperNavbar  from '../navbars/UpperNavbar';
import "../../css/components/main.css";
import { useSelector } from 'react-redux';
import { AppModeState } from '../../dtos/common/AppModeState';
import { AppMode } from '../../data/AppMode';
import { Outlet } from 'react-router-dom';
import { SideBarState } from '../../dtos/sidebar/SideBarState';

export const Main = () => {
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find(md => md.name === modeName);
  const isOpen = useSelector((state: SideBarState) => state.sidebar.isOpen);
  const screenWidth = window.innerWidth;
  const shouldOpenSidebar = screenWidth < 1200;

  // ${isOpen ? 'sidebar-close' : 'sidebar-open'}

  return (
    <div className={`main ${(!isOpen && shouldOpenSidebar) && 'sidebar_after' } ${isOpen ? 'sidebar-close' : 'sidebar-open'}`} style={{ backgroundColor: mode?.backgroundPrimaryColor }}   >
      <UpperNavbar />
      <div className='under-main-container'>
        <Outlet />
      </div>
    </div>
  )
}

import { SessionLanguage } from "../../dtos/common/SettingsLanguage";
import { UpdateSettings } from "../../dtos/common/UpdateSettingsDto";
import instance, { get, patch, post } from "../../utills/BaseService";

const header = {
    access_token: "Bearer " + localStorage.getItem("token")
}

export async function getAllLanguages(dispatch: any): Promise<any> {
    return await get(dispatch, '/configuration/getAllLanguage', null)
};
export async function setSessionLanguage(dispatch: any, credentials: SessionLanguage): Promise<any> {
    return await post(dispatch, '/configuration/setSessionLanguage', credentials)
};

export async function updateSettings(dispatch: any, credentials: UpdateSettings): Promise<any> {
    const token = localStorage.getItem("token");
    const header = {
        access_token: "Bearer " + token
    };
    return await instance.patch('/setting/updateSettings', credentials, {
        headers: header
    })
};
export async function updateOrCreateByKey(dispatch: any, credentials: UpdateSettings): Promise<any> {
    const header = {
        access_token: "Bearer " + localStorage.getItem("token")
    }
    return await instance.post('/setting/updateOrCreateByKey', credentials, {
        headers: header
    })
};
export async function createSettings(dispatch: any, credentials: UpdateSettings): Promise<any> {
    return await post(dispatch, '/setting/createSettings', credentials)
};

export async function getAllAppSettings(dispatch: any): Promise<any> {
    return await get(dispatch, '/configuration/getallappsetting', null)
};

export async function findSettingsByKey(dispatch: any, input: any): Promise<any> {
    return await get(dispatch, '/setting/findSettingsByKey', input)
}

import { InputNumber } from "primereact/inputnumber";
import { useEffect, useRef, useState } from "react";
import "../../css/components/employeeBilling.css";
import { Toast } from "primereact/toast";

interface Input {
    isloggedIn: boolean;
    timeInMin: number;
    handleSave: any
}
export const InputTimeDifference = (input: Input) => {
    const [billingInput, setBillingInput] = useState<boolean>(true);
    const [hourVal, setHourVal] = useState<number>(0);
    const [minVal, setMinVal] = useState<number>(0);
    const [billingTime, setBillingTime] = useState(0);
    const toast = useRef<any>(null);
    const validHour = new RegExp('^([0-1]?[0-9]|2[0-3])$');
    const validMin = new RegExp('^[0-9]$|^[1-5][0-9]$');

    const handleSave = async (hour: number, min: number) => {
        let validationErr: string = '';
        if (hour === null) {
            setHourVal(0)
        }
        if (min === null) {
            setMinVal(0)
        }

        const timeInMin: number = (hour * 60) + min;

        if (validationErr.length > 0) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: validationErr });
            return;
        }

        if (validHour.test(hour?.toString()) && validMin.test(min?.toString())) {
            await input.handleSave(timeInMin)
            setBillingTime(timeInMin)
        }
    }


    function debounce<Params extends any[]>(
        func: (...args: Params) => any,
        timeout: number,
    ): (...args: Params) => void {
        let timer: NodeJS.Timeout
        return (...args: Params) => {
            clearTimeout(timer)
            timer = setTimeout(() => {
                func(...args)
            }, timeout)
        }
    }

    const changeHourValue = (hour: number) => {
        setHourVal((prev) => {
            const prevVal = prev;
            const newVal = hour;

            if (newVal === null || prevVal === newVal) {
                return newVal;
            }
            handleSave(newVal, minVal)
            return newVal
        })
    }
    const changeMinValue = (min: number) => {
        setMinVal((prev) => {
            const prevVal = prev;
            const newVal = min;

            if (newVal === null || prevVal === newVal) {
                return newVal;
            }
            handleSave(hourVal, newVal)
            return newVal
        })
    }

    const handleTime = () => {
        if (input.timeInMin !== undefined) {

            var hours = (input.timeInMin / 60);
            var rhours = Math.floor(hours);
            var minutes = (hours - rhours) * 60;
            var rminutes = Math.round(minutes);
            setHourVal(rhours);
            setMinVal(rminutes)
        }
    }


    useEffect(() => {
        handleTime()
    }, [billingTime,input])

    return (
        <div className={billingInput ? 'input-for-billing-time-main' : 'input-for-billing-time'}>
            <Toast ref={toast} />
            <div className="input-for-billing-time-main" >
                {billingInput ? (
                    <div className="input-for-billing-time" >
                        <InputNumber inputStyle={{ width: '3rem', border: (hourVal > 23 || hourVal < 0) ? '1px solid red' : '' }} size={1} value={hourVal} onChange={debounce((e: any) => changeHourValue(e.value), 1000)} tooltip='Hour will not be more than 23' tooltipOptions={{ position: 'top' }} />Hr
                        <InputNumber inputStyle={{ width: '3rem', border: (minVal > 59 || minVal < 0) ? '1px solid red' : '' }} size={1} value={minVal} onChange={debounce((e: any) => changeMinValue(e.value), 1000)} tooltip="Minute will not be more than 59" tooltipOptions={{ position: 'top' }} />Min
                    </div>

                ) : (<label>{hourVal} Hr : {minVal} Min</label>)}
            </div>
        </div>
    )
}

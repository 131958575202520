// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container {
  display: flex;
  justify-content: flex-end;
}

input:invalid[focused="true"] {
  border: 1px solid red;
}

.status-button {
  width: 60% important!;
  font-size: 10px important!;
  text-align: center important!;
}



.status-button-container .p-button {
  width: 50%;
  text-align: center;
  font-size: 11px;
}

.subkpi_delete_btn{
  display: flex;
  justify-content: center;
}
.subkpi_delete_btn button{
  background-color: rgb(131, 2, 0) !important;
}

.subKpi-heading{
  word-wrap: break-word;
  width: 40rem;
}


.input-error .p-inputnumber-input {
  border-color: red !important;
  border-width: 2px !important;
}
@media only screen and (max-width: 767px) {
  .sub_kpi_toolbar button{
    font-size: 14px;
    width: 80px !important;
  }
}
@media only screen and (max-width: 568px) {
  .sub_kpi_toolbar .endToolbar{
    gap: 0.5rem !important;
    justify-content: flex-start !important;
    flex-wrap: wrap !important;
  }
  .sub_kpi_toolbar button{
    font-size: 12px;
    width: 60px !important;
  }
}

`, "",{"version":3,"sources":["webpack://./src/css/components/subKpi.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,0BAA0B;EAC1B,6BAA6B;AAC/B;;;;AAIA;EACE,UAAU;EACV,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,2CAA2C;AAC7C;;AAEA;EACE,qBAAqB;EACrB,YAAY;AACd;;;AAGA;EACE,4BAA4B;EAC5B,4BAA4B;AAC9B;AACA;EACE;IACE,eAAe;IACf,sBAAsB;EACxB;AACF;AACA;EACE;IACE,sBAAsB;IACtB,sCAAsC;IACtC,0BAA0B;EAC5B;EACA;IACE,eAAe;IACf,sBAAsB;EACxB;AACF","sourcesContent":[".button-container {\n  display: flex;\n  justify-content: flex-end;\n}\n\ninput:invalid[focused=\"true\"] {\n  border: 1px solid red;\n}\n\n.status-button {\n  width: 60% important!;\n  font-size: 10px important!;\n  text-align: center important!;\n}\n\n\n\n.status-button-container .p-button {\n  width: 50%;\n  text-align: center;\n  font-size: 11px;\n}\n\n.subkpi_delete_btn{\n  display: flex;\n  justify-content: center;\n}\n.subkpi_delete_btn button{\n  background-color: rgb(131, 2, 0) !important;\n}\n\n.subKpi-heading{\n  word-wrap: break-word;\n  width: 40rem;\n}\n\n\n.input-error .p-inputnumber-input {\n  border-color: red !important;\n  border-width: 2px !important;\n}\n@media only screen and (max-width: 767px) {\n  .sub_kpi_toolbar button{\n    font-size: 14px;\n    width: 80px !important;\n  }\n}\n@media only screen and (max-width: 568px) {\n  .sub_kpi_toolbar .endToolbar{\n    gap: 0.5rem !important;\n    justify-content: flex-start !important;\n    flex-wrap: wrap !important;\n  }\n  .sub_kpi_toolbar button{\n    font-size: 12px;\n    width: 60px !important;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

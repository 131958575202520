import * as react from 'react';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { useRef, useState } from 'react';
import '../../css/components/emailInputs.css';
import { Divider } from 'primereact/divider';
import { Checkbox } from 'primereact/checkbox';
import { testMail } from '../../service/emailSettings/EmailSettingsServices';
import { useDispatch, useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';
import { AppMode } from "../../data/AppMode";
import { ThemeState } from "../../dtos/common/ThemeState";
import { AppModeState } from '../../dtos/common/AppModeState';
import { findSettingsByKey, updateOrCreateByKey } from '../../service/common/commonService';
import { Host, Port, SMTP_Password, SMTP_Username } from '../../shared/constant/StarValue';
export const EmailSettings = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const themeName = useSelector((state: ThemeState) => state.theme.themeName);
    const modeName = useSelector((state: AppModeState) => state.theme.appMode);
    const mode = AppMode.find(md => md.name === modeName);
    const [fromAdd, setFromAdd] = useState('admin');
    const [toAdd, setToAdd] = useState('');
    const [host, setHost] = useState('127.0.0.1');
    const [port, setPort] = useState<number>(25);
    const [displayName, setDisplayName] = useState('EPMS');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [ssl, setSsl] = useState<boolean>(true);
    const dispatch = useDispatch();
    const toast = useRef<any>(null);
    const localization = useSelector(
        (state: any) => state.localization.localization
    );
    const [disableBtn, setDisableBtn] = useState<boolean>(false);

    const handleTestMail = async () => {
        const input = {
            host: host,
            port: port,
            from: fromAdd,
            to: toAdd,
            subject: displayName,
            message: 'HI',
            userName: userName,
            password: password
        }

        if (userName.length === 0 || password.length === 0 || toAdd.length === 0) {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please complete all the fields to send a test mail!', life: 3000 });
            return;
        }
        const result = await testMail(dispatch, input);
        await getAllInputs()
        toast.current.show({ severity: 'success', summary: 'Success', detail: result, life: 3000 });
    }

    const getAllInputs = async () => {

        const [hostInput, portInput, fromAddInput, smtpUserName] = await Promise.all([findSettingsByKey(dispatch, { key: Host }), findSettingsByKey(dispatch, { key: Port }), findSettingsByKey(dispatch, { key: 'fromAdd' }), findSettingsByKey(dispatch, { key: SMTP_Username })])

        setHost(hostInput?.Value);
        setPort(portInput?.Value);
        setFromAdd(fromAddInput?.Value);
        setUserName(smtpUserName?.Value);
        setPassword('*****')

        if (!hostInput || !portInput || !smtpUserName) {
            setDisableBtn(true)
        }
        else {
            setDisableBtn(false)
        }
    }

    const handleSave = async () => {
        if (userName.length === 0) {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please provide an username for save the details', life: 3000 });
            return;
        }

        if (password.length === 0 || password === '*****') {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please provide a password for save the details', life: 3000 });
            return;
        }

        if (host.length === 0) {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please provide a host name for save the details!', life: 3000 });
            return;
        }
        if (port === null) {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please provide a port for save the details!', life: 3000 });
            return;
        }

        await Promise.all([updateOrCreateByKey(dispatch, { Key: SMTP_Username, Value: userName }),
        updateOrCreateByKey(dispatch, { Key: SMTP_Password, Value: password }),
        updateOrCreateByKey(dispatch, { Key: Port, Value: port.toString() }),
        updateOrCreateByKey(dispatch, { Key: Host, Value: host })]);

        await getAllInputs();

        toast.current.show({ severity: 'success', summary: 'Success', detail: 'You have successfully save the details!', life: 3000 });
    }

    react.useEffect(() => {
        getAllInputs()
    }, [])

    return (
        <Card style={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color, padding: '0rem' }} className='settings-card'>
            <Toast ref={toast} />
            <div className='email-settings-header p-card-title'>
                <h3 className='m-0'>{localization?.MailSettings || "Mail Settings"}</h3>
            </div>
            <div className='dashbord-card-cont'>
            <div className='all-inputs'>
                <div className='input-groups'>
                    <label>From Address</label>
                    <InputText className='mail-input' value={fromAdd} onChange={(e) => setFromAdd(e.target.value)} />
                </div>
                <div className='input-groups'>
                    <label>From Display Name</label>
                    <InputText className='mail-input' value={displayName} onChange={(e) => setDisplayName(e.target.value)} />
                </div>
                <div className='input-groups'>
                    <label>Host</label>
                    <InputText className='mail-input' value={host} onChange={(e) => setHost(e.target.value)} />
                </div>
                <div className='input-groups'>
                    <label>Port</label>
                    <InputNumber inputClassName='mail-input' value={port} onChange={(e: any) => setPort(e.value)} />
                </div>
                <div className='input-groups'>
                    <label>UserName</label>
                    <InputText className='mail-input' value={userName} onChange={(e) => setUserName(e.target.value)} />
                </div>
                <div className='input-groups'>
                    <label>Password</label>
                    <InputText className='mail-input' type='password' value={password} onChange={(e) => setPassword(e.target.value)} width={100} />
                </div>
                <div className='check-input-groups'>
                    <div className='check-groups'>
                        <Checkbox onChange={(e: any) => setSsl(ssl ? false : true)} checked={ssl ? true : false}></Checkbox>
                        <label>Use SSl</label>
                    </div>
                    <div className='check-groups'>
                        <Checkbox onChange={(e: any) => setSsl(ssl ? false : true)} checked={ssl ? false : true}></Checkbox>
                        <label>Use Default Credentials</label>
                    </div>
                </div>
            </div>
            <Divider />
            <div className='email-settings-footer gap-3'>
                <div className='input-groups'>
                    <InputText className='mail-input' value={toAdd} placeholder='To Address' onChange={(e) => setToAdd(e.target.value)} />
                </div>
                <div className='email-settings-btn'>
                    <Button label='Save' onClick={handleSave} />
                    <Button label='Send Test Mail' disabled={disableBtn} onClick={handleTestMail} />
                </div>
            </div>
            </div>
        </Card>
    )
}
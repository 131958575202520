import ReactApexChart from "react-apexcharts";
import { getTheMonthName } from "../../shared/functions/GetMonthName";
import colors from '../../shared/json/Colors.json';
import { MonthlyRatingsWithKPI } from "../../dtos/performance/MonthlyRatingsWithKPI";
import { AppModeState } from "../../dtos/common/AppModeState";
import { useSelector } from "react-redux";
import { AppMode } from "../../data/AppMode";

export interface PerformanceReportProps {
    data: MonthlyRatingsWithKPI[];
    date: Date
}

interface Series {
    name: string;
    data: number[];
}

export const EmpPerformanceReportMonthlyChart = (props: PerformanceReportProps) => {
    const modeName = useSelector((state: AppModeState) => state.theme.appMode);
    const mode = AppMode.find((md) => md.name === modeName);
    let catg: string[] = [];
    let series: Series[] = [];

    const baseHeight = 100; // Base height in pixels
    const additionalHeightPerDataPoint = 10; // Additional height per data point
    const dynamicHeight = props.data.length > 1 
    ? baseHeight + (props.data.length) * additionalHeightPerDataPoint 
    : 100;

    props.data.forEach(val => {
        //for name
        const isNamePresent = catg.find(cat => cat === `${val.EmployeeName}(${val.EmpCode})`);
        if (!isNamePresent) {
            catg.push(`${val.EmployeeName}(${val.EmpCode})`);
        }

        //for kpi name
        const isKPIPresent = series.find(ser => ser.name === val.Name)
        if (!isKPIPresent) {
            series.push({
                name: val.Name,
                data: []
            })
        }
    })

    catg.forEach(cat => {
        series.forEach(ser => {
            const isPresent = props.data.find(val => `${val.EmployeeName}(${val.EmpCode})` === cat && val.Name === ser.name);

            if (isPresent) {
                ser.data.push(isPresent.KpiPercentRating)
            }
            else {
                ser.data.push(0)
            }
        })

    });

    const legendColor: string[] = [];
    series.forEach(ser => legendColor.push(mode?.color as string))

    const legendClrForX: string[] = []
    catg.forEach(cat => legendClrForX.push(mode?.color as string))

    const chart: any = {

        series: series,
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {
                        total: {
                            enabled: true,
                            offsetX: 0,
                            formatter: function (val: number) {
                                const rating = val.toFixed(2);
                                return rating;
                            },
                            style: {
                                fontSize: '13px',
                                fontWeight: 900,
                                color:mode?.color
                            }
                        }
                    }
                },
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            xaxis: {
                categories: catg,
                labels: {
                    formatter: function (val: any) {
                        return val + '%'
                    }
                }
            },
            yaxis: {
                labels: {
                    style: {
                        colors: legendClrForX
                    }
                }
            },
            tooltip: {
                y: {
                    formatter: function (val: any) {
                        return val + '%'
                    }
                }
            },
            fill: {
                opacity: 1
            },
            colors: colors.colors,
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40,
                labels: {
                    colors: legendColor
                }
            }
        },
    };

    return (
        <div>
            <div className="p-card-title"><h5 className="mb-0">{getTheMonthName(props.date)} Performance Report</h5></div>
            <div className="dashbord-card-cont"><ReactApexChart options={chart.options} series={chart.series} type="bar" height={dynamicHeight} /></div>
        </div>
    )
}
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppModeState } from "../../dtos/common/AppModeState";
import { AppMode } from "../../data/AppMode";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { ThemeState } from "../../dtos/common/ThemeState";
import { Themes } from "../../data/Themes";
import { DataTable } from "primereact/datatable";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";

import { Tooltip } from "primereact/tooltip";
import { SkillDto } from "../../dtos/skill/SkillsDto";
import {
  createRole,
  getPaginatedRoles,
  updateRole,
} from "../../service/employeeRoles/employeeRolesServices";
import { TabView, TabPanel } from "primereact/tabview";

import { Tree } from "primereact/tree";
import {
  checkPermissionExistance,
  createPermissionAssignByRoleName,
  getPermissionByEmployeeCode,
} from "../../service/permissionAssign/permissionAssignServices";
import { treeDataWithSelectable, treeDataWithoutSelectable, treeWithUnCheckedRoles } from "./roleData";
import { TreeItemDto } from "../../dtos/employeeRoles/TreeItemDto";
import { RoleInfoDto } from "../../dtos/employeeRoles/RoleInfoDto";
import { CreateUpdateRoleDto } from "../../dtos/employeeRoles/CreateUpdateRolesDto";
import {
  createRoleMapping,
  createUserMapping,
  editRoleInfo,
  editRoleMapping,
  editRolePermission,
  editUserMapping,
  editUserPermission,
  indivisualRole,
  kpiMappings,
  roleMappings,
  userMapping,
  userPermission,
} from "../../shared/constant/PermissionVariables";
import { setPermissions } from "../../store/slices/permissionSlice";
import { setError } from "../../store/slices/errorSlice";
import { useNavigate } from "react-router-dom";
import { getUserRole } from "../../service/user/userService";
import { GetPaginationRowsCount } from "../../shared/functions/GetPaginationRowsCount";
import { GetPaginationRowsInterface } from "../../dtos/common/GetPaginationRows";
import { ADMIN } from "../../shared/constant/RoleNames";

export interface LazyState {
  page: number;
  first: number;
  rows: number;
  sortField: string;
  sortOrder: number;
  filters?: string;
}

interface RoleErrors {
  Name: boolean;
  Duration: boolean;
}

interface RoleInfo {
  Name: string;
  Description: string;
}

interface Permission {
  id: number;
  RoleName: string;
  PermissionName: string;
  EmployeeCode: string | null;
  IsGranted: boolean;
}

interface PermissionCheckState {
  [key: string]: {
    checked: boolean;
    partialChecked: boolean;
  };
}

const RoleTable = () => {
  //#region all Variables
  const localization = useSelector(
    (state: any) => state.localization.localization
  );

  const dt = React.useRef<any>(null);
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  const dispatch = useDispatch();
  const row: GetPaginationRowsInterface = GetPaginationRowsCount();
  const [permissionExist,setPermissionExist] = useState<boolean|null>(null)
  const [lazyState, setlazyState] = React.useState({
    page: 0,
    first: 0,
    rows: row.rowCount,
    sortField: "createdAt",
    sortOrder: 0 as any,
  });
  const [totalRoles, setTotalRoles] = useState(0);
  const [selectedRole, setSelectedRole] = useState<any>();
  const [roles, setRoles] = useState<SkillDto[] | []>([]);
  const toast = useRef<Toast>(null);
  const [isEditClicked, setIsEditClicked] = useState<boolean>(false);
  const [showRoleDialog, setShowRoleDialog] = React.useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const emptyRole = {
    Name: "",
    Description: "",
  };

  const emptyRoleErrors = {
    Name: false,
    Duration: false,
  };
  const [roleErrors, setRoleErrors] = useState<RoleErrors>(emptyRoleErrors);
  const [roleInfo, setRoleInfo] = useState<RoleInfo>(emptyRole);
  const [prevRoleName, setPrevRoleName] = useState<string | null>(null);
  const [expandDescription, setExpandDescription] = useState<{
    [key: number]: boolean;
  }>({});
  const [tree, setTree] = useState<any>([]);
  const [selectedKeys, setSelectedKeys] = useState<PermissionCheckState | {}>(
    {}
  );
  const [activeTab, setActiveTab] = useState<number>(0);
  const [allGivenPermissions, setAllGivenPermissions] = useState<
    Permission[] | []
  >([]);
  const [alreadyCheckedPermission, setAlreadyCheckedPermission] = useState<
    PermissionCheckState | {}
  >({});
  const [expandedKeys, setExpandedKeys] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [unCheckedNode, setUnCheckedNode] = useState<string | null>(null);
  const userPermissions = useSelector(
    (state: any) => state.permission.permissions
  );
  const hasDetailsTab = userPermissions.includes(editRoleInfo);
  const hasPermissionsTab = userPermissions.includes(editRolePermission);
  const navigate = useNavigate();
  const [previouslySelectedNodes, setPreviouslySelectedNodes] = useState<
    PermissionCheckState | {}
  >({});
  const [allUserPermissions, setAllUserPermissions] = useState<
    { [key: string]: { checked: boolean; partialChecked: boolean } } | {}
  >({});
  const [permissionsInDB, setPermissionsInDB] = useState<any>();
  //#endregion
  //#region all functions

  const onSort = (event: any) => {
    setlazyState((prev: LazyState) => {
      const newState: LazyState = {
        ...prev, // Copy all properties from the previous state
        sortOrder: event.sortOrder, // Update sortOrder
        sortField: event.sortField, // Update sortField
      };
      return newState;
    });
  };

  const onPageChange = (event: any) => {
    setlazyState(event);
  };

  const showToast = (severity: any, detail: string, summary: string) => {
    toast.current?.show({ severity, summary, detail, life: 3000 });
  };

  const resetDialog = () => {
    setShowRoleDialog(false);
    setRoleInfo(emptyRole);
    setRoleErrors(emptyRoleErrors);
    setRoleId(null);
    setIsEditClicked(false);
    setAlreadyCheckedPermission({});
    setActiveTab(0);
    setSelectedKeys({});
  };

  const allDataFunc = async () => {
    let param;

    if (searchText.length > 0) {
      param = {
        Limit: lazyState.rows,
        Page: lazyState.page,
        SortBy: lazyState.sortOrder === 1 ? "ASC" : "DESC",
        SortProperty: lazyState.sortField,
        Filter: searchText?.toLowerCase(),
        PropertyNames: ["Name"],
      };
    } else {
      param = {
        Limit: lazyState.rows,
        Page: lazyState.page,
        SortBy: lazyState.sortOrder === 1 ? "ASC" : "DESC",
        SortProperty: lazyState.sortField,
      };
    }

    const paginatedRoles = await getPaginatedRoles(dispatch, param);
    setTotalRoles(paginatedRoles?.TotalCount);
    setRoles(paginatedRoles?.List);
  };

  const descriptionExpandHandler = () => {
    const tempDescriptionExpandInfo = { ...expandDescription };
    if (roles) {
      roles.forEach((role) => {
        tempDescriptionExpandInfo[role.id] = false;
      });
    }
    setExpandDescription(tempDescriptionExpandInfo);
  };

  const editRole = (rowData: any) => {
    if (
      !userPermissions.includes(editRoleInfo) &&
      !userPermissions.includes(editRolePermission)
    ) {
      return toast.current?.show({
        severity: "error",
        summary: "Error",
        detail:
          "You don't have the required permissions to access this feature.",
        life: 3000,
      });
    }
    setIsEditClicked(true);
    setPrevRoleName(rowData.Name);
    setRoleId(rowData.id);
    setShowRoleDialog(true);
    setRoleInfo({
      Name: rowData.Name,
      Description: rowData.Description,
    });
  };

  const handleSave = async () => {
    if (isEditClicked && activeTab === 1) {
      const input = {
        RoleName: roleInfo.Name,
        PermissionName: selectedKeys ? Object.keys(selectedKeys) : [],
        IsGranted: true,
      };
      let updatedPermissions: any = [];
     

      const selectedNodes = Object.keys(selectedKeys);

      selectedNodes.forEach((permission: any) => {
        if (permissionsInDB.length === 0) {
          // If permissionsInDB is empty, add all selected keys to uncheckedNodes
          updatedPermissions.push({
            RoleName: roleInfo.Name,
            IsGranted: true,
            PermissionName: permission,
          });
        } else {
          // permissionsInDB is not empty; check if permission exists and compare IsGranted status
          const dbPermission = permissionsInDB.find(
            (node: any) => node.PermissionName === permission
          );
        
          if (dbPermission) {
            // Permission exists in DB, compare IsGranted status
            const selectedIsGranted = (selectedKeys as PermissionCheckState)[
              permission
            ]?.checked;
            
            if (dbPermission.IsGranted !== selectedIsGranted) {
              updatedPermissions.push({
                RoleName: roleInfo.Name,
                IsGranted: selectedIsGranted,
                PermissionName: permission,
              });
            }
          } else {
            // Permission is not in permissionsInDB but is in selectedKeys
            updatedPermissions.push({
              RoleName: roleInfo.Name,
              IsGranted: (selectedKeys as PermissionCheckState)[permission]
                ?.checked,
              PermissionName: permission,
            });
          }
        }
      });

      // Handle permissions in previouslySelectedNodes that are not in selectedKeys
      Object.keys(previouslySelectedNodes).forEach((dbPermission: any) => {
        if (!selectedKeys.hasOwnProperty(dbPermission)) {
          // If permission is in previouslySelectedNodes but missing in selectedKeys, set IsGranted to false
          updatedPermissions.push({
            RoleName: roleInfo.Name,
            IsGranted: false,
            PermissionName: dbPermission,
          });
        }
      });

      const createInput = [...updatedPermissions];
      if(Object.keys(selectedKeys).length===0 && createInput.length===0){
        return showToast(
          "error",
          "Select some permissions to save it!",
          "Error"
        );
      }else if(Object.keys(selectedKeys).length!==0 && createInput.length===0){
        resetDialog()
          return
      }
      // if(createInput.length===0){
      //   return showToast(
      //     "error",
      //     "Select some permissions to save it!",
      //     "Error"
      //   );
      // }
      const res = await createPermissionAssignByRoleName(dispatch, createInput);
      const role = await getUserRole(dispatch);
      if (role === roleInfo.Name) {
        getPermission();
      }
      resetDialog();
      return showToast(
        "success",
        "Permission assigned successfully",
        "Success"
      );
    }
    if (checkForErrors(roleInfo)) {
      if (isEditClicked && activeTab === 0) {
        if (roleId) {
          const input: CreateUpdateRoleDto = {
            Name: roleInfo.Name.trim(),
            Description: roleInfo?.Description?.trim(),
            prevRoleName: prevRoleName ?? undefined,
          };
          const response = await updateRole(dispatch, roleId, input);
          if (typeof response === "object" && "error" in response) {
            // If it's an error response, show the error toast
            if (response.error) {
              return showToast("error", response.msg, "Error");
            }
          } else {
            showToast("success", "Role Updated successfully", "Success");
          }
        }
      } else {
        const input = {
          Name: roleInfo.Name.trim(),
          Description: roleInfo?.Description?.trim(),
        };
        const response = await createRole(dispatch, input);
        if (typeof response === "object" && "error" in response) {
          if (response.error) {
            return showToast("error", response.msg, "Error");
          }
        } else {
          showToast("success", "Role created successfully", "Success");
        }
      }

      resetDialog();
      allDataFunc();
    }
  };

  const checkForErrors = (role: RoleInfo) => {
    let isValid = true;
    let errors = { ...roleErrors };
    if (!role.Name.trim()) {
      errors.Name = true;
      isValid = false;
    }

    setRoleErrors(errors);
    return isValid;
  };

  const onRoleChange = (name: string, value: string) => {
    setRoleErrors({ ...roleErrors, [name]: false });
    setRoleInfo((prevRole) => ({
      ...prevRole,
      [name]: value,
    }));
  };

  const findParentNode = (
    nodes: any,
    childKey: any,
    parentNodes: any[] = []
  ): any[] | null => {
    for (const node of nodes) {
      // If current node has children, continue searching
      if (node.key === childKey) {
        return parentNodes.concat([node]);
      }
      if (node.children) {
        // Check if any child has the specified key
        if (node.children.some((child: any) => child.key === childKey)) {
          // Add the current node (parent) to the parentNodes array
          parentNodes.push(node);
          // Continue searching upwards by calling the function with the current node's key
          findParentNode(treeDataWithoutSelectable, node.key, parentNodes);
          return parentNodes; // Return once all parents are found
        }

        // Recursively call the function on children if child key not found at this level
        const result = findParentNode(node.children, childKey, parentNodes);
        if (result) return result; // Return if result is found in deeper recursive calls
      }
    }
    return null;
  };

  const updateCheckedValues = (obj: any): any => {
    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        if ("checked" in obj[key]) {
          obj[key].checked = true;
          obj[key].partialChecked = false;
        }
      }
    }
    Object.keys(obj).forEach((item: any) => {
      const parents = findParentNode(treeDataWithoutSelectable, item);
      parents?.forEach((parent) => {
        obj[parent.key] = { checked: true };
      });
    });

    if(roleInfo.Name!==ADMIN){
      delete obj[indivisualRole];
      delete obj[editRoleInfo];
      delete obj[editRolePermission];
      delete obj[userPermission];
      delete obj[editUserPermission]
    }
   if(!permissionExist){
    delete obj[kpiMappings];
      delete obj[roleMappings];
      delete obj[createRoleMapping];
      delete obj[editRoleMapping];
      delete obj[userMapping];
      delete obj[createUserMapping];
      delete obj[editUserMapping];
   }

    setSelectedKeys({ ...obj });
    return obj;
  };



  // Function to collect all node keys for expansion
  const expandAllNodes = (nodes: TreeItemDto[]) => {
    let keys: { [key: string]: boolean } = {};
    const traverse = (nodeArray: TreeItemDto[]) => {
      nodeArray.forEach((node) => {
        keys[node.key] = true; // Mark the node as expanded
        if (node.children) {
          traverse(node.children); // Recursively collect keys from children
        }
      });
    };
    traverse(nodes);
    return keys;
  };

  const checkIfPermissionExist = async ()=>{
    const isPermissionExist = await checkPermissionExistance(dispatch, {
      roleName: roleInfo.Name,
    });
    setPermissionExist(isPermissionExist)
  }

  const getPermissionOnPageReload = async () => {
    const isPermissionExist = await checkPermissionExistance(dispatch, {
      roleName: roleInfo.Name,
    });
    if (isPermissionExist && roleInfo.Name===ADMIN) {
      setTree(treeDataWithoutSelectable);
    }else if(isPermissionExist && roleInfo.Name!==ADMIN){
      setTree(treeWithUnCheckedRoles);
    } else{
      setTree(treeDataWithSelectable);
    }
 
    const alreadyGivenPermissions = await getPermissionByEmployeeCode(
      dispatch,
      {
        roleName: roleInfo.Name,
      }
    );
    const checkedPermissions = alreadyGivenPermissions?.filter((node) => {
      return node.IsGranted;
    });
    let obj: any = {};
    const permissions = checkedPermissions.forEach((permission) => {
      obj[permission.PermissionName] = { checked: true };
    });
    Object.keys(obj).forEach((item: any) => {
      const parents = findParentNode(treeDataWithoutSelectable, item);
      parents?.forEach((parent) => {
        obj[parent.key] = { checked: true };
      });
    });
    setSelectedKeys({ ...obj });
    setPreviouslySelectedNodes({ ...obj });
    setAllUserPermissions({ ...obj });
    setPermissionsInDB(alreadyGivenPermissions);
  };

  const getPermission = async () => {
    if (Object.keys(selectedKeys).includes(indivisualRole)) {
     
      const alreadyGivenPermissions = await getPermissionByEmployeeCode(
        dispatch,
        {
          roleName: roleInfo.Name,
        }
      );

      const permissions = alreadyGivenPermissions.map(
        (permission) => permission.PermissionName
      );

      dispatch(setPermissions(permissions));
      setAllGivenPermissions(alreadyGivenPermissions);
    } else {
      dispatch(
        setError({
          isError: true,
          errorMessage: "Sorry,you don't have permission to access this page",
        })
      );
      setTimeout(() => {
        navigate("/permissionDenied");
      }, 2000);
    }
  };

  const handleTabChange = (e: any) => {
    setActiveTab(e.index);
    // You can use this index to perform actions based on the active tab
  };

  //#endregion

  //#region all templates

  const startToolbar = () => {
    return (
      <div>
        <h4>{localization?.Skills || "Roles"}</h4>
      </div>
    );
  };

  const endToolbar = () => {
    return (
      <div className="endToolbar">
     
        <div className="searchBxCont">
          <div className="searchBx border_primary">
            <InputText
              className="w-100"
              type="search"
              placeholder={` ${localization?.Search || "Search"}...`}
              onChange={(e) => {
                setTimeout(() => {
                  setSearchText(e.target.value);
                }, 500);
              }}
            />
            <span className="searchIcon"><i className="pi pi-search"></i></span>
          </div>
        </div>
      </div>
    );
  };

  const editDeleteButtonTemplate = (rowData: RoleInfoDto) => {
    return (
      <div className="editDeleteButtonTemplate skill_table_btns bengali_btn_style">
        <Button
          icon="pi pi-pencil"
          // label={localization?.Edit || "Edit"}
          className="editDelButton text-primary"
          style={{ backgroundColor: ThemeColors?.primaryColor }}
          onClick={() => {
            editRole(rowData);
          }}
        />
      </div>
    );
  };

  const dialogHeader = () => {
    return (
      <>
        <div className="dialogHeader">
          <h4>
            {isEditClicked
              ? localization?.EditRole || "Edit Role"
              : localization?.CreateSkill || "Create Role"}
          </h4>
        </div>
      </>
    );
  };

  const roleDescriptionTemplate = (rowData: RoleInfoDto) => {
    return (
      <>
        {rowData?.Description && rowData?.Description?.length > 150 ? (
          <>
            {rowData.Description.slice(0, 150)}
            <span
              className={`goal-description-expanded-dots${rowData.id} expanded-dots`}
              data-pr-position="bottom"
            >
              ...
              <Tooltip
                target={`.goal-description-expanded-dots${rowData.id}`}
                content={rowData.Description}
                style={{ width: "50rem" }}
              />
            </span>
          </>
        ) : (
          rowData.Description
        )}
      </>
    );
  };

  const roleNameTemplate = (rowData: RoleInfoDto) => {
    return (
      <>
        {rowData.Name.length > 70 ? (
          <>
            {rowData.Name.slice(0, 70)}
            <span
              className={`name-expanded-dots${rowData.id} expanded-dots`}
              data-pr-position="bottom"
            >
              ...
              <Tooltip
                target={`.name-expanded-dots${rowData.id}`}
                content={rowData.Name}
                style={{ width: "50rem" }}
              />
            </span>
          </>
        ) : (
          rowData.Name
        )}
      </>
    );
  };

  const dialogFooter = () => {
    return (
      <div
        style={{
          padding: "1rem 0",
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
      >
        <Button
          label={localization?.Save || "Save"}
          className="kpi-save-button"
          onClick={handleSave}
          style={{ backgroundColor: ThemeColors?.primaryColor }}
        />
      </div>
    );
  };

  //#endregion

  // Ensure activeTab defaults to the first available tab if the current index is invalid
  useEffect(() => {
    if (!hasDetailsTab && activeTab === 0) {
      setActiveTab(1); // Set to Permissions tab (index 0 after Details is removed)
    } else if (!hasPermissionsTab && activeTab === 1) {
      setActiveTab(0); // Reset to Details tab (or the only available tab)
    }
  }, [hasDetailsTab, hasPermissionsTab, activeTab]);

  useEffect(() => {
    if (tree) {
      const allExpandedKeys = expandAllNodes(tree);
      setExpandedKeys(allExpandedKeys);
    }
  }, [tree]);

  useEffect(() => {
    if (roleInfo?.Name) {
      getPermissionOnPageReload();
      checkIfPermissionExist()
    }
  }, [isEditClicked]);

  useEffect(() => {
    descriptionExpandHandler();
  }, [roles]);

  useEffect(() => {
    allDataFunc();
  }, [lazyState, searchText]);

  useEffect(() => {
    setTree(treeDataWithoutSelectable);
  }, []);

  return (
    <div>
      <Toast ref={toast} />
      <Toolbar
        className="bg-transparent border-0 p-0 mb-3"
        start={startToolbar}
        end={endToolbar}
        style={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
      />

      <div
        // style={{ backgroundColor: mode?.backgroundSecondaryColor }}
        className="kpi_tabile_res RolesTable cs_card overflow-hidden border_primary cs_card_shadow"
      >
        <DataTable
          className="audit-log-table roleTable"
          ref={dt}
          value={roles}
          selection={selectedRole}
          onSelectionChange={(e: any) => setSelectedRole(e.value)}
          dataKey="Name"
          lazy
          paginator
          first={lazyState.first}
          rows={lazyState.rows}
          totalRecords={totalRoles}
          onSort={onSort}
          onPage={onPageChange}
          sortOrder={lazyState.sortOrder}
          sortField={lazyState.sortField}
          showGridlines
          rowsPerPageOptions={row.rowList}
          currentPageReportTemplate="Total Records {totalRecords} "
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        >
          <Column
            className="kpiColumn"
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
              minWidth: "200px",
            }}
            field="Name"
            body={roleNameTemplate}
            header={localization?.Name || "Name"}
            sortable
          ></Column>
          <Column
            className="kpiColumn"
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            field="Description"
            body={roleDescriptionTemplate}
            header={localization?.Description || "Description"}
          ></Column>
          {(userPermissions.includes(editRoleInfo) ||
            userPermissions.includes(editRolePermission)) && (
            <Column
              className="kpiColumn col_center actionColumn"
              bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              body={editDeleteButtonTemplate}
              header={localization?.Action || "Action"}
            ></Column>
          )}
        </DataTable>
      </div>
      <Dialog
        className="dialog RoleDialog"
        visible={showRoleDialog}
        header={dialogHeader}
        footer={dialogFooter}
        style={{
          width: "45rem",
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        contentStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        headerStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        baseZIndex={999}
        onHide={resetDialog}
        draggable={false}
      >
        <TabView
          activeIndex={activeTab}
          onTabChange={(e) => handleTabChange(e)}
          panelContainerStyle={{
            backgroundColor: mode?.backgroundSecondaryColor,
            color: mode?.color,
          }}
        >
          {userPermissions.includes(editRoleInfo) && (
            <TabPanel
              header={localization?.Details || "Details"}
              contentStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
            >
              <div className="field">
                <label htmlFor="name" className="font-bold">
                  {localization?.Name || "Name"}*
                </label>
                <InputText
                  id="goal-name"
                  name="Name"
                  value={roleInfo.Name}
                  onChange={(e) => onRoleChange(e.target.name, e.target.value)}
                  disabled
                />

                {roleErrors.Name && (
                  <small className="p-error">Role Name is required.</small>
                )}
              </div>

              <div className="field">
                <label htmlFor="information" className="font-bold">
                  {localization?.Description || "Description"}
                </label>
                <InputTextarea
                  className="custom-textarea"
                  id="goal-description"
                  name="Description"
                  value={roleInfo.Description}
                  onChange={(e) => onRoleChange(e.target.name, e.target.value)}
                  rows={5}
                  cols={30}
                />
              </div>
            </TabPanel>
          )}
          {userPermissions.includes(editRolePermission) && (
            <TabPanel
              header={localization?.Permissions || "Permissions"}
              contentStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
            >
              <Tree
                value={tree}
                selectionMode="checkbox"
                selectionKeys={selectedKeys}
                onSelectionChange={(e: any) => {
                  updateCheckedValues(e.value);
                }}
                className="w-full"
                expandedKeys={expandedKeys}
                onToggle={(e) => setExpandedKeys(e.value)} // Allows manual toggle by user
                propagateSelectionUp={false}
                style={{
                  backgroundColor: mode?.backgroundSecondaryColor,
                  color: mode?.color,
                }}
              />
            </TabPanel>
          )}
        </TabView>
      </Dialog>
    </div>
  );
};

export default RoleTable;

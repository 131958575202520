// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emp-name-col {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.emp-name-col>span:nth-of-type(1) {
    cursor: pointer;
}

.sidebar-open .performance-table .p-datatable-wrapper {
    width: calc(100vw - 311px);
}

.sidebar-close .performance-table .p-datatable-wrapper {
    width: calc(100vw - 145px);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item{
    padding: 0.5rem 1.25rem;
}
.dashbord-card-cont{
    padding: 1rem;
}
.performance-report-card-cont .apexcharts-toolbar{
    top: -56px !important;
}
@media screen and (max-width:680px) {
    .p-report-toolbar .p-toolbar-group-right {
        width: 100%;
    }
    .p-report-toolbar .date-input {
        justify-content: left;
    }
    .p-report-toolbar .report-start-tool {
        flex-direction: unset;
    }
}
`, "",{"version":3,"sources":["webpack://./src/css/components/performanceReport.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;AAC9B;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,aAAa;AACjB;AACA;IACI,qBAAqB;AACzB;AACA;IACI;QACI,WAAW;IACf;IACA;QACI,qBAAqB;IACzB;IACA;QACI,qBAAqB;IACzB;AACJ","sourcesContent":[".emp-name-col {\n    display: flex;\n    gap: 1rem;\n    align-items: center;\n}\n\n.emp-name-col>span:nth-of-type(1) {\n    cursor: pointer;\n}\n\n.sidebar-open .performance-table .p-datatable-wrapper {\n    width: calc(100vw - 311px);\n}\n\n.sidebar-close .performance-table .p-datatable-wrapper {\n    width: calc(100vw - 145px);\n}\n.p-multiselect-panel .p-multiselect-items .p-multiselect-item{\n    padding: 0.5rem 1.25rem;\n}\n.dashbord-card-cont{\n    padding: 1rem;\n}\n.performance-report-card-cont .apexcharts-toolbar{\n    top: -56px !important;\n}\n@media screen and (max-width:680px) {\n    .p-report-toolbar .p-toolbar-group-right {\n        width: 100%;\n    }\n    .p-report-toolbar .date-input {\n        justify-content: left;\n    }\n    .p-report-toolbar .report-start-tool {\n        flex-direction: unset;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

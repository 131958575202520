import * as react from 'react';
import { useEffect } from 'react';
export interface MailTempPreviewProp {
    temp: any
}


export const MailTempPreview = (props: any) => {

    const handleTemp = () => {
        let modifiedTemp = props.temp;

        if (modifiedTemp.includes('#empName#')) {
            modifiedTemp = modifiedTemp.replace('#empName#', 'Gourav');
        }

        if (modifiedTemp.includes('#allSubordinates#')) {
            modifiedTemp = modifiedTemp.replace(
                '#allSubordinates#',
                `<div>
                    <style>
    
                        table {
                            border-collapse: collapse;
                            width: fit-content;
                        }
    
                        th, td {
                            border: 1px solid #7D7C7C;
                            padding: 0.8rem 8rem;
                            text-align: center;
                        }
    
                        th {
                            background-color: var(--blue-highlightbtn);
                            color: white;
                            font-size: 1rem;
                        }
    
                        td {
                            font-size: 0.9rem;
                        }
                        .table-container{
                            display:flex;
                            justify-content:center;
                            margin:10px 0px;
                        }
    
                    </style>
                    <div class='table-container'>
                        <table>
                            <tr>
                                <th>Employee Names</th>
                            </tr>
                                <tr><td>Sumita Dey</td></tr>
                                <tr><td>Priti Roy</td></tr>
                                <tr><td>Utsav Sen</td></tr>
                        </table>
                        </div>
                </div>`
            );;
        }

        return modifiedTemp;
    }

    const modifiedTemp = handleTemp();



    const theObj = { __html: modifiedTemp };

    return <div style={{ paddingBottom: '1rem' }} dangerouslySetInnerHTML={theObj} />;
}
import { MultiSelect } from "primereact/multiselect";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppModeState } from "../../dtos/common/AppModeState";
import { AppMode } from "../../data/AppMode";
import { SkillDto } from "../../dtos/skill/SkillsDto";
import { createSkill } from "../../service/skill/skillServices";
import { error } from "console";

interface MultiselectProps {
  selectedSkills: SkillDto[] | [];
  setSelectedSkills: React.Dispatch<React.SetStateAction<SkillDto[] | []>>;
  allSkills: SkillDto[] | [];
  setAllSkills: React.Dispatch<React.SetStateAction<SkillDto[] | []>>;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  goalWithSkillsError?: {
    GoalName: boolean;
    Duration: boolean;
    Skills: boolean;
  };
  errors?: { employeeName: boolean; skill: boolean };
  setGoalWithSkillError?: React.Dispatch<
    React.SetStateAction<{
      GoalName: boolean;
      Duration: boolean;
      Skills: boolean;
    }>
  >;
  setErrors?: React.Dispatch<
    React.SetStateAction<{ employeeName: boolean; skill: boolean }>
  >;
}
const ModifiedMultiselect: React.FC<MultiselectProps> = ({
  selectedSkills,
  setSelectedSkills,
  allSkills,
  setAllSkills,
  inputValue,
  setInputValue,
  goalWithSkillsError,
  setGoalWithSkillError,
  setErrors,
  errors,
}) => {
  //#region all Variables
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const dispatch = useDispatch();
  const [multiSelectKey, setMultiSelectKey] = useState<number>(0);

  //#endregion

  //#region all Functions
  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
    if (setErrors && errors) {
      setErrors({ ...errors, skill: false });
    }
  };

  const handleKeyDown = async (e: any) => {
    if (e.key === "Enter") {
      const newSkill = { id: Date.now(), Name: inputValue };
      // Check if the skill already exists in allSkills by matching the Name property
      const isSkillUnique = !allSkills.some(
        (skill) => skill.Name.toLowerCase() === newSkill.Name.toLowerCase()
      );
      if (isSkillUnique) {
        // If the skill is unique, update the selectedSkills and allSkills
        if (selectedSkills === null || selectedSkills.length < 1) {
          setSelectedSkills([newSkill]);
          setAllSkills([...allSkills, newSkill]);
        } else {
          setSelectedSkills([...selectedSkills, newSkill]);
          setAllSkills([...allSkills, newSkill]);
        }
      }
      if (inputValue.length > 0) {
        if (setErrors && errors) {
          setErrors({ ...errors, skill: false });
        }
      }
      setInputValue("");
      setMultiSelectKey((prevKey) => prevKey + 1);
    }
  };
  const handleChange = (e: any) => {
    setSelectedSkills(e.value);
    if (goalWithSkillsError && setGoalWithSkillError) {
      setGoalWithSkillError({ ...goalWithSkillsError, Skills: false });
    }
    if (setErrors && errors) {
      setErrors({ ...errors, skill: false });
    }
  };
  //#endregion
  return (
    <div>
      <MultiSelect
        key={multiSelectKey}
        value={selectedSkills}
        style={{ color: "black" }}
        onChange={handleChange}
        options={allSkills}
        optionLabel="Name"
        filter
        placeholder="Select skills"
        maxSelectedLabels={3}
        className="w-full md:w-75rem"
        onKeyDown={handleKeyDown}
        onInput={handleInputChange}
        panelStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
      />
    </div>
  );
};

export default ModifiedMultiselect;
